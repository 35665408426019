import _ from 'lodash'

const SERVICE_ANALYSIS_STATUS_TYPE = {
  approved: 'APPROVED',
  reproved: 'REPROVED',
}

const SERVICE_ANALYSIS_STATUS_MOVE_TO = {
  approved: 'APPROVED',
  reproved: 'REPROVED',
  blacklist: 'BLACKLIST',
}

const analysisStatusMoveToName = {
  [SERVICE_ANALYSIS_STATUS_MOVE_TO.approved]: 'Aprovado',
  [SERVICE_ANALYSIS_STATUS_MOVE_TO.reproved]: 'Reprovado',
  [SERVICE_ANALYSIS_STATUS_MOVE_TO.blacklist]: 'Bloqueado',
}

const getAnalysisStatusMoveToName = (key) => {
  return analysisStatusMoveToName[key] || key
}

const getAnalysisStatusNameToKey = (key) => {
  const obj = _
    .chain(analysisStatusMoveToName)
    .invert()
    .mapKeys((v, k) => k.toLowerCase())
    .value()

  const keyDowncased = key?.toLowerCase()
  return obj[keyDowncased] || key
}

export default {
  SERVICE_ANALYSIS_STATUS_TYPE: SERVICE_ANALYSIS_STATUS_TYPE,
  SERVICE_ANALYSIS_STATUS_MOVE_TO: SERVICE_ANALYSIS_STATUS_MOVE_TO,
}

export {
  getAnalysisStatusMoveToName,
  getAnalysisStatusNameToKey,
}
