import { useEffect } from 'react'
import { useSelector } from 'react-redux'

/** Use this hook once on App.jsx to customize the Zendesk widget used on all pages. */
const useZendeskWidgetCustomized = () => {
  const email = useSelector((state) => state.auth.email)
  const username = useSelector((state) => state.auth.name)

  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'identify', { 'name': username, 'email': email })
    }
  }, [email, username])
}

export default useZendeskWidgetCustomized
