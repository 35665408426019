import React from 'react'
import _ from 'lodash'

import CertificateRow from 'ui/components/ContainerCommonPanels/CertificateRow'

const infos = [
  {
    options: {
      fields: {
        child_process: {
          name: 'Processos',
          fields: {
            process_kind: 'Tipo',
            'applicants.0.name': 'Nome do Participante',
            'applicants.1.name': 'Nome do Participante',
            process_number: 'Número do Processo',
            last_change: 'Última Atualização',
            last_change_date: 'Data da última Atualização',
            process_value: 'Valor',
          },
        },
      },
    },
  },
]

const ExpansionPanelTRFProcesses = ({ serviceInfos }) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
      {
        _.chain(serviceInfos)
        .sortBy(['name'])
        .map((service) => {
          return (
            <CertificateRow
              key={service.service_id}
              title={service.name}
              service={service}
              otherInfos={infos[0] && _.get(service, 'data')}
              options={infos[0] && infos[0].options}
            />
          )
        }).value()
      }
    </ul>
  )
}

export default ExpansionPanelTRFProcesses
