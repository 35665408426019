import _ from 'lodash'

import {
  CREATE_PLAN_CNPJ,
  CREATE_PLAN_CNPJ_FAILED,
  CREATE_PLAN_CNPJ_SUCCEEDED,
  FETCH_PLAN_CNPJ_LAST,
  FETCH_PLAN_CNPJ_LAST_FAILED,
  FETCH_PLAN_CNPJ_LAST_SUCCEEDED,
} from './actions'

const initialState = {
  fail: false,
  load: false,
  plan_id: null,
}

export const planCnpjReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAN_CNPJ_LAST: {
      return {
        ...state,
        fail: false,
        load: true,
      }
    }
    case FETCH_PLAN_CNPJ_LAST_FAILED: {
      return {
        ..._.cloneDeep(initialState),
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case FETCH_PLAN_CNPJ_LAST_SUCCEEDED: {
      return {
        fail: false,
        load: false,
        plan_id: action.payload.plan_id,
        process_pole: action.payload.process_pole,
        services: action.payload.services,
      }
    }

    case CREATE_PLAN_CNPJ: {
      return {
        ...state,
        fail: false,
        load: true,
      }
    }
    case CREATE_PLAN_CNPJ_FAILED: {
      return {
        ..._.cloneDeep(initialState),
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case CREATE_PLAN_CNPJ_SUCCEEDED: {
      return {
        fail: false,
        load: false,
        plan_id: action.payload.plan_id,
        process_pole: action.payload.process_pole,
        services: action.payload.services,
      }
    }

    default:
      return state
  }
}

export default planCnpjReducer
