import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(() => ({
  containerFilterDescription: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
  },
}))

export default styles
