import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'

export default function ({ location }) {
  const title = 'Total'
  const subtitle = 'Todas as consultas'
  const color = '#ddd'
  const hasManualDerivation = true
  const withManualAnalysis = true
  const itemWithManualAnalysis = true

  return (
    <BaseConsultsList
      location={location}
      title={title}
      subtitle={subtitle}
      color={color}
      hasManualDerivation={hasManualDerivation}
      withManualAnalysis={withManualAnalysis}
      itemWithManualAnalysis={itemWithManualAnalysis}
    />
  )
}
