import { Typography } from '@material-ui/core'
import React from 'react'

export default () => {
  return (
    <div
      style={{
        fontSize: '1.5rem',
        backgroundColor: '#eee',
        padding: 10,
        borderRadius: 8,
      }}
    >
      <p style={{ marginBottom: 5 }}>
        <strong>Atenção:</strong>
      </p>

      <Typography>
        Para que as credenciais do GOV.BR sejam corretamente integradas com o sistema, é
        preciso:
      </Typography>

      <ol>
        <li>
          <Typography>
            Acessar e realizar login ao menos uma vez em
            {' '}
            <a href="https://consentimento.acesso.gov.br">
              https://consentimento.acesso.gov.br
            </a>
            .
          </Typography>
        </li>
        <li>
          <Typography>
            Desativar a autenticação de 2 fatores (&quot;2FA&quot;) da sua conta
            GOV.BR (caso não tenha ativado essa autenticação, nenhuma ação é
            necessária).
          </Typography>
        </li>
      </ol>
    </div>
  )
}
