import styled from 'styled-components'

export default styled.div`
  width: 290px;

  .filter-container {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 20px;

    &:hover {
      background-color: ${(props) => props.theme.palette.gray[100]};
    }
  }

  .text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    flex-grow: 1;
  }
`

const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;

  .date-container {
    display: flex;
    flex-direction: row;
  }

  .date-container-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    .btn-date-filter {
      flex-grow: 1;
    }
  }
`

export { ContainerModal }
