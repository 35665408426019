import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Tab, Tabs } from '@material-ui/core'

import {
  Button,
  DateFilter,
  ListReportExportDate,
  ListReportExportBatch,
  Modal,
} from 'ui/components'

import {
  postExportReportListByDate,
  postExportReportListByBatch,
} from 'services/apiMotorBgc'
import { REPORT_OF_TYPE } from 'utils/constants/exportReport'

import BatchSelectionModal from './BatchSelectionModal'
import Filters from './Filters'
import Container, { ContainerModal } from './styles'

export default () => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector(state => state.auth.panelEnv)

  const [modalOpen, setModalOpen] = useState(false)
  const [initialDate, setInitialDate] = useState(moment().subtract(1, 'months').startOf('month'))
  const [finalDate, setFinalDate] = useState(moment())
  const [batchSelected, setBatchSelected] = useState(null)
  const [lastReportDate, setLastReportDate] = useState(null)
  const [filterValid, setFilterValid] = useState(false)
  const [filter, setFilter] = useState(false)
  const [ofType, setOfType] = useState(REPORT_OF_TYPE.DATE)

  const validInputs = () => {
    if (!filterValid) return false

    if (ofType === REPORT_OF_TYPE.DATE) {
      return !_.isEmpty(initialDate) && !_.isEmpty(finalDate)
    }

    return !_.isEmpty(batchSelected)
  }

  const handleToggleModal = () => {
    setBatchSelected(null)
    setModalOpen(!modalOpen)
  }

  const handleDateFilter = (initial, final) => {
    setInitialDate(initial)
    setFinalDate(final)
  }

  const handleExport = async () => {
    let postFunc = null
    const options = {
      exportType: 'CSV',
      filter: filter,
    }

    if (ofType === REPORT_OF_TYPE.DATE) {
      postFunc = postExportReportListByDate
      options.initialDate = initialDate
      options.finalDate = finalDate
    } else {
      postFunc = postExportReportListByBatch
      options.batchId = batchSelected.batch_id
    }

    try {
      await postFunc(
        apiKey,
        userId,
        panelEnv,
        options,
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao gerar relatorio'
      toast.error(messageError)
      return
    }

    toast.success('O relatório está sendo gerado, pode demorar alguns minutos!')
    setLastReportDate(moment())
  }

  const handleChangeFilters = (newFilter, invalid) => {
    setFilter(newFilter)
    setFilterValid(!invalid)
  }

  const handleTabChange = (_e, val) => {
    setOfType(val)
  }

  const handleSelectBatch = (val) => {
    setBatchSelected(val)
  }

  const renderDateFilter = () => (
    <DateFilter
      onHandleFilter={(initial, final) => handleDateFilter(initial, final)}
      initialDateDefault={initialDate}
      finalDateDefault={finalDate}
    />
  )

  const renderBatchFilter = () => (
    <BatchSelectionModal onSelect={handleSelectBatch} />
  )

  const renderTabs = () => (
    <div className="filter-container">
      <Tabs value={ofType} onChange={handleTabChange}>
        <Tab value={REPORT_OF_TYPE.DATE} label="Por Intervalo de Datas" />
        <Tab value={REPORT_OF_TYPE.BATCH} label="Por Lote" />
      </Tabs>

      <div className="tab-container">
        {
          ofType === REPORT_OF_TYPE.DATE
          ? renderDateFilter()
          : renderBatchFilter()
        }
      </div>
    </div>
  )

  return (
    <Container>
      <Button onClick={handleToggleModal} variant="text">
        Relatórios
      </Button>

      <Modal
        open={modalOpen}
        onClose={handleToggleModal}
        title="Relatórios"
      >
        <ContainerModal>
          {renderTabs()}

          <div style={{ marginTop: 15 }}>
            <Filters onChange={handleChangeFilters} />
          </div>

          <Button onClick={handleExport} disabled={!validInputs()}>
            Gerar Relatório
          </Button>

          <hr style={{ margin: '20px 0' }} />

          <p style={{ fontSize: 18 }}>Relatórios</p>
          <br />

          {
            ofType === REPORT_OF_TYPE.DATE
            ? <ListReportExportDate forceUpdate={lastReportDate} />
            : <ListReportExportBatch forceUpdate={lastReportDate} />
          }
        </ContainerModal>
      </Modal>
    </Container>
  )
}
