const GROUP_ID = {
  CADASTRAL_DATA: 'CADASTRAL_DATA',
  CADASTRAL_EXTENSION: 'CADASTRAL_EXTENSION',
  CREDIT_HISTORY: 'CREDIT_HISTORY',
  NEGATIVE_MEDIA: 'NEGATIVE_MEDIA',
  CVM: 'CVM',
  IBAMA: 'IBAMA',
  PROTESTS_ACCOUNTS: 'PROTESTS_ACCOUNTS',
  CERTIFICATES: 'CERTIFICATES',
  CERTIFICATES_TRF: 'CERTIFICATES_TRF',
  TRF_PROCESSES: 'TRF_PROCESSES',
  COURT_LAWSUITS: 'COURT_LAWSUITS',
  LABOR_COURTS: 'LABOR_COURTS',
  PJE_FIRST_INSTANCE: 'PJE_FIRST_INSTANCE',
  PJE_SECOND_INSTANCE: 'PJE_SECOND_INSTANCE',
  TJ_FIRST_INSTANCE: 'TJ_FIRST_INSTANCE',
  TJ_SECOND_INSTANCE: 'TJ_SECOND_INSTANCE',
  TRT_CERTIFICATE_PJE: 'TRT_CERTIFICATE_PJE',
  TRT_CERTIFICATE_PHYSICAL: 'TRT_CERTIFICATE_PHYSICAL',
  STATE_TAX_DEBTS: 'STATE_TAX_DEBTS',
  SINTEGRA: 'SINTEGRA',
}

const groupIdName = {
  [GROUP_ID.CADASTRAL_DATA]: 'Dados Cadastrais',
  [GROUP_ID.CADASTRAL_EXTENSION]: 'Cadastro Estendido',
  [GROUP_ID.CREDIT_HISTORY]: 'Histórico de Crédito',
  [GROUP_ID.NEGATIVE_MEDIA]: 'Mídia Negativa',
  [GROUP_ID.CVM]: 'CVM - Comissão de Valores Mobiliários',
  [GROUP_ID.IBAMA]: 'IBAMA',
  [GROUP_ID.PROTESTS_ACCOUNTS]: 'Protestos e Contas',
  [GROUP_ID.CERTIFICATES]: 'Certidões',
  [GROUP_ID.CERTIFICATES_TRF]: 'Certidões do TRF',
  [GROUP_ID.TRF_PROCESSES]: 'TRF Processos',
  [GROUP_ID.COURT_LAWSUITS]: 'Processos Judiciais e Criminais',
  [GROUP_ID.LABOR_COURTS]: 'Tribunais do Trabalho',
  [GROUP_ID.PJE_FIRST_INSTANCE]: 'Processos no PJE 1º Instância',
  [GROUP_ID.PJE_SECOND_INSTANCE]: 'Processos no PJE 2º Instância',
  [GROUP_ID.TJ_FIRST_INSTANCE]: 'Processos no TJ 1º Instância',
  [GROUP_ID.TJ_SECOND_INSTANCE]: 'Processos no TJ 2º Instância',
  [GROUP_ID.TRT_CERTIFICATE_PJE]: 'TRT - PJE Certidões',
  [GROUP_ID.TRT_CERTIFICATE_PHYSICAL]: 'TRT - Físico Certidões',
  [GROUP_ID.STATE_TAX_DEBTS]: 'Débitos Tributários Estaduais',
  [GROUP_ID.SINTEGRA]: 'SINTEGRA',
}

const nameGroupId = (key) => {
  return groupIdName[key] || key
}

export {
  GROUP_ID,
  nameGroupId,
}
