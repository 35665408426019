import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: 20,
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
    fontSize: 18,
    fontWeight: 'bold',
  },
  secondaryHeading: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  icon: {
    fontSize: 30,
    marginRight: 15,
  },
}))
