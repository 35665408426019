import React from 'react'
import _ from 'lodash'

import CertificateRow from 'ui/components/ContainerCommonPanels/CertificateRow'

const ExpansionPanelCertificates = ({ serviceInfos, sortBy = 'name' }) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
      {
        _.chain(serviceInfos)
        .sortBy(sortBy)
        .map((service) => {
          return (
            <CertificateRow
              key={service.service_id}
              title={service.name}
              service={service}
            />
          )
        }).value()
      }
    </ul>
  )
}

export default ExpansionPanelCertificates
