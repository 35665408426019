import React, { useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@material-ui/icons'
import RemoveIcon from '@material-ui/icons/Remove'
// eslint-disable-next-line import/no-cycle
import { ModalContainerPanels } from 'ui/components/ContainerCommonPanels/utilsFunctions'
import { ConsultStatusIcon } from 'utils/icons'
import { formatDocNumber } from 'utils/masks'
import { PANEL_ENVS } from 'utils/constants/environments'
// eslint-disable-next-line import/no-cycle
import { renderTable } from '../index'

const MemberRow = ({ docType, docData, docMember, member }) => {
  const [openExpandRow, setOpenExpandRow] = useState(false)
  const [consultModalOpen, setConsultModalOpen] = useState(false)

  const docNumber = () => (
    docType === PANEL_ENVS.CPF ? member.cpf : member.cnpj
  )

  const renderExpandRow = () => {
    if (docType !== PANEL_ENVS.CNPJ) return null

    return (
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenExpandRow(!openExpandRow)}
      >
        {openExpandRow ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
    )
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {renderExpandRow()}
        </TableCell>
        <TableCell component="th" scope="row">
          {member.name}
        </TableCell>
        <TableCell>{docType.toUpperCase()}</TableCell>
        <TableCell>{formatDocNumber(docNumber(), docType)}</TableCell>
        <TableCell>{member.qualification}</TableCell>
        <TableCell>
          {member.start_date || <RemoveIcon style={{ color: '#ddd' }} />}
        </TableCell>
        <TableCell>
          <ConsultStatusIcon consult={docMember} />
        </TableCell>
        <TableCell>
          <Button
            onClick={() => setConsultModalOpen(true)}
            size='small'
            variant='outlined'
            startIcon={<Search />}
          >
            Exibir
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openExpandRow} timeout="auto" unmountOnExit>
            <Box margin='5px 0 25px 1px'>
              <Typography style={{ marginTop: 15 }} variant="body1" gutterBottom component="div">
                {`Quadro societário de ${member.name}`}
              </Typography>

              {renderTable(member.data, docData)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <ModalContainerPanels
        docData={docMember}
        docType={docType}
        onClose={() => setConsultModalOpen(false)}
        open={consultModalOpen}
      />
    </React.Fragment>
  )
}

export default MemberRow
