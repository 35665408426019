import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  certiLine: {
    fontSize: 17,
    borderTop: `1px solid ${theme.palette.primary.grayLight}`,
    paddingTop: 10,
  },
  title: {
    display: 'block',
    fontSize: 17,
    fontWeight: 600,
  },
  description: {
    display: 'block',
    fontSize: 17,
  },
}))

export default styles
