import { PANEL_ENVS } from 'utils/constants/environments'

import {
  FAIL_LOGIN,
  LOGIN,
  LOGOUT,
  SUCCESS_LOGIN,
  SUCCESS_REGISTER,
  RECOVER_PASSWORD,
  CHANGE_PASSWORD,
  FAIL_CHANGE_PASSWORD,
  CHANGE_PANEL_ENV,
} from './actions'

const initialState = {
  access_token: null,
  api_key: null,
  api_key_id: null,
  email: null,
  email_verified: null,
  enabled: false,
  expires_in: 0,
  given_name: null,
  groups: [],
  name: null,
  phone_number: null,
  phone_number_verified: null,
  plans: [],
  refresh_token: null,
  status: null,
  sub: null,
  username: null,
  load: false,
  fail: false,
  changePasswordSession: null,
  panelEnv: PANEL_ENVS.CPF,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        load: true,
        fail: false,
      }

    case SUCCESS_LOGIN:
      return {
        ...state,
        ...action.payload,
        load: false,
        fail: false,
      }

    case FAIL_LOGIN:
      return {
        ...state,
        load: false,
        fail: true,
      }

    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }

    case SUCCESS_REGISTER:
      return {
        ...state,
        destination: action.payload.destination,
        sub: action.payload.sub,
      }

    case RECOVER_PASSWORD:
      return {
        ...state,
        destination: action.payload,
      }

    case CHANGE_PASSWORD:
      return {
        ...state,
        ...action.payload,
      }
    case FAIL_CHANGE_PASSWORD:
      return {
        ...initialState,
        panelEnv: state.panelEnv,
      }

    case CHANGE_PANEL_ENV:
      return {
        ...state,
        panelEnv: action.payload.panelEnv,
      }

    default:
      return state
  }
}

export default authReducer
