import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import ProcessAccordion from 'ui/components/ContainerCommonPanels/ProcessAccordion'

const committeOpts = {
  committe: 'Nome do Comitê',
  committe_cnpj: 'CNPJ do Comitê',
  electoral_unity: 'Unidade Eleitoral',
  original_donor_document: 'CPF/CNPJ do Doador Originário',
  original_donor_name: 'Nome do Doador Originário',
  electoral_receipt: 'Recibo eleitoral',
  date: 'Data',
  uf: 'Estado',
  value: 'Valor da Doação',
  party_acronym: 'Sigla do Partido',
  expense_type: 'Tipo de Despesa',
  recourse_font: 'Fonte do Recurso',
}

const candidateOpts = {
  donor_name: 'Nome do Doador',
  donor_document: 'CPF/CNPJ do Doador',
  original_donor_document: 'CPF/CNPJ do Doador Originário',
  original_donor_name: 'Nome do Doador Originário',
  electoral_receipt: 'Recibo eleitoral',
  date: 'Data',
  value: 'Valor da Doação',
  candidate_name: 'Nome do Candidato',
  candidate_number: 'Número do Candidato',
  candidate_cnpj: 'CNPJ do Candidato',
  party_acronym: 'Sigla do Partido',
  expense_type: 'Tipo de Despesa',
  recourse_font: 'Fonte do Recurso',
}

const ElectoralFinancingPanel = ({ service }) => {
  let occurrenceCount = 0
  const [occCount, setOccCount] = useState(0)

  useEffect(() => {
    setOccCount(occurrenceCount)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service])

  // Gets the description of the fields to be exhibited
  const getOptions = (key) => {
    const isDonationForCommitte = (keyDonation) => keyDonation.includes('donations_for_committe')

    if (isDonationForCommitte(key))
      return committeOpts
    return candidateOpts
  }

  // Gets the right field key for the 'donors' object that sometimes cames as 'donations'
  const getDonationsKey = (key) => {
    if (key.includes('donors'))
      return 'donors'
    if (key.includes('donations'))
      return 'donations'

    return null
  }

  const getTitle = (key, value, donationsKey) => {
    const supplyNumber = value.supply?.length || 0
    const donationsNumber = value[donationsKey]?.length || 0

    if (key.includes('donations_for_committe'))
      return `Financiamentos de Comitês em ${key.slice(key.length - 4)} (${donationsNumber + supplyNumber})`
    if (key.includes('donations_for_candidate'))
      return `Financiamentos de Candidatos em ${key.slice(key.length - 4)} (${donationsNumber + supplyNumber})`

    return null
  }

  const renderFinancingItems = (subtitle, donations, fieldNames) => {
    const maxItemsToRender = 10
    const donationsLength = donations.length
    occurrenceCount += donations.length

    const renderDonationFields = (donation, index) => {
      if (_.isEmpty(donation)) return null

      return (
        <ul key={`ul${index}`} style={{ listStyleType: 'none' }}>
          {
            Object.entries(donation).map(([donationKey, donationValue], index2) => {
              if (_.isEmpty(fieldNames[donationKey]) || _.isEmpty(donationValue)) return null

              return (
                <li key={`li${index2}`}>
                  <strong>{fieldNames[donationKey]}</strong>
                  {': '}
                  {donationValue}
                </li>
              )
            })
          }
        </ul>
      )
    }

    return (
      <React.Fragment>
        <br />
        <strong>{subtitle}</strong>

        {donations.slice(0, maxItemsToRender).map((donation, index) => (
          <div key={index}>
            {renderDonationFields(donation, index)}
            {index < maxItemsToRender - 1 && index < donationsLength - 1 && <hr />}
          </div>
        ))}

        {
          donationsLength > maxItemsToRender &&
          <div style={{ marginTop: 20, fontSize: 14, textAlign: 'center' }}>
            <p>{`Exibindo apenas as ${maxItemsToRender} primeiras ocorrências`}</p>
          </div>
        }
      </React.Fragment>
    )
  }

  const renderFinancingAccordion = (title, value, donationsKey, fieldNames) => {
    return (
      <ProcessAccordion title={title}>
        {!_.isEmpty(value[donationsKey]) && renderFinancingItems(`Doações (${value[donationsKey].length})`, value[donationsKey], fieldNames)}

        {!_.isEmpty(value.supply) && renderFinancingItems(`Fornecimentos (${value.supply.length})`, value.supply, fieldNames)}
      </ProcessAccordion>
    )
  }

  const renderContent = () => {
    const serviceData = service?.data
    if (_.isEmpty(serviceData)) return null

    const components = Object.entries(serviceData).map(([key, value], index) => {
      // Skips if value is missing
      if (_.isEmpty(value)) return null

      // The key may vary  has 'donations' or 'donors'
      const donationsKey = getDonationsKey(key)
      const title = getTitle(key, value, donationsKey)

      // Skips if title couldn't be parsed
      if (_.isEmpty(title)) return null

      // Skips if has no donation or supply arrays to render
      if (_.isEmpty(value[donationsKey]) && _.isEmpty(value.supply)) return null

      const fieldNames = getOptions(key)

      return (
        <div key={index + Date.now()}>
          {renderFinancingAccordion(title, value, donationsKey, fieldNames)}
        </div>
      )
    })

    return components
  }

  const renderAccordion = () => (
    <ProcessAccordion title={`Ocorrências (${occCount})`}>
      {renderContent()}
    </ProcessAccordion>
  )

  if (_.get(service, 'data.consists') !== true) return null

  return renderAccordion()
}

export default ElectoralFinancingPanel
