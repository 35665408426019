import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { InputLabel } from '@material-ui/core'

import {
  Button,
  Modal,
  TextInput,
} from 'ui/components'

import {
  postCreateOrganization,
} from 'services/apiMotorBgc'

import messages from 'utils/messages'
import { isPermissionMaster } from 'utils/validators'

import styles from './styles'

const validationSchema = Yup.object({
  name: Yup.string().required(messages.required),
})

const initialValues = {
  name: '',
}

export default ({ onCreate = null }) => {
  const classes = styles()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const userPermission = useSelector((state) => state.user.permission)

  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  if (!isPermissionMaster(userPermission)) return null

  const handleToggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  const handleUserCreate = async (values) => {
    setLoading(true)

    try {
      await postCreateOrganization(
        apiKey,
        userId,
        values.name,
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao criar organização'
      toast.error(messageError)
      setLoading(false)
      return
    }

    setLoading(false)
    setModalOpen(false)
    toast.success('Organização criado com sucesso!')

    if (_.isFunction(onCreate)) onCreate()
  }

  const renderNameInput = () => {
    return (
      <React.Fragment>
        <InputLabel>Nome</InputLabel>
        <Field
          name="name"
          component={TextInput}
        />
      </React.Fragment>
    )
  }

  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUserCreate}
      >
        {(form) => {
          return (
            <form>
              {renderNameInput()}

              <div className={classes.buttonsContainer}>
                <Button onClick={handleCancel} variant="text">
                  Cancelar
                </Button>
                <Button onClick={form.handleSubmit} disabled={loading}>
                  Criar Organização
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    )
  }

  return (
    <div>
      <Button onClick={handleToggleModal}>
        Criar Organização
      </Button>

      <Modal
        open={modalOpen}
        onClose={handleToggleModal}
        title="Criar Organização"
      >
        {renderForm()}
      </Modal>
    </div>
  )
}
