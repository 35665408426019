import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { SaveOutlined } from '@material-ui/icons'
import { Button, Checkbox } from 'ui/components'
import { postProcessesClassifierPreset } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'

import { buildFileFromTypeResults } from '../SaveLoadProcessesClassifierPreset/spreadSheet'

const CreatePresetDialog = ({ isOpen, service, onClose }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [presetName, setPresetName] = useState('')
  const [saveToOrganization, setSaveToOrganization] = useState(false)

  const formatTypeResultsToSave = (typeResults) => {
    const formatted = _.map(typeResults, (tr) => {
      const newData = _.mapValues(tr.data, (item) => _.pick(item, ['values']))
      return { type_result_id: tr.type_result_id, move_to: tr.move_to, data: newData }
    })

    return formatted
  }

  const handleCreatePreset = async () => {
    const params = {
      name: presetName,
      visibleToOrganization: saveToOrganization,
      typeResults: formatTypeResultsToSave(service.type_results),
    }

    try {
      await postProcessesClassifierPreset(apiKey, userId, params)
    } catch (err) {
      reportError(err, 'Occorreu um erro ao salvar o preset')
      return
    }

    toast.success('Preset salvo!')
    onClose()
  }

  const handleExportPreset = () => {
    try {
      const csvURL = buildFileFromTypeResults(service.type_results)
      const tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', 'classificador-de-processos.csv')
      tempLink.click()
      toast.info('Termos exportados em CSV')
    } catch (err) {
      reportError(err, 'Ocorreu um erro ao exportar')
    }
  }

  const handleSaveToOrganization = (checked) => {
    setSaveToOrganization(checked)
  }

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>
        Novo preset
      </DialogTitle>

      <DialogContent style={{ padding: '10px 30px' }}>
        <DialogContentText>
          Crie um novo preset com as palavras-chaves para uso futuro.
          Pode ser usado em outros planos do seu usuário ou compartilha-lo com outros usuários da sua organização.
        </DialogContentText>

        <div style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }}>
          <Button
            variant="text"
            size="small"
            startIcon={<SaveOutlined />}
            onClick={handleExportPreset}
          >
            <span>Exportar termos em um arquivo CSV</span>
          </Button>
        </div>

        <div style={{ marginTop: 20 }}>
          <TextField
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
            fullWidth
            label="Escolha um nome para o preset"
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <Checkbox
            value={saveToOrganization}
            label="Compartilhar preset com as outras pessoas da minha organização"
            onClick={handleSaveToOrganization}
          />
        </div>
      </DialogContent>

      <DialogActions style={{ display: 'flex',  justifyContent: 'space-around' }}>
        <Button variant="text" onClick={onClose}>Cancelar</Button>

        <Button variant="text" disabled={_.isEmpty(presetName)} onClick={handleCreatePreset}>Salvar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreatePresetDialog
