import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/styles'
import _ from 'lodash'

import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

import { CircularProgressBar } from 'ui/components'

import serviceStatusConstants from 'utils/constants/serviceStatus'
import analysisStatusConstants from 'utils/constants/analysisStatus'
import { formatDate, formatDocNumber } from 'utils/masks'

export default ({ docList = [], loading = false, onClickItem }) => {
  const theme = useTheme()

  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const renderDocProgress = (doc) => {
    const serviceInfos = doc.service_infos

    const processingCount = _.reject(
      serviceInfos,
      { status: serviceStatusConstants.SERVICE_STATUS.processing },
    ).length

    const progressCount = serviceInfos.length === 0
      ? 0
      : processingCount * 100 / serviceInfos.length
    if (progressCount !== 100) return <CircularProgressBar value={progressCount} />

    let elemIcon = null

    switch (doc.analysis_status) {
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved:
        elemIcon = <CheckCircleIcon style={{ color: theme.palette.primary.green }} />
        break
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved:
        elemIcon = <CancelIcon style={{ color: theme.palette.primary.red }} />
        break
      default:
        elemIcon = null
    }

    return <div className="background-icon">{elemIcon}</div>
  }

  const handleClick = (docItem) => {
    if (onClickItem) onClickItem(docItem)
  }

  return (
    <React.Fragment>
      {loading
      ? <tr className="disabled"><td colSpan={3}><CircularProgress /></td></tr>
      : (
        docList.length === 0 ? (
          <tr>
            <td colSpan={3}>Nenhum registro encontrado</td>
          </tr>
        ) : (
          <React.Fragment>
            {docList.map((docItem) => (
              <tr
                key={docItem.doc_id}
                className="handler"
                onClick={() => handleClick(docItem)}
              >
                <td>{formatDocNumber(docItem[panelEnv], panelEnv)}</td>
                <td>{formatDate(docItem.created_at)}</td>
                <td className="column-icon">{renderDocProgress(docItem)}</td>
              </tr>
            ))}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  )
}
