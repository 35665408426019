import * as Sentry from '@sentry/browser'
import { formatToCEP } from 'brazilian-values'

export const columnsAddress = [
  {
    name: 'address',
    label: 'Endereço',
  },
  {
    name: 'neighborhood',
    label: 'Bairro',
  },
  {
    name: 'city_state',
    label: 'Cidade/Estado',
  },
  {
    name: 'zipcode',
    label: 'CEP',
    options: {
      customBodyRender: (value) => {
        try {
          return value ? formatToCEP(value) : null
        } catch (err) {
          Sentry.captureException(err)
          return false
        }
      },
    },
  },
]

export const columnsVehicles = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'license_plate',
    label: 'Placa',
  },
  {
    name: 'year',
    label: 'Ano',
  },
  {
    name: 'renavam',
    label: 'Renavam',
  },
  {
    name: 'chassi',
    label: 'Chassi',
  },
]
