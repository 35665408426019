import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { CircularProgress } from '@material-ui/core'

import { getDoc } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'
import { PANEL_ENVS } from 'utils/constants/environments'

import CpfPanels from 'ui/components/ContainerCpfDoc/Panels'
import CnpjPanels from 'ui/components/ContainerCnpjDoc/Panels'

import AnalysisStatusHistoric from './AnalysisStatusHistoric'

import ManualAnalysis from 'ui/components/ManualAnalysis'
import Motives from './Motives'

import Container from './styles'

export default ({ docId, docType, withManualAnalysis, manualDerivationToRole, manualDerivationOnConfirm }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  let planServicesList = null
  switch (docType) {
    case PANEL_ENVS.CPF:
      planServicesList = useSelector((state) => state.planCpfServices.services)
      break
    case PANEL_ENVS.CNPJ:
      planServicesList = useSelector((state) => state.planCnpjServices.services)
      break
    default:
  }

  const [docData, setDocData] = useState(null)
  const [loading, setLoading] = React.useState(true)

  const listDocs = async () => {
    let res = null
    try {
      res = await getDoc(
        apiKey,
        userId,
        docType,
        docId,
        {
          withManualAnalysis: withManualAnalysis,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao exibir consulta')
      return
    }

    setDocData(res.data)
    setLoading(false)
  }

  useEffect(() => {
    listDocs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderManualAnalysis = () => {
    if (withManualAnalysis !== true) return null

    return (
      <ManualAnalysis
        manualAnalysis={docData.manual_derivation_analysis}
        manualDerivationToRole={manualDerivationToRole}
        onConfirm={manualDerivationOnConfirm}
      />
    )
  }

  const renderPanels = () => {
    if (docType === PANEL_ENVS.CPF)
      return <CpfPanels docData={docData} />
    return <CnpjPanels docData={docData} />
  }

  return (
    <Container>
      {
        loading
        ? <CircularProgress />
        : (
          <div className="container-data">
            <div className="container-btns">
              <Motives cpfDoc={docData} planServicesList={planServicesList} />
              {renderManualAnalysis()}
            </div>

            <AnalysisStatusHistoric historic={docData.analysis_status_historic} />
            {renderPanels()}
          </div>
        )
      }
    </Container>
  )
}
