import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

const CustomizedSwitches = ({ labelOff, labelOn, onChange }) => {
  const [customSwitch, setState] = React.useState(true)

  const handleChange = (event) => {
    setState(event.target.checked)
    if (_.isFunction(onChange)) {
      onChange(event.target.checked)
    }
  }

  return (
    <FormGroup>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item style={{ fontSize: 12 }}>{labelOff}</Grid>
          <Grid item>
            <AntSwitch checked={customSwitch} onChange={handleChange} name="customSwitch" />
          </Grid>
          <Grid item style={{ fontSize: 12 }}>{labelOn}</Grid>
        </Grid>
      </Typography>
    </FormGroup>
  )
}

CustomizedSwitches.propTypes = {
  onChange: PropTypes.func,
  labelOff: PropTypes.string.isRequired,
  labelOn: PropTypes.string.isRequired,
}

export default CustomizedSwitches
