import React from 'react'
import _ from 'lodash'

import CertificateRow from 'ui/components/ContainerCommonPanels/CertificateRow'

const infos = [
  {
    options: {
      fields: {
        child_process: {
          name: 'Processos',
          fields: {
            process_kind: 'Tipo',
            applicants: {
              name: 'Participantes',
              fields: {
                kind: 'Polo',
                name: 'Participante',
              },
            },
            process_number: 'Número do Processo',
            distribution_date: 'Recebido em',
          },
        },
      },
    },
  },
]

const ExpansionPanelProcessesTJ = ({ serviceInfos }) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
      {
        _.chain(serviceInfos)
        .sortBy(['name'])
        .map((service) => {
          return (
            <CertificateRow
              key={service.service_id}
              title={service.name}
              service={service}
              otherInfos={infos[0] && _.get(service, 'data')}
              options={infos[0] && infos[0].options}
            />
          )
        }).value()
      }
    </ul>
  )
}

export default ExpansionPanelProcessesTJ
