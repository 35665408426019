import React from 'react'

/**
 * Score Indicators data
 *   Reverse == false: pontuacao de 0% a 100%
 *   Reverse == true: pontuacao de 100% a 0%'
  *  Description must be displayed on a tooltip for the user
*/
const scoreIndicatorsData = {
  'Comprometimento de crédito emergencial':
  {
    reverse: false,
    title: 'Comprometimento de crédito emergencial',
    description: (
      <p>
        Indica o nível de comprometimento com créditos do tipo emergencial.
        <br />
        <strong>
          Pontuações maiores indicam maior comprometimento com crédito rotativo.<br />
          De 0% (pior) a 100% (melhor).
        </strong>
      </p>
    ),
  },
  // Identico ao anterior, mas com uma chave diferente pois a API agora retorna assim
  // Mantendo assim por uma questão de retrocompatibilidade
  'Pontuação maiores indicam maior comprometimento com crédito rotativo': {
    reverse: false,
    title: 'Comprometimento de crédito emergencial',
    description: (
      <p>
        Indica o nível de comprometimento com créditos do tipo emergencial.
        <br />
        <strong>
          Pontuações maiores indicam maior comprometimento com crédito rotativo.
          <br />
          De 0% (pior) a 100% (melhor).
        </strong>
      </p>
    ),
  },
  'Tipo de contratação':
  {
    reverse: false,
    title: 'Tipo de contratação',
    description: (
      <p>
        Indica os tipos de contratações realizadas.
        <br />
        <strong>
          Pontuações maiores indicam contratações de produtos associados a perfis menos arriscados.<br />
          De 0% (pior) a 100% (melhor).
        </strong>
      </p>
    ),
  },
  // Identico ao anterior, mas com uma chave diferente pois a API agora retorna assim
  // Mantendo assim por uma questão de retrocompatibilidade
  'Pontuações maiores indicam contratações de produtos associados a perfis menos arriscados':
  {
    reverse: false,
    title: 'Tipo de contratação',
    description: (
      <p>
        Indica os tipos de contratações realizadas.
        <br />
        <strong>
          Pontuações maiores indicam contratações de produtos associados a perfis menos arriscados.
          <br />
          De 0% (pior) a 100% (melhor).
        </strong>
      </p>
    ),
  },
  'Endividamento de contrato e credores': {
    reverse: true,
    title: 'Endividamento de contrato e credores',
    description: (
      <p>
        Indica o nível de endividamento vigente com credores distintos
        <br />
        <strong>
          Pontuações maiores indicam número maior de credores e/ou contratos distintos em aberto.<br />
          De 100% (pior) a 0% (melhor).
        </strong>
      </p>
    ),
  },
  // Identico ao anterior, mas com uma chave diferente pois a API agora retorna assim
  // Mantendo assim por uma questão de retrocompatibilidade
  'Pontuações maiores indicam número maior de credores e/ou contratos distintos em aberto': {
    reverse: true,
    title: 'Endividamento de contrato e credores',
    description: (
      <p>
        Indica o nível de endividamento vigente com credores distintos
        <br />
        <strong>
          Pontuações maiores indicam número maior de credores e/ou contratos distintos em aberto.
          <br />
          De 100% (pior) a 0% (melhor).
        </strong>
      </p>
    ),
  },
  'Gravidade em atrasos': {
    reverse: true,
    title: 'Gravidade em atrasos',
    description: (
      <p>
        Indica o nível da gravidade do atraso dos débitos em aberto.
        <br />
        <strong>
          Pontuações maiores indicam débitos vigentes com atrasos mais severos.<br />
          De 100% (pior) a 0% (melhor).
        </strong>
      </p>
    ),
  },
  // Identico ao anterior, mas com uma chave diferente pois a API agora retorna assim
  // Mantendo assim por uma questão de retrocompatibilidade
  'Pontuações maiores indicam débitos vigentes com atrasos mais severos': {
    reverse: true,
    title: 'Gravidade em atrasos',
    description: (
      <p>
        Indica o nível da gravidade do atraso dos débitos em aberto.
        <br />
        <strong>
          Pontuações maiores indicam débitos vigentes com atrasos mais severos.
          <br />
          De 100% (pior) a 0% (melhor).
        </strong>
      </p>
    ),
  },
  'Pontualidade de pagamento': {
    reverse: false,
    title: 'Pontualidade de pagamento',
    description: (
      <p>
        Indica o comportamento de pontualidade de pagamento.
        <br />
        <strong>
          Pontuações maiores indicam a realização de mais pagamentos de parcelas sem atrasos.<br />
          De 0% (pior) a 100% (melhor).
        </strong>
      </p>
    ),
  },
  // Identico ao anterior, mas com uma chave diferente pois a API agora retorna assim
  // Mantendo assim por uma questão de retrocompatibilidade
  'Pontuações maiores indicam a realização de mais pagamentos de parcelas sem atrasos': {
    reverse: false,
    title: 'Pontualidade de pagamento',
    description: (
      <p>
        Indica o comportamento de pontualidade de pagamento.
        <br />
        <strong>
          Pontuações maiores indicam a realização de mais pagamentos de parcelas sem atrasos.
          <br />
          De 0% (pior) a 100% (melhor).
        </strong>
      </p>
    ),
  },
  'Busca por crédito': {
    reverse: true,
    title: 'Busca por crédito',
    description: (
      <p>
        Indica o comportamento mensal de busca por crédito.
        <br />
        <strong>
          Pontuações maiores indicam maior frequência de novos contratos.<br />
          De 100% (pior) a 0% (melhor).
        </strong>
      </p>
    ),
  },
  // Identico ao anterior, mas com uma chave diferente pois a API agora retorna assim
  // Mantendo assim por uma questão de retrocompatibilidade
  'Pontuações maiores indicam maior frequência de novos contratos': {
    reverse: true,
    title: 'Busca por crédito',
    description: (
      <p>
        Indica o comportamento mensal de busca por crédito.
        <br />
        <strong>
          Pontuações maiores indicam maior frequência de novos contratos.
          <br />
          De 100% (pior) a 0% (melhor).
        </strong>
      </p>
    ),
  },
  'Estabilidade de relacionamento': {
    reverse: false,
    title: 'Estabilidade de relacionamento',
    description: (
      <p>
        Indica o nível da estabilidade da relação com os credores.
        <br />
        <strong>
          Pontuações maiores indicam relacionamentos mais estáveis com os credores.
          <br />
          De 0% (pior) a 100% (melhor).
        </strong>
      </p>
    ),
  },
  // Identico ao anterior, mas com uma chave diferente pois a API agora retorna assim
  // Mantendo assim por uma questão de retrocompatibilidade
  'Pontuações maiores indicam relacionamentos mais estáveis com os credores': {
    reverse: false,
    title: 'Estabilidade de relacionamento',
    description: (
      <p>
        Indica o nível da estabilidade da relação com os credores.
        <br />
        <strong>
          Pontuações maiores indicam relacionamentos mais estáveis com os credores.<br />
          De 0% (pior) a 100% (melhor).
        </strong>
      </p>
    ),
  },
}

export default scoreIndicatorsData
