export const columns = [
  {
    name: 'assumed_billing',
    label: 'Faturamento aproximado',
  },
  {
    name: 'income_range',
    label: 'Faixa do faturamento',
  },
  {
    name: 'date_reference',
    label: 'Data da referencia',
  },
  {
    name: 'company_syze',
    label: 'Tamanho da empresa',
  },
]
