import React, { useState } from 'react'

import { SaveOutlined, CloudDownloadOutlined } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import { Button } from 'ui/components'

import CreatePresetDialog from '../CreatePresetDialog'
import ListPresetsDialog from '../ListPresetsDialog'
import styles from './styles'

const SaveLoadProcessesClassifierPreset = ({
  onUpdateTypeResults,
  service,
}) => {
  const classes = styles()

  const [saveOpened, setSaveOpened] = useState(false)
  const [listPresetsOpened, setListPresetsOpened] = useState(false)

  const openPresetsList = () => {
    setListPresetsOpened(true)
  }

  const openSave = () => {
    setSaveOpened(true)
  }

  const renderCreatePresetDialog = () => {
    if (!saveOpened) return null

    return (
      <CreatePresetDialog
        isOpen={saveOpened}
        service={service}
        onClose={() => setSaveOpened(false)}
      />
    )
  }

  const renderListPresetsDialog = () => {
    if (!listPresetsOpened) return null

    return (
      <ListPresetsDialog
        isOpen={listPresetsOpened}
        onLoadPreset={onUpdateTypeResults}
        onClose={() => setListPresetsOpened(false)}
      />
    )
  }

  const disabled = !service.checked

  return (
    <div className={classes.container}>
      <div style={{ color: disabled ? '#bbb' : null, display: 'flex', alignItems: 'center' }}>
        <Typography>Preset de palavras-chave:</Typography>

        <Button
          variant="text"
          size="small"
          disabled={disabled}
          startIcon={<CloudDownloadOutlined />}
          onClick={openPresetsList}
        >
          <span>Carregar preset</span>
        </Button>

        <Button
          variant="text"
          size="small"
          disabled={disabled}
          startIcon={<SaveOutlined />}
          onClick={openSave}
        >
          <span>Salvar preset</span>
        </Button>
      </div>

      {renderCreatePresetDialog()}

      {renderListPresetsDialog()}
    </div>
  )
}

export default SaveLoadProcessesClassifierPreset
