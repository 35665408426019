import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { ThemeProvider } from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { useSelector } from 'react-redux'

import {
  ButtonBatchConsult,
  ButtonConsult,
  DialogConfirm,
  Modal,
  Pagination,
  SearchDoc,
  Table,
  TitleView,
} from 'ui/components'

import {
  deleteBlacklist,
  getListBlacklist,
  postBlacklist,
  postCreateBlacklistBatch,
} from 'services/apiMotorBgc'
import { formatDate, formatDocNumber } from 'utils/masks'
import { isDocNumberValid } from 'utils/validators'
import { extractOnlyNumbers } from 'utils/functions'
import reportError from 'utils/errorReporter'

import Container from './styles'

const title = 'Bloqueados'
const color = '#000000'

export default function () {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const savedCallbackListDocBlacklist = useRef()

  const [docFilter, setDocFilter] = useState('')

  const [consultsList, setConsultsList] = useState([])
  const [totalRequests, setTotalRequests] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [itemSelected, setItemSelected] = useState({})
  const [loading, setLoading] = React.useState(true)
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false)
  const [docToDelete, setDocToDelete] = useState('')

  const [modalOpen, setModalOpen] = useState(false)

  const theme = {
    color: color,
  }

  const listBlacklist = async (options = {}) => {
    let res = null
    try {
      res = await getListBlacklist(
        apiKey,
        userId,
        panelEnv,
        {
          docNumber: _.has(options, 'docNumber') ? options.docNumber : docFilter,
          page: _.has(options, 'page') ? options.page : currentPage,
        },
      )
    } catch (err) {
      reportError(err, `Erro ao listar ${title}`)
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalRequests(xTotal)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setConsultsList(res.data.blacklist)
  }

  const callbackListDocBlacklist = () => listBlacklist()

  useEffect(() => {
    savedCallbackListDocBlacklist.current = callbackListDocBlacklist
  })

  useEffect(() => {
    listBlacklist()

    const callListDocBlacklist = () => savedCallbackListDocBlacklist.current()
    const intervalId = setInterval(callListDocBlacklist, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [consultsList])

  const handleToggleModal = (item) => {
    setItemSelected(item)
    setModalOpen(!modalOpen)
  }

  const handleChangePage = async (event, page) => {
    const options = {
      page: page,
    }

    setLoading(true)
    listBlacklist(options)
  }

  const handleDocFilter = async (docNumber) => {
    if (docFilter === docNumber) return

    const options = {
      docNumber: docNumber,
      page: 1,
    }

    setLoading(true)
    setDocFilter(docNumber)
    listBlacklist(options)
  }

  const handleOpenDialogDelete = (event, docNumber) => {
    event.stopPropagation()

    setDocToDelete(docNumber)
    setDialogDeleteOpen(true)
  }

  const handleDocDeleteCancel  = async (event) => {
    event.stopPropagation()
    setDocToDelete('')
    setDialogDeleteOpen(false)
  }

  const handleDocDeleteConfirm  = async (event, docNumber) => {
    event.stopPropagation()

    try {
      await deleteBlacklist(apiKey, userId, panelEnv, docNumber)
      toast.success(`${panelEnv.toUpperCase()} removido dos ${title} com sucesso!`)
      setLoading(true)
      listBlacklist()
    } catch (err) {
      reportError(err, `Erro ao remover dos ${title}`)
    }

    setDocToDelete('')
    setDialogDeleteOpen(false)
  }

  const handleAddConfirm = async (values) => {
    const { docNumber } = values
    const docNumberDigits = extractOnlyNumbers(docNumber)

    if (!isDocNumberValid(docNumberDigits)) {
      toast.error(`${panelEnv.toUpperCase()} inválido!`)
      return
    }

    try {
      await postBlacklist(apiKey, userId, panelEnv, docNumberDigits)

      setLoading(true)
      listBlacklist()
    } catch (err) {
      reportError(err, `Erro ao adicionar nos ${title}`)
      return
    }

    toast.success('Adicionado com sucesso!')
  }

  const handleBatchConsultConfirm = async (values) => {
    try {
      await postCreateBlacklistBatch(
        apiKey,
        userId,
        panelEnv,
        values.description,
        values.docNumbers,
        {
          overwrite: values.overwrite,
        },
      )
      listBlacklist()
    } catch (err) {
      reportError(err, `Erro ao adicionar nos ${title}`)
      return
    }

    toast.success('Adicionado com sucesso! O lote esta sendo processado.')
  }

  const renderActionButtons = (item) => {
    return (
      <div
        className="icon-button"
        onClick={(e) => handleOpenDialogDelete(e, item[panelEnv])}
      >
        <DeleteForeverIcon style={{ color: "#FF0000" }} />
      </div>
    )
  }

  const renderDocBlacklistList = (list) => {
    return _.map(list, item => (
      <tr key={item[panelEnv]} onClick={() => handleToggleModal(item)} className="handler">
        <td className="sticky-col first-col">{formatDocNumber(item[panelEnv], panelEnv)}</td>
        <td>{formatDate(item.created_at)}</td>

        <td className="sticky-col last-col">
          <div className="buttons-container">
            {renderActionButtons(item)}
          </div>
        </td>
      </tr>
    ))
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <TitleView
          type="Consultas"
          title={title}
          subtitle={`Lista de ${panelEnv.toUpperCase()}s que são reprovados automaticamente`}
        />

        <div className="main">
          <div className="button-add-container">
            <ButtonConsult
              btnConfirmText="Adicionar"
              btnMainText={`Adicionar ${panelEnv}`}
              onConfirm={handleAddConfirm}
            />
          </div>

          <div className="button-add-container">
            <ButtonBatchConsult
              btnConfirmText="Adicionar Lote"
              btnMainText={`Adicionar ${panelEnv}s em Lote`}
              onConfirm={handleBatchConsultConfirm}
              showBlacklistOverwite={true}
            />
          </div>

          <div className="first-line">
            <div className="requests-container">
              <span className="counter">{`Total de bloqueados: ${totalRequests}`}</span>
            </div>
            <SearchDoc onSearch={(term) => handleDocFilter(term)} type={panelEnv} />
          </div>

          <div className="view">
            {loading
            ? <div className="loader-container"><CircularProgress /></div>
            : (
              <div className="wrapper">
                <Table className="table">
                  <thead>
                    <tr>
                      <th className="sticky-col first-col">Documento</th>
                      <th>Data</th>
                      <th className="sticky-col last-col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      consultsList.length === 0
                      ? (<tr><td colSpan={3}>Nenhum registro encontrado.</td></tr>)
                      : renderDocBlacklistList(consultsList)
                    }
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChangePage}
          />

          <DialogConfirm
            open={dialogDeleteOpen}
            onConfirm={(e) => handleDocDeleteConfirm(e, docToDelete)}
            onCancel={(e) => handleDocDeleteCancel(e)}
            title="Remover"
            content={`Tem certeza que deseja remover o ${panelEnv.toUpperCase()} ${formatDocNumber(docToDelete, panelEnv)}?`}
          />

          <Modal
            open={modalOpen}
            onClose={handleToggleModal}
            title={`${panelEnv}: ${formatDocNumber(itemSelected.docNumber, panelEnv)}`}
          >
            <div>{itemSelected.docNumber}</div>
          </Modal>

        </div>
      </Container>
    </ThemeProvider>
  )
}
