import React from 'react'
import { withStyles } from '@material-ui/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'

import {
  Divider,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { CardTitle } from './styledComponents'

import styles from './styles'

const ExpansionPanelCustom = withStyles({
  root: {
    border: '2px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    borderRadius: 15,
  },
  rounded: {
    '&:first-child': {
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
    '&:last-child': {
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
    },
  },
})(MuiAccordion)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#FFF',
    borderRadius: 15,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})(MuiAccordionSummary)

export default function ExpansionPanel({
  children,
  size = 'default',
  subtitle,
  title,
}) {
  const classes = styles()

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <ExpansionPanelCustom
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        square={false}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon fontSize={size} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <CardTitle size={size}>{title}</CardTitle>
          {subtitle}
        </ExpansionPanelSummary>

        <Divider />

        <AccordionDetails>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {children}
          </div>
        </AccordionDetails>
      </ExpansionPanelCustom>
    </div>
  )
}
