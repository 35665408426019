import { formatDate } from 'utils/masks'
import { isPermissionAdminOrGreater } from 'utils/validators'

const defaultOptions = {
  setCellProps: () => ({ style: { lineHeight: 0.2, cursor: 'pointer' } }),
}

export const columnsBatch = (userPermission) => [
  {
    name: 'description',
    label: 'Descrição',
    options: {
      ...defaultOptions,
    },
  },
  {
    name: 'quantity',
    label: 'Quantidade',
    options: {
      ...defaultOptions,
    },
  },
  {
    name: 'created_at',
    label: 'Data de Criação',
    options: {
      ...defaultOptions,
      customBodyRender: (value) => formatDate(value),
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      ...defaultOptions,
    },
  },
  {
    name: 'user_email',
    label: 'Criado por',
    options: {
      ...defaultOptions,
      display: isPermissionAdminOrGreater(userPermission) ? 'true' : 'excluded',
    },
  },
]
