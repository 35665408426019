import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'

export default function ({ location }) {
  const title = 'Total'
  const subtitle = 'Todas as consultas'
  const analysisStatus = null
  const color = '#ddd'

  const arrayAnalysisStatus = []

  return (
    <BaseConsultsList
      location={location}
      title={title}
      subtitle={subtitle}
      color={color}
      analysisStatus={analysisStatus}
      actionsAnalysisStatus={arrayAnalysisStatus}
    />
  )
}
