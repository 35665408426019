import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { GROUP_ID, nameGroupId } from 'utils/constants/groupService'
import serviceStatusConstants from 'utils/constants/serviceStatus'
import analysisStatusConstants from 'utils/constants/analysisStatus'

import ExpansionPanel from 'ui/components/ExpansionPanel'
import ExpansionPanelCertificates from 'ui/components/ContainerCommonPanels/ExpansionPanelCertificates'
import ExpansionPanelTRFProcesses from 'ui/components/ContainerCommonPanels/ExpansionPanelTRFProcesses'
import ExpansionPanelNegativeMedia from 'ui/components/ContainerCommonPanels/ExpansionPanelNegativeMedia'
import ExpansionPanelCvm from 'ui/components/ContainerCommonPanels/ExpansionPanelCvm'
import ExpansionPanelProcessesPJE from 'ui/components/ContainerCommonPanels/ExpansionPanelProcessesPJE'
import ExpansionPanelJudicialCriminalProcedures from 'ui/components/ContainerCommonPanels/ExpansionPanelJudicialCriminalProcedures'
import ExpansionPanelProcessesTJ from 'ui/components/ContainerCommonPanels/ExpansionPanelProcessesTJ'

import PanelSubtitle from 'ui/components/ContainerCommonPanels/Panels/PanelSubtitle'
import PanelTitle from 'ui/components/ContainerCommonPanels/Panels/PanelTitle'

import ExpansionPanelCadastralData from '../ExpansionPanelCadastralData'
import ExpansionPanelCadastralExtension from '../ExpansionPanelCadastralExtension'
import ExpansionPanelCreditHistory from '../ExpansionPanelCreditHistory'
import ExpansionPanelProtestsAccounts from '../ExpansionPanelProtestsAccounts'
import ExpansionPanelIBAMA from '../ExpansionPanelIBAMA'

const Panels = ({ docData }) => {
  const servicesList = useSelector((state) => state.planCnpjServices.services)

  const filterServicesByGroupId = (services, groupId) => {
    const servicesIdsPermitted = _
      .chain(servicesList)
      .filter({ group_id: groupId })
      .map((serv) => serv.service_id)
      .value()

    return _.filter(services, (service) => servicesIdsPermitted.includes(service.service_id))
  }

  /** Sobrescreve a análise de status para groupIds especificados */
  const defineAnalysisStatus = (servicesFiltered) => {
    if (_.some(servicesFiltered, { analysis_status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved })) {
      return analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved
    }
    if (_.some(servicesFiltered, { analysis_status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention })) {
      return analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention
    }
    if (_.every(servicesFiltered, { analysis_status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved })) {
      return analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved
    }

    return null
  }

  const renderPanel = (groupId) => {
    let serviceInfos = docData.service_infos
    serviceInfos = _.map(servicesList, (serviceDesc) => {
      const serviceInfo = _.find(serviceInfos, { service_id: serviceDesc.service_id })
      return {
        ...serviceInfo,
        service_id: serviceDesc.service_id,
        name: serviceDesc.name,
        name_short: serviceDesc.name_short,
        response_time: serviceDesc.response_time,
      }
    })

    let elemPanel = null
    let servicesFiltered = []
    switch (groupId) {
      case GROUP_ID.CADASTRAL_DATA: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CADASTRAL_DATA)
        elemPanel = <ExpansionPanelCadastralData serviceInfos={servicesFiltered} docData={docData} />
        break
      }
      case GROUP_ID.CADASTRAL_EXTENSION: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CADASTRAL_EXTENSION)
        elemPanel = <ExpansionPanelCadastralExtension serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.CREDIT_HISTORY: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CREDIT_HISTORY)
        elemPanel = <ExpansionPanelCreditHistory serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.NEGATIVE_MEDIA: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.NEGATIVE_MEDIA)
        elemPanel = <ExpansionPanelNegativeMedia serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.CVM: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CVM)
        elemPanel = <ExpansionPanelCvm serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.IBAMA: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.IBAMA)
        elemPanel = <ExpansionPanelIBAMA serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.PROTESTS_ACCOUNTS: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.PROTESTS_ACCOUNTS)
        elemPanel = <ExpansionPanelProtestsAccounts serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.CERTIFICATES: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CERTIFICATES)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.CERTIFICATES_TRF: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CERTIFICATES_TRF)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.TRF_PROCESSES: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.TRF_PROCESSES)
        elemPanel = <ExpansionPanelTRFProcesses serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.COURT_LAWSUITS: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.COURT_LAWSUITS)
        elemPanel = <ExpansionPanelJudicialCriminalProcedures serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.LABOR_COURTS: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.LABOR_COURTS)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.PJE_FIRST_INSTANCE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.PJE_FIRST_INSTANCE)
        elemPanel = <ExpansionPanelProcessesPJE serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.PJE_SECOND_INSTANCE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.PJE_SECOND_INSTANCE)
        elemPanel = <ExpansionPanelProcessesPJE serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.TJ_FIRST_INSTANCE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.TJ_FIRST_INSTANCE)
        elemPanel = <ExpansionPanelProcessesTJ serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.TJ_SECOND_INSTANCE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.TJ_SECOND_INSTANCE)
        elemPanel = <ExpansionPanelProcessesTJ serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.TRT_CERTIFICATE_PJE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.TRT_CERTIFICATE_PJE)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} sortBy={null} />
        break
      }
      case GROUP_ID.TRT_CERTIFICATE_PHYSICAL: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.TRT_CERTIFICATE_PHYSICAL)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} sortBy={null} />
        break
      }
      case GROUP_ID.STATE_TAX_DEBTS: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.STATE_TAX_DEBTS)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} sortBy={null} />
        break
      }
      case GROUP_ID.SINTEGRA: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.SINTEGRA)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} sortBy={null} />
        break
      }
      default:
        return null
    }

    if (_.isEmpty(servicesFiltered)) return null

    let status = null
    if (_.some(servicesFiltered, { status: serviceStatusConstants.SERVICE_STATUS.error })) {
      status = serviceStatusConstants.SERVICE_STATUS.error
    } else if (_.some(servicesFiltered, { status: serviceStatusConstants.SERVICE_STATUS.processing })) {
      status = serviceStatusConstants.SERVICE_STATUS.processing
    } else if (_.every(servicesFiltered, { status: serviceStatusConstants.SERVICE_STATUS.processed })) {
      status = serviceStatusConstants.SERVICE_STATUS.processed
    }

    const analysisStatus = defineAnalysisStatus(servicesFiltered)

    return (
      <ExpansionPanel
        key={groupId}
        title={<PanelTitle text={nameGroupId(groupId)} analysisStatus={analysisStatus} />}
        subtitle={<PanelSubtitle status={status} analysisStatus={analysisStatus} />}
      >
        {elemPanel}
      </ExpansionPanel>
    )
  }

  return (
    <React.Fragment>
      {
        _.map(GROUP_ID, (groupId) => renderPanel(groupId))
      }
    </React.Fragment>
  )
}

export default Panels
