import React from 'react'
import { TextField, Chip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import styles from './styles'

const MuiChipInput = ({
  label,
  placeholder,
  disabled = false,
  fullWidth,
  value = [],
  onChange,
  onPaste,
  style = {},
}) => {
  const classes = styles()

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      freeSolo
      value={value}
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={(e, values) => onChange(e, values)}
      onPaste={onPaste}
      renderTags={(values, getTagProps) => (
        values.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option}
          />
        ))
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{ root: classes.textField }}
          size="medium"
          disabled={disabled}
          onPaste={(e) => onPaste && e.preventDefault()}
          style={style}
          fullWidth={fullWidth}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

export default MuiChipInput
