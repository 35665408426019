const USER_PERMISSION = {
  COMMON: 'COMMON',
  ADMIN: 'ADMIN',
  MASTER: 'MASTER',
}

const USER_ROLE = {
  ANALYST: 'ANALYST',
  SUPERVISOR: 'SUPERVISOR',
}

const permissionName = {
  [USER_PERMISSION.COMMON]: 'Normal',
  [USER_PERMISSION.ADMIN]: 'Administrador',
  [USER_PERMISSION.MASTER]: 'Master',
}

const roleName = {
  [USER_ROLE.ANALYST]: 'Analista',
  [USER_ROLE.SUPERVISOR]: 'Coordenador',
}

const translateRole = (key) => {
  return roleName[key] || key
}

const translatePermission = (key) => {
  return permissionName[key] || key
}

export {
  USER_ROLE,
  USER_PERMISSION,
  translateRole,
  translatePermission,
}
