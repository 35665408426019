import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'
import moment from 'moment'

import {
  Divider,
  TextField,
} from '@material-ui/core'

import { Button, DialogConfirm, Radio } from 'ui/components'
import analysisStatusConstants, { getAnalysisStatusMoveToName } from 'utils/constants/analysisStatus'
import { MANUAL_DERIVATION_STATUS } from 'utils/constants/manualDerivationStatus'
import { USER_ROLE, translateRole } from 'utils/constants/user'

import {
  getAnalysis,
  postCreateAnnotation,
  putSetUser,
  removeUser,
} from './functions'
import styles from './styles'

const DESCRIPTION = {
  MIN: 1,
  MAX: 2000,
}

const RADIOS = [
  {
    label: 'Aprovado',
    status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved,
  },
  {
    label: 'Reprovado',
    status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved,
  },
]

const initialState = {
  analysisCurrent: null,
  analysisStatus: null,
  btnDialogOpenDisabled: false,
  description: '',
  dialogOpen: false,
  formValid: false,
}

export default ({ manualAnalysis, manualDerivationToRole, onConfirm }) => {
  const classes = styles()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const userEmail = useSelector((state) => state.user.email)
  const userRole = useSelector((state) => state.user.role)

  const [dialogOpen, setDialogOpen] = useState(initialState.dialogOpen)
  const [btnDialogOpenDisabled, setBtnDialogOpenDisabled] = useState(initialState.btnDialogOpenDisabled)
  const [analysisStatus, setAnalysisStatus] = useState(initialState.analysisStatus)
  const [description, setDescription] = useState(initialState.description)
  const [formValid, setFormValid] = useState(initialState.formValid)
  const [analysisCurrent, setAnalysisCurrent] = useState(initialState.analysisCurrent)

  let disabledBtn = true
  let showForm = false
  let btnText = 'Ver analise'
  switch (manualAnalysis.status) {
    case MANUAL_DERIVATION_STATUS.FINALIZED: {
      disabledBtn = false
      break
    }
    case MANUAL_DERIVATION_STATUS.IN_QUEUE: {
      if (userRole === manualDerivationToRole) {
        disabledBtn = false
        showForm = true
        btnText = 'Fazer analise'
      } else if (userRole === USER_ROLE.ANALYST && manualDerivationToRole === USER_ROLE.SUPERVISOR) {
        disabledBtn = false
        showForm = false
      } else {
        disabledBtn = true
        btnText = 'Você não tem permissão para fazer a analise'
      }
      break
    }
    case MANUAL_DERIVATION_STATUS.IN_ANALYSIS: {
      // TODO: a comparacao deveria ser por userId, mas para nao trafegar ele pela rede, foi feita via email
      if (_.toUpper(userEmail) === _.toUpper(manualAnalysis.user.email)) {
        disabledBtn = false
        showForm = true
        btnText = 'Fazer analise'
      } else {
        btnText = `Analise sendo feita pelo usuário: "${manualAnalysis.user.email}"`
      }
      break
    }
    default:
      break
  }

  const validateForm = useCallback(() => {
    if (!showForm) {
      setFormValid(false)
      return
    }

    const descLength = description.length
    if (descLength < DESCRIPTION.MIN || descLength > DESCRIPTION.MAX) {
      setFormValid(false)
      return
    }

    if (!_.some(RADIOS, { status: analysisStatus })) {
      setFormValid(false)
      return
    }

    setFormValid(true)
  }, [analysisStatus, description, showForm])

  useEffect(() => {
    validateForm()
  }, [analysisStatus, description, validateForm])

  const resetStates = () => {
    setDialogOpen(initialState.dialogOpen)
    setBtnDialogOpenDisabled(initialState.btnDialogOpenDisabled)
    setAnalysisStatus(initialState.analysisStatus)
    setDescription(initialState.description)
    setFormValid(initialState.formValid)
    setAnalysisCurrent(initialState.analysisCurrent)
  }

  const handleOpenModal = async () => {
    setBtnDialogOpenDisabled(true)

    let setUserOk = false
    if (showForm) {
      setUserOk = await putSetUser(apiKey, userId, manualAnalysis.analysis_id)
      if (setUserOk !== true) return
    }

    const resAnalysis = await getAnalysis(apiKey, userId, manualAnalysis.analysis_id)
    if (resAnalysis === null) return

    setAnalysisCurrent(resAnalysis)
    setDialogOpen(true)
    setBtnDialogOpenDisabled(false)
  }

  const handleDialogConfirm = async () => {
    if (showForm) {
      const createOk = await postCreateAnnotation(
        apiKey,
        userId,
        manualAnalysis.analysis_id,
        analysisStatus,
        description,
      )
      if (createOk !== true) return

      toast.success('Analise feita com sucesso.')
      if (_.isFunction(onConfirm)) onConfirm()
    }

    setDialogOpen(false)
    resetStates()
  }

  const handleDialogCancel = async () => {
    if (showForm) {
      await removeUser(
        apiKey,
        userId,
        manualAnalysis.analysis_id,
      )
    }

    setDialogOpen(false)
    resetStates()
  }

  const handleClickRadio = (status) => {
    setAnalysisStatus(status)
  }

  const handleChangeDescription = (e) => {
    setDescription(e.target.value)
  }

  const renderAnnotations = () => {
    if (_.isEmpty(analysisCurrent)) return null

    return (
      <div className={classes.annotationsContainer}>
        {
          _.map(analysisCurrent.annotations, (annotation, i) => (
            <React.Fragment key={i.toString()}>
              <div className={classes.annotationContainer}>
                <p>{`Analise #${i + 1}`}</p>
                <p>
                  <strong>Usuário:</strong>
                  {' '}
                  <span>{annotation.user.email}</span>
                  {' - '}
                  <span>{translateRole(annotation.user.role)}</span>
                </p>
                <p>
                  <strong>Criado em:</strong>
                  {' '}
                  <span>{moment(annotation.created_at).format("DD/MM/YYYY HH:mm")}</span>
                </p>
                <p>
                  <strong>Status:</strong>
                  {' '}
                  <span>{getAnalysisStatusMoveToName(annotation.analysis_status)}</span>
                </p>
                <p>
                  <strong>Descrição:</strong>
                  {' '}
                  <span>{annotation.description}</span>
                </p>
              </div>
              <Divider />
            </React.Fragment>
          ))
        }
      </div>
    )
  }

  const renderRadioboxAnalysisStatus = () => {
    return (
      <div className={classes.radioContainer}>
        {
          _.map(RADIOS, (radio) => (
            <Radio
              key={radio.status}
              label={radio.label}
              value={radio.status === analysisStatus}
              onClick={() => handleClickRadio(radio.status)}
            />
          ))
        }
      </div>
    )
  }

  const renderTextarea = () => {
    return (
      <div className={classes.textareaContainer}>
        <TextField
          label="Descrição"
          placeholder="Descreva a analise"
          fullWidth={true}
          multiline={true}
          required={true}
          rows={15}
          size="medium"
          onChange={handleChangeDescription}
          value={description}
          inputProps={{
            maxLength: DESCRIPTION.MAX,
            minLength: DESCRIPTION.MIN,
          }}
        />
      </div>
    )
  }

  const renderFormAnalysis = () => {
   if (!showForm) return null

    return (
      <React.Fragment>
        {renderRadioboxAnalysisStatus()}
        {renderTextarea()}
      </React.Fragment>
    )
  }

  const renderAnalysis = () => {
    return (
      <div className={classes.dialogContainer}>
        {renderAnnotations()}
        {renderFormAnalysis()}
      </div>
    )
  }

  const renderBtnDesc = () => btnText

  return (
    <div className={classes.container}>
      <Button
        onClick={handleOpenModal}
        disabled={btnDialogOpenDisabled || disabledBtn}
      >
        {renderBtnDesc()}
      </Button>

      <DialogConfirm
        open={dialogOpen}
        onConfirm={handleDialogConfirm}
        onCancel={handleDialogCancel}
        btnCancelText="Cancelar"
        btnConfirmDisabled={!formValid}
        title="Analise Manual do caso"
        content={renderAnalysis()}
      />
    </div>
  )
}
