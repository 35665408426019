import React, { useState } from 'react'
import styled from 'styled-components'

import { Modal } from 'ui/components'

const Container = styled.div`
`

export default function ({ history }) {
  const [modalOpen, setModalOpen] = useState(false)

  const handleReturn = function () {
    history.goBack()
  }

  const handleToggleModal = function () {
    setModalOpen(!modalOpen)
  }

  return (
    <Container>
      <nav>
        <button type="button" onClick={handleReturn}>
          Voltar
        </button>
      </nav>

      <button type="button" onClick={handleToggleModal}>
        Abrir Modal
      </button>

      <Modal open={modalOpen} onClose={handleToggleModal}>
        <h1>Exemplo de Modal</h1>
      </Modal>
    </Container>
  )
}
