import React from 'react'
import {
  TableCell,
  TableRow,
} from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'

const MemberWithoutDocNumberRow = ({ member }) => {
  return (
    <TableRow>
      <TableCell />
      <TableCell component="th" scope="row">
        {member.name}
      </TableCell>
      <TableCell><RemoveIcon style={{ color: '#ddd' }} /></TableCell>
      <TableCell><RemoveIcon style={{ color: '#ddd' }} /></TableCell>
      <TableCell>{member.qualification}</TableCell>
      <TableCell>
        {member.start_date || <RemoveIcon style={{ color: '#ddd' }} />}
      </TableCell>
      <TableCell>
        <RemoveIcon style={{ color: '#ddd' }} />
      </TableCell>
      <TableCell>
        <RemoveIcon style={{ color: '#ddd' }} />
      </TableCell>
    </TableRow>
  )
}

export default MemberWithoutDocNumberRow
