import React from 'react'
import PropTypes from 'prop-types'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { FormControl, FormLabel, FormGroup } from '@material-ui/core'

import {
  Switch,
  Tooltip,
} from 'ui/components'

const SwitchPdfType = ({ onChange }) => {
  return (
    <FormControl component="fieldset" style={{ marginTop: 15 }}>
      <div style={{ display: 'flex', marginBottom: 5, justifyContent: 'space-between', alignItems: 'center' }}>
        <FormLabel component="legend" style={{ fontSize: 14 }}>Tipo de Relatório PDF</FormLabel>
        <Tooltip
          leaveDelay={800}
          title={(
            <React.Fragment>
              <h3 color="inherit">Tipo de relatório de PDF</h3>
              <div>
                <h4>Relatório completo:</h4>
                <p>Retorna todos os serviços no PDF, mostrando os serviços requisitados e não requisitados.</p>
              </div>
              <div>
                <h4>Relatório resumido:</h4>
                <p>Retorna apenas os serviços requisitados no PDF, facilitando a leitura.</p>
              </div>
            </React.Fragment>
          )}
        >
          <HelpOutlineIcon style={{ fontSize: 12 }} />
        </Tooltip>
      </div>

      <FormGroup>
        <Switch labelOff="Resumido" labelOn="Completo" onChange={onChange} />
      </FormGroup>
    </FormControl>
  )
}

SwitchPdfType.propTypes = {
  /**
   * This method is called when the switch value is changed
   */
  onChange: PropTypes.func.isRequired,
}

export default SwitchPdfType
