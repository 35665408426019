import React from 'react'
import _ from 'lodash'
import CadastralData from './CadastralData'

import RecursiveMembershipboard from './RecursiveMembershipboard'

const ExpansionPanelCadastralData = ({ serviceInfos, docData }) => {
  const qsa = _
    .chain(serviceInfos)
    .find({ service_id: 'membership_board_v2' })
    .get('data')
    .value() || {}

  const recursiveQsa = _
    .chain(serviceInfos)
    .find({ service_id: 'membership_board_recursive' })
    .value() || {}

  const isRecursiveQsaPresent = !_.isEmpty(recursiveQsa.status)

  const renderRecursiveQsa = () => {
    if (!isRecursiveQsaPresent) return null

    return (
      <RecursiveMembershipboard
        membershipBoardRecursiveService={recursiveQsa}
        docData={docData}
      />
    )
  }

  return (
    <React.Fragment>
      <CadastralData membershipBoardService={qsa} isMembershipBoardRecursivePresent={isRecursiveQsaPresent} />

      {renderRecursiveQsa()}
    </React.Fragment>
  )
}

export default ExpansionPanelCadastralData
