// Rotas de admin

import { objToQueryString } from 'utils/functions'
import axios from './axiosCustom'

import {
  HOST,
  headers,
} from './apiMotorBgc'

export async function getUsers(apiKey, userId, orgId, params = {}) {
  const { email, page } = params

  const queryString = objToQueryString({
    user_id: userId,
    org_id: orgId,
    email: email,
    page: page || 1,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/admin/user/list?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function postCreateUser(apiKey, userId, email, permission, orgId, authAccessToken) {
  const data = {
    user_id: userId,
    email: email,
    permission: permission,
    org_id: orgId,
    auth_access_token: authAccessToken,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/admin/user`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function putAdminUser(apiKey, userId, authAccessToken, params = {}) {
  const { userEmailToUpdate, userData } = params

  const data = {
    user_id: userId,
    user_email_to_update: userEmailToUpdate,
    data: userData,
    auth_access_token: authAccessToken,
  }

  const res = await axios({
    method: 'PUT',
    url: `${HOST}/admin/user`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}
