import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(_theme => ({
  dialogContainerContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dialogContentText: {
    fontSize: 16,
  },
  dialogContentContainerInput: {
    marginTop: 15,
    width: '80%',
  },
}))

export default styles

