export const FETCH_PLAN_CNPJ_LAST = 'FETCH_PLAN_CNPJ_LAST'
export const FETCH_PLAN_CNPJ_LAST_FAILED = 'FETCH_PLAN_CNPJ_LAST_FAILED'
export const FETCH_PLAN_CNPJ_LAST_SUCCEEDED = 'FETCH_PLAN_CNPJ_LAST_SUCCEEDED'

export const CREATE_PLAN_CNPJ = 'CREATE_PLAN_CNPJ'
export const CREATE_PLAN_CNPJ_FAILED = 'CREATE_PLAN_CNPJ_FAILED'
export const CREATE_PLAN_CNPJ_SUCCEEDED = 'CREATE_PLAN_CNPJ_SUCCEEDED'

export const fetchPlanCnpjLast = () => ({
  type: FETCH_PLAN_CNPJ_LAST,
})

export const createPlanCnpj = payload => ({
  type: CREATE_PLAN_CNPJ,
  payload: payload,
})
