import styled from 'styled-components'

export default styled.div`
  width: 100%;

  .loader-container {
    display: flex;
    justify-content: center;
  }

  .table-container {
    width: 100%;

    tr {
      cursor: pointer;

      td {
        padding: 10px;
      }
    }
  }
`
