import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#212121',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip)

export default LightTooltip
