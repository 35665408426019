import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { CircularProgress } from '@material-ui/core'

import {
  Container,
  Modal,
  Pagination,
  Table,
  TitleView,
} from 'ui/components'
import BaseConsultsList from 'ui/views/consults/BaseConsultsList'

import { getListBatch } from 'services/apiMotorBgc'
import { BATCH_STATUS } from 'utils/constants/batchStatus'
import { formatDate } from 'utils/masks'
import { isPermissionAdminOrGreater } from 'utils/validators'

const BaseBatchConsultList = ({ status, title, subtitle }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector(state => state.auth.panelEnv)
  const userPermission = useSelector(state => state.user.permission)

  const savedCallbackListBatches = useRef()

  const [loading, setLoading] = useState(false)
  const [batchList, setBatchList] = useState([])
  const [totalRequests, setTotalRequests] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const [modalOpen, setModalOpen] = useState(false)
  const [batchData, setBatchData] = useState({})

  const listBatches = async (options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getListBatch(
        apiKey,
        userId,
        panelEnv,
        {
          status: status,
          page: _.has(options, 'page') ? options.page : currentPage,
        },
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao listar lotes'
      toast.error(messageError)
      setLoading(false)
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalRequests(xTotal)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setBatchList(res.data)
    setLoading(false)
  }

  const callbackListBatches = () => listBatches()

  useEffect(() => {
    savedCallbackListBatches.current = callbackListBatches
  })

  useEffect(() => {
    listBatches()

    const callListBatches = () => savedCallbackListBatches.current()
    const intervalId = setInterval(callListBatches, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = async (event, page) => {
    const options = {
      page: page,
    }

    listBatches(options)
  }

  const handleClickBatch = (batch) => {
    setBatchData(batch)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setBatchData({})
    setModalOpen(false)
  }

  const renderHeaderUserEmail = () => {
    if (!isPermissionAdminOrGreater(userPermission)) return null

    return (
      <th>Criado por</th>
    )
  }

  const renderDataUserEmail = (batch) => {
    if (!isPermissionAdminOrGreater(userPermission)) return null

    return (
      <td>{batch.user_email}</td>
    )
  }

  const renderListBatches = () => {
    if (loading) return <tr><td colSpan={2}><CircularProgress /></td></tr>

    if (batchList.length === 0) {
      return <tr><td colSpan={2}>Nenhum registro encontrado</td></tr>
    }

    return (
      <React.Fragment>
        {batchList.map(batch => (
          <tr
            key={batch.batch_id}
            onClick={() => handleClickBatch(batch)}
            style={{ cursor: 'pointer' }}
          >
            <td>
              {batch.description}
            </td>
            <td>
              {batch.quantity}
            </td>
            <td>
              {formatDate(batch.created_at)}
            </td>
            {renderDataUserEmail(batch)}
          </tr>
        ))}
      </React.Fragment>
    )
  }

  const renderTitleModal = () => {
    return [
      `Lote: ${batchData.description}`,
      `Data: ${formatDate(batchData.created_at)}`,
    ].join(' | ')
  }

  return (
    <Container>
      <TitleView
        type="Consultas em Lote"
        title={title}
        subtitle={subtitle}
      />

      <div className="main">
        <div className="requests-container">
          <div>
            <div className="title-container">
              <div className="title">
                LOTES
              </div>

              <div>
                <div className="total-requests">{`Quantidade: ${totalRequests}`}</div>
              </div>
            </div>

            <Table className="table">
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Quantidade</th>
                  <th>Data</th>
                  {renderHeaderUserEmail()}
                </tr>
              </thead>
              <tbody>
                {renderListBatches()}
              </tbody>
            </Table>

            <Pagination
              id="pagination"
              count={totalPages}
              page={currentPage}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </div>

      <Modal
        onClose={handleCloseModal}
        open={modalOpen}
        title={renderTitleModal()}
      >
        <BaseConsultsList
          actionsAnalysisStatus={[]}
          batchId={batchData.batch_id}
          location=""
          title={title}
        />
      </Modal>
    </Container>
  )
}

BaseBatchConsultList.propTypes = {
  status: PropTypes.oneOf(Object.values(BATCH_STATUS)).isRequired,
  title: PropTypes.string.isRequired,
}

export default BaseBatchConsultList
