import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import JSZip from 'jszip'
import saveAs from 'file-saver'

import { toast } from 'react-toastify'
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'

import { getExportReport } from 'services/apiMotorBgc'
import { getExtensionFileName } from 'utils/functions'
import reportError from 'utils/errorReporter'

const getReportUrls = async (apiKey, userId, panelEnv, docId, withOnlyRequestedServices) => {
  const res = await getExportReport(
    apiKey,
    userId,
    panelEnv,
    {
      docId: docId,
      exportType: 'PDF',
      withOnlyRequestedServices: withOnlyRequestedServices,
    },
  )

  return res.data
}

const urlToPromise = async (url) => {
  return new Promise((resolve, reject) => {
    const config = {
      responseType: 'arraybuffer',
      headers: {
        'Cache-Control': 'no-cache',
      },
    }

    axios.get(url, config).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

const saveZip = async (fileName, mainUrl, servicesUrls) => {
  const zip = new JSZip()

  zip.file(
    `relatorio.${getExtensionFileName(mainUrl.document_url)}`,
    urlToPromise(mainUrl.document_url),
    { binary: true },
  )

  servicesUrls.forEach(item => {
    const pdfName = item.name
    const extension = getExtensionFileName(item.document_url)
    const folder = 'certidoes'

    zip.file(
      `${folder}/${pdfName}.${extension}`,
      urlToPromise(item.document_url),
      { binary: true },
    )
  })

  const zipContent = await zip.generateAsync({ type: 'blob' })
  const zipName = `${fileName}.zip`
  saveAs(zipContent, zipName)
}

const ButtonDownloadReportPDF = ({ createdAt, docId, value, withOnlyRequestedServices }) => {
  const panelEnv = useSelector(state => state.auth.panelEnv)
  const userId = useSelector(state => state.auth.sub)
  const apiKey = useSelector(state => state.auth.api_key)
  const [loading, setLoading] = useState(false)

  const downloadText = withOnlyRequestedServices
    ? 'Baixar PDF (resumido)'
    : 'Baixar PDF (completo)'

  const handleClick = async (e) => {
    e.stopPropagation()

    if (loading) {
      toast.info('O relatório está sendo gerado. Aguarde alguns segundos')
      return
    }
    setLoading(true)

    try {
      let reportUrls = null
      try {
        reportUrls = await getReportUrls(apiKey, userId, panelEnv, docId, withOnlyRequestedServices)
      } catch (err) {
        reportError(err, 'Houve um problema ao obter o relatório')
        return
      }

      const nameDate = moment(createdAt).format('DD-MM-YYYY_HH-mm')
      const fileName = `bgc_${value}_${nameDate}`

      saveZip(fileName, reportUrls.main, reportUrls.services)
        .catch((err) => {
          reportError(err, 'Houve um problema ao obter o relatório')
        })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Tooltip title={downloadText} aria-label={downloadText}>
      {loading ? (
        <CircularProgress
          size="1.6rem"
          color="secondary"
          onClick={handleClick}
        />
      ) : (
        <IconButton
          aria-label={downloadText}
          edge="end"
          size="small"
          onClick={handleClick}
        >
          <GetAppOutlinedIcon fontSize="inherit" />
        </IconButton>
      )}
    </Tooltip>
  )
}

export default ButtonDownloadReportPDF
