import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  buttonContainer: {
     width: 300,

    [theme.breakpoints.down('sm')]: {
       width: '100%',
    },
  },
  button: {
    width: 'inherit',
    height: '50px',
    borderRadius: 30,
    marginTop: '50px',
    marginBottom: 20,
    '&:hover':{
      backgroundColor: theme.palette.primary.hover,
    },
  },
  progress: {
    height: '25px !important',
    margin: theme.spacing(1),
    width: '25px !important',
  },
  recaptchaBadge: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
}))

export default styles
