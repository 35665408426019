import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Grid, Link, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { DialogConfirm } from 'ui/components'
import ModalDataTable from '../ModalDataTable'


function BatchConsultModal({ modalOpen, onCancel, onConfirm, docsModalData }) {
  const panelEnv = useSelector(state => state.auth.panelEnv)
  const panelEnvUpper = panelEnv?.toUpperCase()

  const [showDocTables, setShowDocTables] = useState(false)

  useEffect(() => {
    if (modalOpen) return

    setShowDocTables(false)
  }, [modalOpen])

  const handleShowDocTables = () => {
    setShowDocTables(!showDocTables)
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
  }

  const handleConfirm = () => {
    if (onConfirm) onConfirm()
  }

  const textBtnCancel = () => {
    let textBtn = 'Voltar'

    if (
      !_.isEmpty(docsModalData.docsDuplicates)
      || !_.isEmpty(docsModalData.invalidDocs)
    ) {
      textBtn += ' e corrigir os valores'
    }

    return textBtn
  }

  const textBtnConfirm = () => {
    let textBtn

    if (
      !_.isEmpty(docsModalData.docsDuplicates)
      || !_.isEmpty(docsModalData.invalidDocs)
    ) {
      textBtn = `Ignorar erros e processar ${docsModalData.uniqueValidDocs.length} ${panelEnvUpper}${docsModalData.uniqueValidDocs.length > 1 ? 's' : ''}`
    } else {
      textBtn = `Confirmar o processamento de ${docsModalData.uniqueValidDocs.length} ${panelEnvUpper}${docsModalData.uniqueValidDocs.length > 1 ? 's' : ''}`
    }

    return textBtn
  }

  const renderTotalTexts = () => {
    const tableDataRows =
    [
      { name: 'Total de registros inseridos', quantity: docsModalData.totalDocsProvided },
      { name: `${panelEnvUpper}s duplicados`, quantity: docsModalData.docsDuplicates.length },
      { name: `${panelEnvUpper}s inválidos`, quantity: docsModalData.invalidDocs.length },
      {
        name: <strong>{`Total de ${panelEnvUpper}s a serem enviados`}</strong>,
        quantity: <strong>{docsModalData.uniqueValidDocs.length}</strong>,
      },
    ]

    return (
      <Grid container>
        {tableDataRows.map((r, index) => (
          <Grid key={r.name} container spacing={2} item xs={12}>
            <Grid data-testid={`table-name-${index}`} item xs={10}>
              <Typography>
                {r.name}
                :
              </Typography>
            </Grid>
            <Grid data-testid={`table-qty-${index}`} item xs={2}>
              <Typography>{r.quantity}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  }

  const renderTableListDocs = () => {
    if (!showDocTables) return null

    return (
      <React.Fragment>
        <ModalDataTable
          titleText={`${panelEnvUpper}s inválidos`}
          subTitleText="Os valores inválidos serão ignorados"
          data={docsModalData.invalidDocs}
        />

        <ModalDataTable
          titleText={`${panelEnvUpper}s duplicados`}
          subTitleText="Os valores duplicados serão ignorados"
          data={docsModalData.docsDuplicates}
        />

        <ModalDataTable
          titleText={`${panelEnvUpper}s válidos`}
          data={docsModalData.uniqueValidDocs}
        />
      </React.Fragment>
    )
  }

  return (
    <DialogConfirm
      open={modalOpen}
      btnConfirmDisabled={docsModalData.uniqueValidDocs.length === 0}
      title="Consulta em lote"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      btnConfirmText={textBtnConfirm()}
      btnCancelText={textBtnCancel()}
      content={(
        <React.Fragment>
          {renderTotalTexts()}

          <br />
          <Link style={{ cursor: 'pointer' }} onClick={handleShowDocTables}>
            <Typography>
              {showDocTables ? 'Esconder' : 'Exibir'}
              {' '}
              {`lista de ${panelEnvUpper}s`}
            </Typography>
          </Link>

          {renderTableListDocs()}
        </React.Fragment>
      )}
    />
  )
}

BatchConsultModal.defaultProps = {
  onCancel: null,
  onConfirm: null,
}

BatchConsultModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  docsModalData: PropTypes.exact({
    validDocs: PropTypes.arrayOf(PropTypes.string),
    invalidDocs: PropTypes.arrayOf(PropTypes.string),
    docsDuplicates: PropTypes.arrayOf(PropTypes.string),
    uniqueValidDocs: PropTypes.arrayOf(PropTypes.string),
    totalDocsProvided: PropTypes.number,
  }).isRequired,
}

export default BatchConsultModal
