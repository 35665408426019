import _ from 'lodash'
import { PANEL_ENVS } from 'utils/constants/environments'
import { findArrayDuplicates } from 'utils/functions'
import { isDocNumberValid } from 'utils/validators'

export const processText = (textArray, docType) => {
  const invalidDocs = []
  const validDocs = []

  let textArrayTreated = _.map(textArray, (x) => x.trim())
  textArrayTreated = _.reject(textArrayTreated, (x) => _.isEmpty(x))

  const zeroLeftSize = docType === PANEL_ENVS.CNPJ ? 14 : 11
  textArrayTreated.forEach((docLine) => {
    if (/[^0-9-./]/.test(docLine)) {
      invalidDocs.push(docLine)
      return
    }

    const docLineSanitized = docLine.replace(/\D/g, '')
    const docToBeValidated = docLineSanitized.padStart(zeroLeftSize, '0')

    if (isDocNumberValid(docToBeValidated, docType)) {
      validDocs.push(docToBeValidated)
      return
    }

    invalidDocs.push(docLine)
  })

  const uniqueValidDocs = _.uniq(validDocs)

  return {
    validDocs: validDocs,
    invalidDocs: invalidDocs,
    docsDuplicates: findArrayDuplicates(validDocs),
    uniqueValidDocs: uniqueValidDocs,
    totalDocsProvided: validDocs.length + invalidDocs.length,
  }
}
