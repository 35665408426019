import React from 'react'
import MaskedInput from 'react-text-mask'

import Container from './styles'
import { cnpjMask, cpfMask, phoneMask } from 'utils/masks'

const masks = {
    cpf: cpfMask,
    cnpj: cnpjMask,
    phone: phoneMask,
}

export default (props) => {
    const { multiline, ...propsWithoutMultiline } = props
    const { label, field, form, mask } = propsWithoutMultiline
    const { touched, errors, setFieldValue } = form
    const { name } = field

    return (
        <Container>
            <div className="textinput">
                <label
                    className={`textinput__label ${
                        errors[name] && touched[name] ? 'textinput__label--error' : ''
                    }`}
                    htmlFor={name}
                >
                    {label}
                </label>
                {!mask ? (
                    <React.Fragment>
                        {!multiline ? (
                            <input
                                {...props}
                                {...field}
                                className={`textinput__input ${
                                    errors[name] && touched[name]
                                        ? 'textinput__input--error'
                                        : ''
                                }`}
                                id={name}
                                onChange={(e) =>
                                    setFieldValue(name, e.target.value)
                                }
                            />
                        ) : (
                            <textarea
                                {...propsWithoutMultiline}
                                {...field}
                                className={`textinput__area ${
                                    errors[name] && touched[name] ? 'textinput__area--error' : ''
                                }`}
                                id={name}
                                onChange={(e) =>
                                    setFieldValue(name, e.target.value)
                                }
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <MaskedInput
                        {...props}
                        {...field}
                        mask={masks[mask]}
                        className={`textinput__input ${
                            errors[name] && touched[name] ? 'textinput__input--error' : ''
                        }`}
                        id={name}
                        onChange={(e) => setFieldValue(name, e.target.value)}
                    />
                )}

                {errors[name] && touched[name] && (<div className="textinput__error">{errors[name]}</div>)}
            </div>
        </Container>
    )
}
