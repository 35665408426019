import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'

import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import { TitleBold } from 'ui/components/ContainerCommonPanels/styledComponents'
import { PANEL_ENVS } from 'utils/constants/environments'

import { columnsCpfs, columnsCnpjs } from './configDataTable'

function ModalDataTable({ titleText, subTitleText, data }) {
  const panelEnv = useSelector(state => state.auth.panelEnv)

  return (
    <React.Fragment>
      {!_.isEmpty(data) && (
        <div style={{ width: '100%' }}>
          <TitleBold style={{ marginTop: 20 }}>{titleText}</TitleBold>
          {subTitleText && <p style={{ fontSize: '1.25rem' }}>{subTitleText}</p>}

          <br />

          <DataTable
            data={_.map(data, docNumber => ({ [panelEnv]: docNumber }))}
            columns={panelEnv === PANEL_ENVS.CPF ? columnsCpfs : columnsCnpjs}
            options={{
              pagination: true,
              rowsPerPageOptions: [10, 20, 100],
              search: true,
            }}
          />
        </div>
      )}
    </React.Fragment>
  )
}

ModalDataTable.defaultProps = {
  data: null,
  subTitleText: null,
}

ModalDataTable.propTypes = {
  titleText: PropTypes.string.isRequired,
  subTitleText: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
}

export default ModalDataTable
