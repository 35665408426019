import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CircularProgress, TextField, Typography } from '@material-ui/core'

import { Button } from 'ui/components'
import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import ModalCustom from 'ui/components/Modal'

import reportError from 'utils/errorReporter'
import { getOrganizations } from 'services/apiMotorBgc'

import { isPermissionMaster } from 'utils/validators'

import { columnsOrganization } from './configDataTable'
import styles from './styles'

const ModalSelectOrganization = ({ onSelect }) => {
  const classes = styles()

  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)
  const userPermission = useSelector(state => state.user.permission)

  const [organizations, setOrganizations] = useState([])
  const [organizationSelected, setOrganizationSelected] = useState(null)

  const [searchName, setSearchName] = useState('')
  const [pageData, setPageData] = useState({ current: 1, totalItems: 0, totalPages: 0, perPage: 0 })
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  if (!isPermissionMaster(userPermission)) return null

  const fetchOrganizations = async (options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getOrganizations(
        apiKey,
        userId,
        {
          name: options.name,
          page: _.has(options, 'page') ? options.page : pageData.current,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao obter organizações')
      setLoading(false)
      return
    }

    const current = parseInt(res.headers['x-page'], 10)
    const totalItems = parseInt(res.headers['x-total'], 10)
    const perPage = parseInt(res.headers['x-per-page'], 10)
    const totalPages =  Math.ceil(totalItems / perPage)
    setPageData({ current: current, totalPages: totalPages, totalItems: totalItems, perPage: perPage })
    setOrganizations(res.data.organizations)
    setLoading(false)
  }

  const handleOpenModal = () => {
    setModalOpen(true)
    fetchOrganizations()
  }

  const handleCloseModal = () => {
    setOrganizationSelected(null)
    setModalOpen(false)
    onSelect(null)
  }

  const handleSearchOrganization = () => {
    fetchOrganizations({ page: 1, name: searchName })
  }

  const handleSelectOrganization = (_params, event) => {
    const organization = organizations[event.rowIndex]
    setOrganizationSelected(organization)
    onSelect(organization)
    setModalOpen(false)
  }

  const renderModalContent = () => {
    if (loading) return <CircularProgress />

    return (
      <React.Fragment>
        <div className={classes.containerFilterName}>
          <TextField label="Nome" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
          <Button variant="text" onClick={handleSearchOrganization}>Filtrar</Button>
        </div>

        <DataTable
          columns={columnsOrganization}
          data={organizations}
          options={{
            pagination: true,
            serverSide: true,
            rowsPerPage: pageData.perPage,
            count: pageData.totalItems,
            page: pageData.current - 1,
            sort: false,
            onChangePage: (number) => {
              fetchOrganizations({ page: number + 1, name: searchName })
            },
            onRowClick: handleSelectOrganization,
          }}
        />
      </React.Fragment>
    )
  }

  const renderOrganizationSelected = () => {
    if (_.isEmpty(organizationSelected)) return null

    return (
      <Typography>
        Organização:
        {' '}
        <strong>{organizationSelected.name}</strong>
      </Typography>
    )
  }

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleOpenModal}>
        Escolher organização
      </Button>

      {renderOrganizationSelected()}

      <ModalCustom onClose={handleCloseModal} open={modalOpen} title="Escolher organização">
        {renderModalContent()}
      </ModalCustom>
    </React.Fragment>
  )
}

ModalSelectOrganization.defaultProps = {
  onSelect: null,
}

ModalSelectOrganization.propTypes = {
  onSelect: PropTypes.func,
}

export default ModalSelectOrganization
