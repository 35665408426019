import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Icons } from 'ui/components'

import { isPermissionAdminOrGreater, isPermissionMaster } from 'utils/validators'

export default function ({ currentPath }) {
  const userPermission = useSelector(state => state.user.permission)

  const renderNavOrg = () => {
    if (!isPermissionAdminOrGreater(userPermission)) return null

    return (
      <li>
        <div className="category">
          <Icons icon="settings" />
          Organização
        </div>

        <ul>
          {/* BV: desativado pois nao tem essa funcionalidade
          <li>
            <Link
              className={(currentPath === '/admin/organization') ? 'selected' : ''}
              to="/admin/organization"
            >
              Minha Organização
            </Link>
          </li>
          */}

          <li>
            <Link
              className={(currentPath === '/admin/users') ? 'selected' : ''}
              to="/admin/users"
            >
              Usuários
            </Link>
          </li>

          {/* BV: desativado pois nao tem essas funcionalidades
          <li>
            <Link
              className={(currentPath === '/admin/organization-plan-cpf') ? 'selected' : ''}
              to="/admin/organization-plan-cpf"
            >
              Organização - Plano do CPF
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === '/admin/organization-plan-cnpj') ? 'selected' : ''}
              to="/admin/organization-plan-cnpj"
            >
              Organização - Plano do CNPJ
            </Link>
          </li>
          */}
        </ul>
      </li>
    )
  }

  const renderNavProfile = () => {
    return (
      <li>
        <div className="category">
          <Icons icon="settings" />
          Perfil
        </div>

        <ul>
          <li>
            <Link
              className={(currentPath === '/config/user') ? 'selected' : ''}
              to="/config/user"
            >
              Meu usuário
            </Link>
          </li>
        </ul>
      </li>
    )
  }

  const renderNavManage = () => { // eslint-disable-line no-unused-vars
    if (!isPermissionMaster(userPermission)) return null

    return (
      <li>
        <div className="category">
          <Icons icon="settings" />
          Gerencia
        </div>

        <ul>
          <li>
            <Link
              className={(currentPath === '/master/organizations') ? 'selected' : ''}
              to="/master/organizations"
            >
              Organizações
            </Link>
          </li>
        </ul>
      </li>
    )
  }

  return (
    <nav>
      <ul>
        {renderNavProfile()}
        {renderNavOrg()}
        {/* BV: desativado pois nao tem essas funcionalidades */}
        {/* {renderNavManage()} */}
      </ul>
    </nav>
  )
}
