import React from 'react'

import styles from './styles'

export default ({
  type,
  title,
  subtitle,
}) => {
  const classes = styles()

  return (
    <div>
      <div>
        <span className={classes.type}>{type}</span>
        <span className={classes.title}>{' / '}</span>
        <span className={classes.title}>{title}</span>
      </div>
      <div>
        <span className={classes.subtitle}>{subtitle}</span>
      </div>
    </div>
  )
}
