import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'
import analysisStatusConstants from 'utils/constants/analysisStatus'

export default function ({ location }) {
  const title = 'Aprovados - PEP'
  const subtitle = 'Aprovados que são PEP'
  const analysisStatus = analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved
  const color = '#19D315'
  const hasPep = true

  const arrayAnalysisStatus = [
    analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.reproved,
    analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.blacklist,
  ]

  return (
    <BaseConsultsList
      location={location}
      title={title}
      subtitle={subtitle}
      color={color}
      analysisStatus={analysisStatus}
      actionsAnalysisStatus={arrayAnalysisStatus}
      hasPep={hasPep}
    />
  )
}
