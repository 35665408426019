import styled from 'styled-components'

export const TitleBold = styled.h2`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  color: #333333;
`

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  min-width: 100%;
  margin-bottom: 5px;
  @media (max-width: 1170px) {
    width: auto;
  }
`

export const LineTitle = styled.div`
  width:160px;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.29;
  letter-spacing: normal;
  color: #666666;
`

export const LineContent = styled.div`
  width: 230px;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.29;
  letter-spacing: normal;
  color: #333333;
`
