import React from 'react'
import _ from 'lodash'

import CertificateRow from 'ui/components/ContainerCommonPanels/CertificateRow'
import ElectoralFinancingPanel from './ElectoralFinancingPanel'

// Called from CertificateRow as a CustomServiceRenderer
const electoralFinancingRenderer = (service, otherInfos, options) => {
  return (
    <ElectoralFinancingPanel service={service} otherInfos={otherInfos} options={options} />
  )
}

const selectCustomRenderer = (service) => {
  switch (service.service_id) {
  case 'electoral_financing_cpf':
  case 'electoral_financing_cnpj':
    return electoralFinancingRenderer
  default:
    return null
  }
}

const ExpansionPanelProtestsAccounts = ({ serviceInfos, serviceOptions }) => {
  const renderServices = () => {
    return _.map(serviceInfos, (service) => {
      const info = _.find(serviceOptions, { serviceId: service.service_id })
      const customRenderer = selectCustomRenderer(service)

      return (
        <CertificateRow
          key={service.service_id}
          title={service.name}
          service={service}
          otherInfos={info && _.get(service, 'data')}
          options={info && info.options}
          customCertificateRenderer={customRenderer}
        />
      )
    })
  }

  return (
    <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
      {renderServices()}
    </ul>
  )
}

export default ExpansionPanelProtestsAccounts
