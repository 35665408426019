import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import { getUser } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'
import { selectApiKey, selectUserId } from 'redux/auth/saga.selectors'

import * as actionsAuth from '../auth/actions'
import * as actions from './actions'

function* fetchUser() {
  yield takeEvery([actions.FETCH_USER, actionsAuth.SUCCESS_LOGIN], function* sg(_action) {
    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    let res = null
    try {
      res = yield call(getUser, apiKey, userId)
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter usuário'
      yield put({ type: actions.FETCH_USER_FAILED, payload: messageError })

      reportError(err, messageError)
      return
    }

    const { data } = res

    yield put({
      type: actions.FETCH_USER_SUCCEEDED,
      payload: data,
    })
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchUser),
  ])
}
