import styled from 'styled-components'

export default styled.div`
`

const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;

  .filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .tab-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }
`

export { ContainerModal }
