import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Badge as MUIBadge } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { Icons } from 'ui/components'
import { getList } from 'services/apiMotorBgc'
import { MANUAL_DERIVATION_STATUS } from 'utils/constants/manualDerivationStatus'
import analysisStatusConstants from 'utils/constants/analysisStatus'
import { USER_ROLE } from 'utils/constants/user'

const Badge = withStyles({
  anchorOriginTopRightRectangle: {
    backgroundColor: '#ccc',
    right: -30,
    transform: 'scale(1) translate(50%, -10%)',
  },
})(MUIBadge)

const BADGE_MAX = 999999999

export default function ({ currentPath }) {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const [totalGeral, setTotalGeral] = useState(null)
  const [totalInAnalysis, setTotalInAnalysis] = useState(null)
  const [totalAnalystInQueueOnlyPep, setTotalAnalystInQueueOnlyPep] = useState(null)
  const [totalAnalystInQueueOnlyProcesses, setTotalAnalystInQueueOnlyProcesses] = useState(null)
  const [totalAnalystInQueuePepAndProcesses, setTotalAnalystInQueuePepAndProcesses] = useState(null)
  const [totalSupervisorInQueueOnlyPep, setTotalSupervisorInQueueOnlyPep] = useState(null)
  const [totalSupervisorInQueueOnlyProcesses, setTotalSupervisorInQueueOnlyProcesses] = useState(null)
  const [totalSupervisorInQueuePepAndProcesses, setTotalSupervisorInQueuePepAndProcesses] = useState(null)
  const [totalFinalizedApproved, setTotalFinalizedApproved] = useState(null)
  const [totalFinalizedReproved, setTotalFinalizedReproved] = useState(null)

  const listDocs = async (type, options = {}) => {
    let res = null
    try {
      res = await getList(
        apiKey,
        userId,
        panelEnv,
        {
          analysisStatus: options.analysisStatus,
          hasManualDerivation: options.hasManualDerivation,
          hasPep: options.hasPep,
          hasProcesses: options.hasProcesses,
          manualDerivationStatus: options.manualDerivationStatus,
          manualDerivationToRole: options.manualDerivationToRole,
        },
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao listar CPFs'
      toast.error(messageError)
      return
    }

    switch (type) {
      case 'totalGeral': setTotalGeral(parseInt(res.headers['x-total'], 10)); break
      case 'inAnalysis': setTotalInAnalysis(parseInt(res.headers['x-total'], 10)); break
      case 'analystInQueueOnlyPep': setTotalAnalystInQueueOnlyPep(parseInt(res.headers['x-total'], 10)); break
      case 'analystInQueueOnlyProcesses': setTotalAnalystInQueueOnlyProcesses(parseInt(res.headers['x-total'], 10)); break
      case 'analystInQueuePepAndProcesses': setTotalAnalystInQueuePepAndProcesses(parseInt(res.headers['x-total'], 10)); break
      case 'supervisorInQueueOnlyPep': setTotalSupervisorInQueueOnlyPep(parseInt(res.headers['x-total'], 10)); break
      case 'supervisorInQueueOnlyProcesses': setTotalSupervisorInQueueOnlyProcesses(parseInt(res.headers['x-total'], 10)); break
      case 'supervisorInQueuePepAndProcesses': setTotalSupervisorInQueuePepAndProcesses(parseInt(res.headers['x-total'], 10)); break
      case 'finalizedApproved': setTotalFinalizedApproved(parseInt(res.headers['x-total'], 10)); break
      case 'finalizedReproved': setTotalFinalizedReproved(parseInt(res.headers['x-total'], 10)); break
      default:
        break
    }
  }

  const calculateTotals = () => {
    listDocs('totalGeral', {
      hasManualDerivation: true,
    })
    listDocs('inAnalysis', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_ANALYSIS,
    })
    listDocs('analystInQueueOnlyPep', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_QUEUE,
      manualDerivationToRole: USER_ROLE.ANALYST,
      hasPep: true,
      hasProcesses: false,
    })
    listDocs('analystInQueueOnlyProcesses', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_QUEUE,
      manualDerivationToRole: USER_ROLE.ANALYST,
      hasPep: false,
      hasProcesses: true,
    })
    listDocs('analystInQueuePepAndProcesses', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_QUEUE,
      manualDerivationToRole: USER_ROLE.ANALYST,
      hasPep: true,
      hasProcesses: true,
    })
    listDocs('supervisorInQueueOnlyPep', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_QUEUE,
      manualDerivationToRole: USER_ROLE.SUPERVISOR,
      hasPep: true,
      hasProcesses: false,
    })
    listDocs('supervisorInQueueOnlyProcesses', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_QUEUE,
      manualDerivationToRole: USER_ROLE.SUPERVISOR,
      hasPep: false,
      hasProcesses: true,
    })
    listDocs('supervisorInQueuePepAndProcesses', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_QUEUE,
      manualDerivationToRole: USER_ROLE.SUPERVISOR,
      hasPep: true,
      hasProcesses: true,
    })
    listDocs('finalizedApproved', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.FINALIZED,
      analysisStatus: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved,
    })
    listDocs('finalizedReproved', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.FINALIZED,
      analysisStatus: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved,
    })
  }

  useEffect(() => {
    setTimeout(calculateTotals, 3 * 1000)

    const intervalId = setInterval(calculateTotals, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderNavManualAnalysis = () => {
    return (
      <li>
        <div className="category">
          <Icons icon="fileText" />
          Analises Manuais
        </div>

        <ul>
          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/total`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/total`}
            >
              <Badge badgeContent={totalGeral} color="primary" max={BADGE_MAX}>
                Total
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/in-analysis`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/in-analysis`}
            >
              <Badge badgeContent={totalInAnalysis} color="primary" max={BADGE_MAX}>
                Em analise
              </Badge>
            </Link>
          </li>

          <li>
            <ul style={{ marginLeft: 15 }}>
              <li>Na fila para Analista:</li>

              <li>
                <Link
                  className={(currentPath === `/${panelEnv}/manual-analysis/analyst/in-queue-only-pep`) ? 'selected' : ''}
                  to={`/${panelEnv}/manual-analysis/analyst/in-queue-only-pep`}
                >
                  <Badge badgeContent={totalAnalystInQueueOnlyPep} color="primary" max={BADGE_MAX}>
                    Apenas PEP
                  </Badge>
                </Link>
              </li>

              <li>
                <Link
                  className={(currentPath === `/${panelEnv}/manual-analysis/analyst/in-queue-only-processes`) ? 'selected' : ''}
                  to={`/${panelEnv}/manual-analysis/analyst/in-queue-only-processes`}
                >
                  <Badge badgeContent={totalAnalystInQueueOnlyProcesses} color="primary" max={BADGE_MAX}>
                    Apenas Processos
                  </Badge>
                </Link>
              </li>

              <li>
                <Link
                  className={(currentPath === `/${panelEnv}/manual-analysis/analyst/in-queue-pep-processes`) ? 'selected' : ''}
                  to={`/${panelEnv}/manual-analysis/analyst/in-queue-pep-processes`}
                >
                  <Badge badgeContent={totalAnalystInQueuePepAndProcesses} color="primary" max={BADGE_MAX}>
                    Pep + Processos
                  </Badge>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <ul style={{ marginLeft: 15 }}>
              <li>Na fila para Coordenador:</li>

              <li>
                <Link
                  className={(currentPath === `/${panelEnv}/manual-analysis/supervisor/in-queue-only-pep`) ? 'selected' : ''}
                  to={`/${panelEnv}/manual-analysis/supervisor/in-queue-only-pep`}
                >
                  <Badge badgeContent={totalSupervisorInQueueOnlyPep} color="primary" max={BADGE_MAX}>
                    Apenas PEP
                  </Badge>
                </Link>
              </li>

              <li>
                <Link
                  className={(currentPath === `/${panelEnv}/manual-analysis/supervisor/in-queue-only-processes`) ? 'selected' : ''}
                  to={`/${panelEnv}/manual-analysis/supervisor/in-queue-only-processes`}
                >
                  <Badge badgeContent={totalSupervisorInQueueOnlyProcesses} color="primary" max={BADGE_MAX}>
                    Apenas Processos
                  </Badge>
                </Link>
              </li>

              <li>
                <Link
                  className={(currentPath === `/${panelEnv}/manual-analysis/supervisor/in-queue-pep-processes`) ? 'selected' : ''}
                  to={`/${panelEnv}/manual-analysis/supervisor/in-queue-pep-processes`}
                >
                  <Badge badgeContent={totalSupervisorInQueuePepAndProcesses} color="primary" max={BADGE_MAX}>
                    Pep + Processos
                  </Badge>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/finalized-approved`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/finalized-approved`}
            >
              <Badge badgeContent={totalFinalizedApproved} color="primary" max={BADGE_MAX}>
                Finalizadas - Aprovado
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/finalized-reproved`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/finalized-reproved`}
            >
              <Badge badgeContent={totalFinalizedReproved} color="primary" max={BADGE_MAX}>
                Finalizadas - Reprovado
              </Badge>
            </Link>
          </li>
        </ul>
      </li>
    )
  }

  return (
    <nav>
      <ul>
      {renderNavManualAnalysis()}
      </ul>
    </nav>
  )
}
