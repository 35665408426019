import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import { getListPlanServices } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'
import { PANEL_ENVS } from 'utils/constants/environments'
import { selectApiKey, selectUserId } from 'redux/auth/saga.selectors'

import * as actions from './actions'

function* fetchPlanServicesList() {
  yield takeEvery(actions.FETCH_PLAN_CPF_SERVICES_LIST, function* sg(_action) {
    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    let resPlanList = null
    try {
      resPlanList = yield call(getListPlanServices, apiKey, userId, PANEL_ENVS.CPF)
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter os serviços'
      yield put({ type: actions.FETCH_PLAN_CPF_SERVICES_LIST_FAILED, payload: messageError })

      reportError(err, messageError)
      return
    }

    const { services } = resPlanList.data

    yield put({
      type: actions.FETCH_PLAN_CPF_SERVICES_LIST_SUCCEEDED,
      payload: { services: services },
    })
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchPlanServicesList),
  ])
}
