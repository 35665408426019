import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'

import { Button } from 'ui/components'

import Container from './styles'

const Search = React.forwardRef(({ _action, onChangeText, onSearch, placeholder, text, mask, ...props }, ref) => {
  const [value, setValue] = useState(text || '')

  const handleClick = () => {
    if (onSearch) onSearch(value)
  }

  const handleChangeText = (e) => {
    const val = e.target.value
    setValue(val)
    if (onChangeText) onChangeText(val)
  }

  return (
    <Container ref={ref} {...props}>
      <MaskedInput
        placeholder={placeholder}
        value={value}
        mask={mask || false}
        onChange={handleChangeText}
      />
      <Button type="button" onClick={handleClick} variant="text">PESQUISAR</Button>
    </Container>
  )
})

export default Search
