import React from 'react'
import styled from 'styled-components'
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import styles from './styles'

const Modal = styled(Dialog)`
  .MuiDialog-paper {
    font-size: 1.2rem;
    min-width: 30rem;
    max-width: 100%;
  }
`

const ModalCustom = ({ title, contentBar, children, onClose, open }) => {
  const classes = styles()

  return (
    <Modal onClose={onClose} open={open}>
      <DialogTitle disableTypography className={classes.dialogTitleContainer}>
        <div className={classes.containerTitle}>
          <h6 className={classes.dialogTitle}>{title}</h6>
          {contentBar}
        </div>
        {onClose && (
          <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContentContainer} dividers={true}>
        {children}
      </DialogContent>
    </Modal>
  )
}

export default ModalCustom
