import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { ButtonRecaptcha } from 'ui/components'

import useHideZendeskWidget from 'hooks/useHideZendesk'
import { successLogin, failChangePassword } from 'redux/auth/actions'
import { changePassword } from 'services/apiAuth'
import { isPasswordValid } from 'utils/validators'
import {
  isNetworkError,
  isUserLogged,
} from 'utils/functions'

import styles from './styles'

const PasswordChange = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()

  const email = useSelector(state => state.auth.email)
  const changePasswordSession = useSelector(state => state.auth.changePasswordSession)
  const accessToken = useSelector(state => state.auth.access_token)

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')
  const [error, setError] = useState(false)

  useHideZendeskWidget()

  useEffect(() => {
    if (changePasswordSession === null) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isUserLogged(accessToken)) {
      history.push('/cpf/processing')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const handleSubmit = async (recaptchaToken) => {
    try {
      const data = {
        app_id: process.env.REACT_APP_COGNITO_APP_ID,
        user_pool: process.env.REACT_APP_COGNITO_USER_POOL,
        username_email: email,
        password: password,
        user_attributes: {
          name: name,
        },
        session: changePasswordSession,
        recaptcha_token: recaptchaToken,
      }

      const res = await changePassword(data)

      if (res) {
        const userdata = res.data
        dispatch(successLogin(userdata))
      }
    } catch (err) {
      dispatch(failChangePassword())

      setError(true)

      if (_.get(err, 'response.status') === 400) {
        toast.error('Sua sessão expirou. Faça o login novamente!')
        history.push('/')
        return
      }

      if (!isNetworkError(err)) {
        const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao mudar senha'
        toast.error(messageError)
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <img src="/assets/images/logos/logo.svg" alt="Logo" className={classes.logo} />
        <div className={classes.titleSidebarContainer}>
          <h1 className={classes.titleSidebar}>Redefinir Senha</h1>
          <p className={classes.subtitleSidebar}>Redefina sua senha para acessar a plataforma!</p>
        </div>
      </div>
      <div className={classes.main}>
        <h1 className={classes.title}>Redefinir Senha</h1>

        <TextField
          id="name"
          label="Nome"
          className={classes.textField}
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!(name.length !== 0 && name.length < 2)}
          type="text"
          required={true}
          autoCapitalize="off"
          autoCorrect="off"
        />

        <TextField
          id="password"
          label="Senha"
          className={classes.textField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          required={true}
          autoCapitalize="off"
          autoComplete="new-password"
          autoCorrect="off"
          error={password.length !== 0 ? !isPasswordValid(password) : false}
          helperText={
            password.length !== 0
              ? isPasswordValid(password)
                ? false
                : 'A senha precisa ter pelo menos 1 letra maiuscula, 1 minuscula, 1 número e um caracter especial, e no mínimo de 8 caracteres.'
              : false
          }
        />

        <TextField
          id="confirmPassword"
          label="Confirmar Senha"
          className={classes.textField}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          required={true}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          error={!!(confirmPassword.length !== 0 && confirmPassword !== password)}
          helperText={confirmPassword !== password ? 'As senhas precisam ser iguais.' : null}
        />
        {error ? (
          <p>
            <strong className={classes.error}>
              Erro ao redefinir a senha, verifique seus dados e tente novamente.
            </strong>
          </p>
        ) : null}

        <ButtonRecaptcha
          text="Confirmar"
          onClick={(token) => handleSubmit(token)}
        />

        <Typography>
          Entrar com outra conta.
          {' '}
          <Link to="/">
            Fazer Login
          </Link>
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(PasswordChange)
