const additionalCostGroupText = 'Alguns serviços deste grupo possuem custo adicional e não podem ser marcados automaticamente.'
const manualInputGroupText = 'Alguns serviços deste grupo necessitam de inputs adicionais e não podem ser marcados automaticamente. Em caso de dúvida, entre em contato com nosso suporte.'
const manualInputServiceText = 'Este serviço requer o preenchimento de inputs adicionais. Em caso de dúvida, entre em contato com nosso suporte.'
const govBrRequiredServicesText = 'Alguns serviços deste grupo necessitam de uma conta do gov.br configurada. Acesse a seção de configurações ou solicite o administrador de sua organização.'
const govBrRequiredServiceText = 'Este serviço requer uma conta do gov.br configurada. Acesse a seção de configurações ou solicite o administrador de sua organização.'
export {
  additionalCostGroupText,
  manualInputGroupText,
  manualInputServiceText,
  govBrRequiredServicesText,
  govBrRequiredServiceText,
}
