import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Avatar, Divider, Typography } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import { Button } from 'ui/components'

import { getOrganization, getUser, putUser } from 'services/apiMotorBgc'

import GovBrAccountForm from 'ui/components/GovBrAccountForm'
import { formatDate } from 'utils/masks'
import { translatePermission, translateRole } from 'utils/constants/user'
import { formatGovBrAccountInput } from 'utils/functions'
import reportError from 'utils/errorReporter'

export default function () {
  const formEditedInitialState = {
    cpf: false,
    password: false,
  }

  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)
  const userOrgId = useSelector((state) => state.user.org_id)

  const [user, setUser] = useState({})
  const [config, setConfig] = useState({})
  const [organization, setOrganization] = useState(null)
  const [hasFormError, setHasFormError] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [editedFormState, setEditedFormState] = useState(formEditedInitialState)

  useEffect(() => {
    if (_.every(editedFormState, (v) => v === false)) {
      setSaveDisabled(true)
      return
    }
    if (editedFormState.cpf || editedFormState.password) {
      setSaveDisabled(hasFormError)
      return
    }

    setSaveDisabled(false)
  }, [hasFormError, editedFormState])

  useEffect(() => {
    const loadFullUser = async () => {
      let res = null
      try {
        res = await getUser(
          apiKey,
          userId,
          {
            withConfig: true,
          },
        )
      } catch (err) {
        reportError(err, 'Erro ao carregar dados do usuário')
        return
      }
      setUser(res.data)
      setConfig(_.get(res, 'data.config'))
    }

    loadFullUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const getAndSetOrg = async (options = {}) => {
    let res = null

    try {
      res = await getOrganization(
        apiKey,
        userId,
        {
          orgId: options.orgId,
          withConfig: true,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao obter organização')
      return
    }

    setOrganization(res.data)
  }

  useEffect(() => {
    getAndSetOrg({ orgId: userOrgId })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateConfig = (newConfig) => {
    setConfig({ ...newConfig })
  }

  const handleSave = async () => {
    const parseEditedUserData = () => {
      const newConfig = {}
      if (editedFormState.cpf || editedFormState.password) newConfig.gov_br_account = formatGovBrAccountInput(config.gov_br_account)

      return {
        config: newConfig,
      }
    }

    try {
      await putUser(
        apiKey,
        userId,
        {
          userData: parseEditedUserData(),
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao salvar')
      return
    }

    toast.success('Salvo com sucesso!')
    setEditedFormState(formEditedInitialState)
  }

  const handleEditGovBrForm = (formName) => {
    setEditedFormState({ ...editedFormState, [formName]: true })
  }

  const renderUserEmail = (userEmail) => {
    return (
      <Typography>
        <span>Email:</span>
        {' '}
        <strong>{userEmail}</strong>
      </Typography>
    )
  }

  const renderUserPermission = (userPermission) => {
    return (
      <Typography>
        <span>Permissão:</span>
        {' '}
        <strong>{translatePermission(userPermission)}</strong>
      </Typography>
    )
  }

  const renderUserRole = (userRole) => {
    return (
      <Typography>
        <span>Role:</span>
        {' '}
        <strong>{translateRole(userRole)}</strong>
      </Typography>
    )
  }

  const renderUserCreatedAt = (createdAt) => {
    return (
      <Typography>
        <span>Criado em:</span>
        {' '}
        <strong>{formatDate(createdAt, { hideTime: true })}</strong>
      </Typography>
    )
  }

  const renderWarningUseOrgGovBrAccount = () => {
    if (_.get(organization, 'config.use_gov_br_account') !== true) return null

    return (
      <div
        style={{
          backgroundColor: '#eee',
          padding: 10,
          gap: 8,
          borderRadius: 8,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar>
          <Warning />
        </Avatar>

        <Typography>
          Um administrador configurou uma conta do GOV.BR para a sua
          organização, enquanto essa configuração estiver vigente, as
          credenciais inseridas aqui não serão usadas.
        </Typography>
      </div>
    )
  }

  const renderGovBrForm = () => { // eslint-disable-line no-unused-vars
    return (
      <React.Fragment>
        <GovBrAccountForm
          config={config}
          onUpdateConfig={handleUpdateConfig}
          onUpdateHasError={(hasError) => setHasFormError(hasError)}
          onEditForm={handleEditGovBrForm}
        />

        <br />

        {renderWarningUseOrgGovBrAccount()}

        <Button disabled={saveDisabled} onClick={handleSave}>
          Salvar
        </Button>
      </React.Fragment>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        maxWidth: '40vw',
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <div>
        {renderUserEmail(user.email)}
        {renderUserPermission(user.permission)}
        {renderUserRole(user.role)}
        {renderUserCreatedAt(user.created_at)}
      </div>

      <Divider />

      {/* BV: nao exibir
      {renderGovBrForm()}
      */}
    </div>
  )
}
