import styled from 'styled-components'

export const CardTitle = styled.h1`
  width: 98%;
  font-size: ${(props) => props.size === 'small' ? '14px' : '24px'};
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
`
