import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Field } from 'formik'
import { Link, Typography } from '@material-ui/core'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { TextInput, LinearProgressWithLabel } from 'ui/components'
import { processFile, validateFile } from './processFile'

const initialModalDataState = () => (
  _.cloneDeep({
    validDocs: [],
    invalidDocs: [],
    docsDuplicates: [],
    uniqueValidDocs: [],
    totalDocsProvided: 0,
  })
)

function InputDocNumbersByTextOrFileInput({ isInputByText, setIsInputByText, formikHelpers, setDocsModalData, onFileUploadProgressChange }) {
  const panelEnv = useSelector(state => state.auth.panelEnv)

  const [uploadedFileName, setUploadedFileName] = useState('')
  const [fileUploadProgress, setFileUploadProgress] = useState(0)

  const inputFileRef = useRef(null)

  const onProcessFileLine = () => undefined

  const onSelectFile = async () => {
    formikHelpers.setFieldValue('isInputByText', false)
    setIsInputByText(false)
    const file = document.getElementById('file-input').files[0]
    setFileUploadProgress(0)
    setUploadedFileName('')
    setDocsModalData(initialModalDataState())

    if (!file) return

    setUploadedFileName(file.name)

    try {
      await validateFile(file)
    } catch (error) {
      toast.error(error.message)
      return
    }

    processFile(file, panelEnv, onProcessFileLine)
      .then(data => {
        setFileUploadProgress(100)
        setDocsModalData(data)
        onFileUploadProgressChange(100)
      })
      .catch(error => {
        toast.error(error.message)
      })
  }

  const handleClickGoToManualTextInsert = () => {
    formikHelpers.setFieldValue('isInputByText', true)
    setIsInputByText(true)
    formikHelpers.setFieldTouched('docsTyped', false, false)
  }

  const onBtnClick = () => {
    setFileUploadProgress(0)
    setUploadedFileName('')
    setDocsModalData(initialModalDataState())
    /* Collecting node-element and performing click */
    inputFileRef.current.click()
  }

  const renderInputByText = () => {
    return (
      <React.Fragment>
        <Field
          name="docsTyped"
          label={`${panelEnv.toUpperCase()}s *`}
          component={TextInput}
          multiline
          rows="10"
          placeholder={`Insira ${panelEnv.toUpperCase()}s separados por nova linha`}
        />
        <Typography variant="caption">
          <Link href="#">
            <label style={{ cursor: 'pointer' }} onClick={onBtnClick}>
              Caso prefira, um arquivo CSV pode ser anexado clicando aqui
            </label>
          </Link>
        </Typography>
      </React.Fragment>
    )
  }

  const renderNotInputByText = () => {
    return (
      <React.Fragment>
        {uploadedFileName.length > 0 && (
          <Typography variant="caption">
            <b>Nome do arquivo:</b>
            <span style={{ marginLeft: 5 }}>{uploadedFileName}</span>
          </Typography>
        )}

        <LinearProgressWithLabel value={fileUploadProgress} />

        <Typography variant="caption">
          <p>
            <Link href="#">
              <label style={{ cursor: 'pointer' }} onClick={onBtnClick}>
                Alterar arquivo CSV
              </label>
            </Link>
          </p>
          <p>
            <Link style={{ cursor: 'pointer' }} href="#" onClick={handleClickGoToManualTextInsert}>
              Voltar para a inserção manual
            </Link>
          </p>
        </Typography>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {
        isInputByText
        ? renderInputByText()
        : renderNotInputByText()
      }

      <input
        type="file"
        data-testid="file-input"
        id="file-input"
        style={{ display: 'none' }}
        accept=".csv"
        onChange={onSelectFile}
        ref={inputFileRef}
      />
    </React.Fragment>
  )
}


InputDocNumbersByTextOrFileInput.defaultProps = {
  onFileUploadProgressChange: null,
}

InputDocNumbersByTextOrFileInput.propTypes = {
  isInputByText: PropTypes.bool.isRequired,
  setIsInputByText: PropTypes.func.isRequired,
  formikHelpers: PropTypes.objectOf(PropTypes.any).isRequired,
  setDocsModalData: PropTypes.func.isRequired,
  onFileUploadProgressChange: PropTypes.func,
}

export default InputDocNumbersByTextOrFileInput
