import _ from 'lodash'

import {
  FETCH_PLAN_CPF_SERVICES_LIST,
  FETCH_PLAN_CPF_SERVICES_LIST_FAILED,
  FETCH_PLAN_CPF_SERVICES_LIST_SUCCEEDED,
} from './actions'

const initialState = {
  fail: false,
  load: false,
  services: [],
}

export const planCpfServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAN_CPF_SERVICES_LIST: {
      return {
        ...state,
        fail: false,
        load: true,
      }
    }
    case FETCH_PLAN_CPF_SERVICES_LIST_FAILED: {
      return {
        ..._.cloneDeep(initialState),
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case FETCH_PLAN_CPF_SERVICES_LIST_SUCCEEDED: {
      return {
        fail: false,
        load: false,
        services: action.payload.services,
      }
    }

    default:
      return state
  }
}

export default planCpfServicesReducer
