import styled from 'styled-components'

export default styled.div`
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .MuiCircularProgress-colorPrimary {
      color: ${(props) => props.theme.color};
    }
  }

  form {
    width: 470px;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    div {
      margin-right: 5px;

      &:last-child {
        margin-right: 0px;
      }

    }
  }

  .form-buttons {
    margin-top: 1rem;
  }

  .first-line {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .requests-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiCircularProgress-root {
      width: initial !important;
      height: initial !important;

      svg {
        margin-right: 0px;
        width: 17px;
      }
    }
  }

  .counter {
    font-weight: bold;
    font-size: 17px;
  }

  .table {
    width: 100%;

    thead {
      tr {
        th {
          font-size: 0.9rem;
          border-top: none;
          border-bottom: 2px solid ${(props) => props.theme.palette.gray[300]};

          &.middle-col {
            white-space: break-spaces;
            min-width: 50px;
            max-width: 50px;
          }
        }

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    tbody {
      border-left: none;
      border-right: none;

      tr {
        border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};

        &:last-child {
          border-bottom: none;
        }
      }

      tr:hover {
        .sticky-col {
          background-color: #F5F5F5;
        }
      }
    }
  }

  .status {
    color: ${(props) => props.theme.color};
    display: flex;
    align-items: center;
  }

  .status-value {
    margin-right: 5px;
  }

  .button-size {
    width: 200px;
  }

  .button-close {
    margin-left: 30px;
  }

  .sticky-col {
    position: sticky;
    position: -webkit-sticky;
    background-color: white;
  }

  .wrapper {
    margin-top: 30px;
    border: 2px solid ${(props) => props.theme.palette.gray[300]};
    border-top: 3px solid ${(props) => props.theme.color};
    position: relative;
    overflow: auto;
    white-space: nowrap;

    ::-webkit-scrollbar-track {
      background-color: #F4F4F4;
    }
    ::-webkit-scrollbar {
      width: 6px;
      background: #F4F4F4;
      height: 10px;
    }

    ::-webkit-scrollbar::hover {
      height: 15px;
    }

    ::-webkit-scrollbar-thumb {
      background: #dad7d7;
    }
  }

  .date-filter {
    width: 100%;
  }

  .view {
    margin: auto;
    width: 100%;

    .loader-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }
  }

  .first-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 0px;
  }

  .last-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    right: 0px;
  }

  .handler {
    cursor: pointer;
  }

  .icon-button {
    cursor: pointer;
  }

  .search {
    button {
      font-size: 14px;
    }
  }

  .button-add-container {
    align-self: flex-start;
  }
`
