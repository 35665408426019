import { formatDate } from 'utils/masks'

const customCreatedAtRender = (value) => {
  return formatDate(value, { hideTime: true })
}

export const columnsOrganization = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'created_at',
    label: 'Criado em',
    options: {
      customBodyRender: customCreatedAtRender,
    },
  },
]
