import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CircularProgress, TextField, Typography } from '@material-ui/core'

import { Button } from 'ui/components'
import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import ModalCustom from 'ui/components/Modal'

import reportError from 'utils/errorReporter'
import { formatDate } from 'utils/masks'
import { getListBatch } from 'services/apiMotorBgc'

import { columnsBatch } from './configDataTable'
import styles from './styles'

const PER_PAGE_MAX = 10

const BatchSelectionModal = ({ onSelect }) => {
  const classes = styles()

  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)
  const panelEnv = useSelector((state) => state.auth.panelEnv)
  const userPermission = useSelector(state => state.user.permission)

  const [batchList, setBatchList] = useState([])
  const [selectedBatch, setSelectedBatch] = useState(null)

  const [searchDescription, setSearchDescription] = useState('')
  const [pageData, setPageData] = useState({ current: 1, totalItems: 0, totalPages: 0 })
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const listBatches = async (options = {}) => {
    setLoading(true)

    let res = null
    const page = options.page || 1
    try {
      res = await getListBatch(
        apiKey,
        userId,
        panelEnv,
        {
          description: options.description,
          page: page,
          perPage: PER_PAGE_MAX,
        },
      )
      const current = parseInt(res.headers['x-page'], 10)
      const totalItems = parseInt(res.headers['x-total'], 10)
      const totalPages =  Math.ceil(totalItems / parseInt(res.headers['x-per-page'], 10))
      setPageData({ current: current, totalPages: totalPages, totalItems: totalItems })
    } catch (err) {
      reportError(err, 'Erro ao listar lotes')
      setLoading(false)
      return
    }

    setBatchList(res.data)
    setLoading(false)
  }

  const handleOpenModal = () => {
    setModalOpen(true)
    listBatches()
  }

  const handleCloseModal = () => {
    setSelectedBatch(null)
    setModalOpen(false)
    onSelect(null)
  }

  const handleSearchBatch = () => {
    listBatches({ page: 1, description: searchDescription })
  }

  const handleSelectBatch = (_params, event) => {
    const batch = batchList[event.rowIndex]
    setSelectedBatch(batch)
    onSelect(batch)
    setModalOpen(false)
  }

  const renderModalContent = () => {
    if (loading) return <CircularProgress />

    return (
      <React.Fragment>
        <div className={classes.containerFilterDescription}>
          <TextField label="Descrição do Lote" value={searchDescription} onChange={(e) => setSearchDescription(e.target.value)} />
          <Button variant="text" onClick={handleSearchBatch}>Filtrar</Button>
        </div>

        <DataTable
          columns={columnsBatch(userPermission)}
          data={batchList}
          options={{
            pagination: true,
            serverSide: true,
            rowsPerPage: PER_PAGE_MAX,
            count: pageData.totalItems,
            page: pageData.current - 1,
            sort: false,
            onChangePage: (number) => {
              listBatches({ page: number + 1, description: searchDescription })
            },
            onRowClick: handleSelectBatch,
          }}
        />
      </React.Fragment>
    )
  }

  const renderBatchSelected = () => {
    if (_.isEmpty(selectedBatch)) return null

    return (
      <Typography>
        Selecionado:
        {' '}
        <strong>{`${selectedBatch.description} - ${formatDate(selectedBatch.created_at)}`}</strong>
      </Typography>
    )
  }

  return (
    <React.Fragment>
      <Button variant="text" onClick={handleOpenModal}>
        {_.isEmpty(selectedBatch) ? 'Escolher lote' : 'Escolher outro'}
      </Button>

      {renderBatchSelected()}

      <ModalCustom onClose={handleCloseModal} open={modalOpen} title="Escolha o lote">
        {renderModalContent()}
      </ModalCustom>
    </React.Fragment>
  )
}

BatchSelectionModal.defaultProps = {
  onSelect: null,
}

BatchSelectionModal.propTypes = {
  onSelect: PropTypes.func,
}

export default BatchSelectionModal
