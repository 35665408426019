import React from 'react'
import _ from 'lodash'
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from '@material-ui/core'

import { PANEL_ENVS } from 'utils/constants/environments'

// eslint-disable-next-line import/no-cycle
import MemberRow from '../MemberRow'
import MemberWithoutDocNumberRow from '../MemberWithoutDocNumberRow'

const MemberTable = ({ members, membersWithoutDocNumber, docData }) => {
  if (_.isEmpty(members)) return null

  const docTypeMember = (member) => _.isEmpty(member.cpf) ? PANEL_ENVS.CNPJ : PANEL_ENVS.CPF

  const selectDocMember = (member) => {
    if (docTypeMember(member) === PANEL_ENVS.CPF) {
      return _.find(docData.cpf_members, { cpf: member.cpf })
    }

    return _.find(docData.cnpj_members, { cnpj: member.cnpj })
  }

  return (
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <strong>Nome</strong>
          </TableCell>
          <TableCell>
            <strong>Tipo</strong>
          </TableCell>
          <TableCell>
            <strong>CPF/CNPJ</strong>
          </TableCell>
          <TableCell>
            <strong>Qualificação</strong>
          </TableCell>
          <TableCell>
            <strong>Data da Associação</strong>
          </TableCell>
          <TableCell colSpan={2}>
            <strong>Background Check</strong>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {_.map(members, (member) => (
          <MemberRow
            key={`${member.cpf || member.cnpj}`}
            docData={docData}
            docMember={selectDocMember(member)}
            docType={docTypeMember(member)}
            member={member}
          />
        ))}

        {_.map(membersWithoutDocNumber, (member) => (
          <MemberWithoutDocNumberRow
            key={`${member.cpf || member.cnpj}`}
            docMember={selectDocMember(member)}
            docType={docTypeMember(member)}
            member={member}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default MemberTable
