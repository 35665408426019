import React from 'react'
import classnames from 'classnames'

import { Search } from 'ui/components'

import { cpfMask, cnpjMask } from 'utils/masks'
import { extractOnlyNumbers } from 'utils/functions'

const SearchDoc = ({ text, type, onChangeText, onSearch, placeholder, className }, ref) => {
  return (
    <Search
      ref={ref}
      className={classnames('search', className)}
      placeholder={placeholder || 'Pesquisar'}
      mask={type === 'cpf' ? cpfMask : cnpjMask}
      onChangeText={onChangeText && ((val) => onChangeText(extractOnlyNumbers(val)))}
      onSearch={onSearch && ((val) => onSearch(extractOnlyNumbers(val)))}
      text={text}
    />
  )
}

export default React.forwardRef(SearchDoc)
