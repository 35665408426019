export default {
  required: 'Campo obrigatório',
  invalidCpf: 'CPF inválido',
  invalidCnpj: 'CNPJ inválido',
  invalidEmail: 'Email inválido',
  invalidPassword: 'Senha inválida',
  passwordHint:
    'A senha precisa ter pelo menos 1 letra maiuscula, 1 minúscula, 1 número e um caracter especial, no mínimo 10 caracteres e caracteres iguais não podem se repetir por mais de 3 vezes',
  passwordNotMatch: 'As senhas devem ser iguais',
}
