import React from 'react'
import _ from 'lodash'
import { useTheme } from '@material-ui/styles'

import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import RemoveIcon from '@material-ui/icons/Remove'
import ScheduleIcon from '@material-ui/icons/Schedule'
import WarningIcon from '@material-ui/icons/Warning'

import analysisStatusConstants from 'utils/constants/analysisStatus'
import serviceStatusConstants from 'utils/constants/serviceStatus'

const textTranslations = {
  PROCESSING: 'Processando',
  APPROVED: 'Aprovado',
  REPROVED: 'Reprovado',
  IN_ATTENTION: 'Em atenção',
}

export const ConsultStatusIcon = ({ consult, showText = false }) => {
  const theme = useTheme()

  if (_.isEmpty(consult)) {
    return <RemoveIcon style={{ color: '#ddd' }} />
  }

  const renderIcon = (icon, text) => {
    if (!showText) return icon

    return (
      <span style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
        {icon}
        {textTranslations[text]}
      </span>
    )
  }

  const renderStatusIcon = () => {
    const { status } = consult

    switch (status) {
      case serviceStatusConstants.SERVICE_STATUS.processing: {
        return renderIcon(<ScheduleIcon style={{ color: '#aaa' }} />, status)
      }
      case serviceStatusConstants.SERVICE_STATUS.error: {
        return renderIcon(<WarningIcon style={{ color: "#444444" }} />, status)
      }
      default:
        return null
    }
  }

  const renderAnalysisStatusIcon = () => {
    const analysisStatus = consult.analysis_status

    switch (analysisStatus) {
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved:
        return renderIcon(
          <CheckCircleIcon style={{ color: theme.palette.primary.green }} />,
          analysisStatus,
        )
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention:
        return renderIcon(
          <ErrorIcon style={{ color: theme.palette.primary.inAttention }} />,
          analysisStatus,
        )
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved:
        return renderIcon(
          <CancelIcon style={{ color: theme.palette.primary.red }} />,
          analysisStatus,
        )
      default:
        return null
    }
  }

  const statusIcon = renderStatusIcon()
  if (!_.isEmpty(statusIcon)) return statusIcon

  const analysisStatusIcon = renderAnalysisStatusIcon()
  if (!_.isEmpty(analysisStatusIcon)) return analysisStatusIcon

  return null
}
