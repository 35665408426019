import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import createAnalysis from './analysisCreator'

const AnalysisButton = ({ analysisParams }) => {
  const theme = useTheme()

  const [buttonState, setButtonState] = useState(true)

  if (buttonState) {
    return (
      <Button
        style={{ color: theme.palette.primary.main, fontWeight: '550', width: 150 }}
        onClick={() => {
          createAnalysis(analysisParams)
          setButtonState(false)
        }}
      >
        {`Analisar ${analysisParams.docType}`}
      </Button>
    )
  }

  return (
    <p style={{ color: theme.palette.primary.green, fontWeight: '550',  width: 150 }}>
      Análise solicitada
    </p>
  )
}

export default AnalysisButton
