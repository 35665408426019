import React from 'react'
import BaseBatchConsultList  from 'ui/views/batch/BaseConsultList'
import { BATCH_STATUS } from 'utils/constants/batchStatus'

export default function () {
  const title = 'Processados'
  const subtitle = 'Lotes processados'
  const status = BATCH_STATUS.processed

  return (
    <BaseBatchConsultList
      title={title}
      subtitle={subtitle}
      status={status}
    />
  )
}
