import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import {
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'
import { DeleteForever as DeleteForeverIcon, SaveAltOutlined } from '@material-ui/icons'
import { Button, Checkbox, DialogConfirm } from 'ui/components'
import ModalCustom from 'ui/components/Modal'
import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import { deleteProcessesClassifierPreset, getProcessesClassifierPresets } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'

import { buildExampleFile, buildTypeResultsFromFileText } from '../SaveLoadProcessesClassifierPreset/spreadSheet'
import { columnsPresets } from './configDataTable'

import styles from './styles'

const serviceId = 'processes_classifier'

const ListPresetsDialog = ({ isOpen, onLoadPreset, onClose }) => {
  const classes = styles()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const email = useSelector(state => state.auth.email)

  const [loading, setLoading] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [presetsList, setPresetsList] = useState([])
  const [withOrganizationPresets, setWithOrganizationPresets] = useState(true)
  const [pageData, setPageData] = useState({ current: 1, totalItems: 0, totalPages: 0, perPage: 0 })

  const [dialogDeletePresetOpen, setDialogDeletePresetOpen] = useState(false)
  const [presetToDelete, setPresetToDelete] = useState(null)

  const inputFile = useRef(null)

  const deletePreset = async (options = {}) => {
    try {
      await deleteProcessesClassifierPreset(
        apiKey,
        userId,
        {
          presetId: options.presetId,
        },
      )
    } catch (err) {
      reportError(err, 'Ocorreu um erro ao remover o preset')
    }
  }

  const fetchListPresets = async (options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getProcessesClassifierPresets(
        apiKey,
        userId,
        {
          name: _.has(options, 'name') ? options.name : searchName,
          withOrganizationPresets: _.has(options, 'withOrganizationPresets') ? options.withOrganizationPresets : withOrganizationPresets,
          page: _.has(options, 'page') ? options.page : pageData.current,
        },
      )
    } catch (err) {
      reportError(err, 'Ocorreu um erro ao listar os presets')
      setLoading(false)
      return
    }

    const current = parseInt(res.headers['x-page'], 10)
    const totalItems = parseInt(res.headers['x-total'], 10)
    const perPage = parseInt(res.headers['x-per-page'], 10)
    const totalPages =  Math.ceil(totalItems / perPage)
    setPageData({ current: current, totalPages: totalPages, totalItems: totalItems, perPage: perPage })
    setPresetsList(res.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchListPresets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearchName = () => {
    fetchListPresets({ name: searchName, page: 1 })
  }

  const handleCheckboxWithOrg = (value) => {
    setWithOrganizationPresets(value)
    fetchListPresets({ withOrganizationPresets: value, page: 1 })
  }

  const handleApplyPreset = (preset) => {
    onLoadPreset(serviceId, preset.type_results)
    toast.info('Preset carregado. Lembre-se de salvar o plano ao final.')
    onClose()
  }

  const handleOpenDialogDeletePreset = (event, preset) => {
    event.stopPropagation()

    setPresetToDelete(preset)
    setDialogDeletePresetOpen(true)
  }

  const handleDeletePresetCancel  = async (event) => {
    event.stopPropagation()
    setDialogDeletePresetOpen(false)
    setPresetToDelete(null)
  }

  const handleDeletePresetConfirm  = async (event, preset) => {
    event.stopPropagation()

    await deletePreset({ presetId: preset.preset_id })
    fetchListPresets()

    setDialogDeletePresetOpen(false)
    setPresetToDelete(null)
  }

  const handleCsvUpload = async (e) => {
    const { files } = e.target

    try {
      if (files && files.length) {
        const typeResults = buildTypeResultsFromFileText(await files[0].text())
        onLoadPreset(serviceId, typeResults)
        toast.info('Preset importado. Lembre-se de salvar o plano ao final.')
        onClose()
      }
    } catch (err) {
      reportError(err, 'Ocorreu um erro ao carregar o arquivo, verifique se ele está no formato correspondente.')
    }
  }

  const openCsvFileSelector = () => {
    inputFile.current.click()
  }

  const renderFilterPresetName = () => {
    return (
      <div className={classes.buttonFilterName}>
        <TextField label="Nome" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
        <Button variant="text" onClick={handleSearchName}>Filtrar</Button>
      </div>
    )
  }

  const renderButtonImportPreset = () => {
    return (
      <Button
        variant="text"
        size="small"
        startIcon={<SaveAltOutlined />}
        onClick={openCsvFileSelector}
      >
        <span>Importar preset de um arquivo CSV</span>
        <input
          type="file"
          id="file"
          accept=".csv"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={handleCsvUpload}
        />
      </Button>
    )
  }

  const renderDownloadPresetExample = () => {
    return (
      <div style={{ fontSize: '1.2rem' }}>
        <Link href={buildExampleFile()} download="exemplo_preset.csv">
          Baixar CSV de exemplo
        </Link>
      </div>
    )
  }

  const renderCheckboxWithOrg = () => (
    <div style={{ marginTop: 20 }}>
      <Checkbox
        value={withOrganizationPresets}
        label="Mostrar presets compartilhados por outras pessoas da organização"
        onClick={handleCheckboxWithOrg}
      />
    </div>
  )

  const renderButtonDeletePreset = (preset) => {
    if (preset.user_email !== email) return null

    return (
      <Button
        variant="outlined"
        size="small"
        onClick={(e) => handleOpenDialogDeletePreset(e, preset)}
      >
        <DeleteForeverIcon fontSize="small" style={{ color: "#FF0000" }} />
      </Button>
    )
  }

  const presetListWithAction = () => {
    return _.map(presetsList, (preset) => {
      const newPreset = _.cloneDeep(preset)
      newPreset.action = (
        <div>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleApplyPreset(preset)}
          >
            Aplicar
          </Button>

          {renderButtonDeletePreset(preset)}
        </div>
      )

      return newPreset
    })
  }

  const renderListPresets = () => {
    if (loading) return <CircularProgress />

    return (
      <DataTable
        columns={columnsPresets}
        data={presetListWithAction()}
        options={{
          pagination: true,
          serverSide: true,
          rowsPerPage: pageData.perPage,
          count: pageData.totalItems,
          page: pageData.current - 1,
          sort: false,
          onChangePage: (number) => {
            fetchListPresets({ page: number + 1 })
          },
        }}
      />
    )
  }

  const renderDialogConfirmDeletePreset = () => {
    if (_.isEmpty(presetToDelete)) return null

    return (
      <DialogConfirm
        open={dialogDeletePresetOpen}
        onConfirm={(e) => handleDeletePresetConfirm(e, presetToDelete)}
        onCancel={(e) => handleDeletePresetCancel(e)}
        title="Remover"
        content={`Tem certeza que deseja remover o preset: "${presetToDelete.name}"?`}
      />
    )
  }

  return (
    <ModalCustom onClose={onClose} open={isOpen} title="Carregar Preset">
      <Typography>
        Aplique ao fomulário um preset salvo. Lembre-se de salvar o plano após aplicar um preset.
      </Typography>

      <div className={classes.containerExportCsv}>
        {renderButtonImportPreset()}
        {renderDownloadPresetExample()}
      </div>

      <div className={classes.containerFilters}>
        {renderFilterPresetName()}
        {renderCheckboxWithOrg()}
      </div>

      {renderListPresets()}

      {renderDialogConfirmDeletePreset()}
    </ModalCustom>
  )
}

export default ListPresetsDialog
