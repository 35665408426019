import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sidebar: {
    backgroundColor: theme.palette.primary.main,
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 40,
    color: theme.palette.primary.lighter,

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      alignItems: 'center',
      padding: '20px 0',
    },
  },
  titleSidebarContainer: {
    marginBottom: 50,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 'initial',
    },
  },
  titleSidebar: {
    fontSize: 50,

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  subtitleSidebar: {
    fontSize: 18,
  },
  logo: {
    width: 300,

    [theme.breakpoints.down('xs')]: {
      width: 230,
    },
  },
  main: {
    height: '100%',
    width: '60%',
    padding: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      padding: 10,
    },
  },
  title: {
    fontSize: 30,
    color: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: 400,
    marginBottom: 30,

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
    },
  },
  error: {
    color: 'red',
    fontSize: 16,
  },
}))

export default styles
