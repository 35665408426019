import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Icons } from 'ui/components'

export default function ({ currentPath }) {
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const renderNavAnalytics = () => {
    return (
      <li>
        <div className="category">
          <Icons icon="calendar" />
          Analytics
        </div>

        <ul>
          <li>
            <Link
              className={(currentPath === `/${panelEnv}/analytics-consults`) ? 'selected' : ''}
              to={`/${panelEnv}/analytics-consults`}
            >
              Por Consultas
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/analytics-services`) ? 'selected' : ''}
              to={`/${panelEnv}/analytics-services`}
            >
              Por Serviços
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/analytics-time-consults`) ? 'selected' : ''}
              to={`/${panelEnv}/analytics-time-consults`}
            >
              Por Tempo - Consultas
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/analytics-time-services`) ? 'selected' : ''}
              to={`/${panelEnv}/analytics-time-services`}
            >
              Por Tempo - Serviços
            </Link>
          </li>
        </ul>
      </li>
    )
  }

  return (
    <nav>
      <ul>
        {renderNavAnalytics()}
      </ul>
    </nav>
  )
}
