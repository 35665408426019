import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik, Form, Field, setNestedObjectValues } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Button, Checkbox, TextInput } from 'ui/components'
import messages from 'utils/messages'

import BatchConsultModal from './BatchConsultModal'
import InputDocNumbersByTextOrFileInput from './InputDocNumbersByTextOrFileInput'
import { processText } from './processText'

const initialValues = {
  description: '',
  docsTyped: '',
  isInputByText: true,
}
const validationSchema = Yup.object({
  isInputByText: Yup.boolean(),
  description: Yup.string()
    .min(3, 'Descrição muito curta. Digite pelo menos 3 caracteres')
    .max(200, 'Descrição muito longa. Digite até 200 caracteres')
    .required(messages.required),
  docsTyped: Yup.string()
    .when(['isInputByText'], {
      is: true,
      then: Yup.string().required(messages.required),
    }),
})

const initialModalDataState = () => (
  _.cloneDeep({
    validDocs: [],
    invalidDocs: [],
    docsDuplicates: [],
    uniqueValidDocs: [],
    totalDocsProvided: 0,
  })
)

const ButtonBatchConsult = ({ btnMainText, btnConfirmText, onConfirm, onCancel, showBlacklistOverwite = false }) => {
  const [formShow, setFormShow] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [docsModalData, setDocsModalData] = useState(initialModalDataState())
  const [fileUploadProgress, setFileUploadProgress] = useState(0)
  const [isInputByText, setIsInputByText] = useState(true)
  const [overwrite, setOverwrite] = useState(false)
  const docType = useSelector(state => state.auth.panelEnv)

  const handleCheckBlacklistOverwrite = (checked) => {
    setOverwrite(checked)
  }

  const handleFileUploadProgressChange = (newProgressState) => {
    setFileUploadProgress(newProgressState)
  }

  const handleBatchModalCancel = () => {
    setModalOpen(false)
  }

  const handleBatchModalConfirm = (formikHelpers) => {
    formikHelpers.submitForm()
  }

  const handleCancel = () => {
    setFormShow(false)
    // setFileUploadProgress(0)
    // setUploadedFileSize(0)
    setIsInputByText(true)
    setDocsModalData(initialModalDataState())
    setModalOpen(false)
    if (onCancel) onCancel()
  }

  const handleSubmit = async (values) => {
    setFormShow(false)
    // setFileUploadProgress(0)
    // setUploadedFileSize(0)
    setDocsModalData(initialModalDataState())
    setModalOpen(false)

    const data = {
      docNumbers: docsModalData.uniqueValidDocs,
      description: values.description,
    }
    if (showBlacklistOverwite) data.overwrite = overwrite

    if (onConfirm) onConfirm(data)
  }

  const handleClickConfirmConsultButton = async (formikHelpers) => {
    const validationErrors = await formikHelpers.validateForm()
    // Manually setting the touched value in all the fields.
    // See https://github.com/formium/formik/issues/2734
    if (_.keys(validationErrors).length > 0) {
      formikHelpers.setTouched(setNestedObjectValues(validationErrors, true))
      return
    }

    if (!formikHelpers.values.isInputByText && fileUploadProgress === 100) {
      setModalOpen(true)
      return
    }

    const rawText = formikHelpers.values.docsTyped
    const rawTextSplited = rawText.split('\n')
    const docsResult = processText(rawTextSplited, docType)

    if (docsResult.totalDocsProvided === 0) {
      formikHelpers.setFieldTouched('docsTyped')
      return
    }

    setDocsModalData(docsResult)
    setModalOpen(true)
  }

  const renderCheckboxBlacklistOverwrite = () => {
    return (
      <div style={{ marginBottom: 8, marginTop: 12 }}>
        <Checkbox
          value={overwrite}
          label="Sobrescrever valores atuais com os do lote"
          onClick={handleCheckBlacklistOverwrite}
        />
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div style={{ marginTop: 15 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={isInputByText}
          onSubmit={handleSubmit}
        >
          {(formikHelpers) => (
            <React.Fragment>
              <Form>
                <Field
                  name="description"
                  label="Descrição do lote *"
                  maxLength={200}
                  component={TextInput}
                  data-testid="batch-description-input"
                  autoFocus
                />

                <InputDocNumbersByTextOrFileInput
                  formikHelpers={formikHelpers}
                  setDocsModalData={setDocsModalData}
                  isInputByText={isInputByText}
                  setIsInputByText={setIsInputByText}
                  onFileUploadProgressChange={handleFileUploadProgressChange}
                />

                {showBlacklistOverwite && renderCheckboxBlacklistOverwrite()}

                <div className="buttons-container">
                  <Button
                    className="button-form"
                    onClick={handleCancel}
                    variant="text"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={(_e) => handleClickConfirmConsultButton(formikHelpers)}
                    className="primary button-form button-form-consult"
                  >
                    {`${btnConfirmText}...`}
                  </Button>
                </div>
              </Form>

              <BatchConsultModal
                modalOpen={modalOpen}
                docsModalData={docsModalData}
                onCancel={handleBatchModalCancel}
                onConfirm={() => handleBatchModalConfirm(formikHelpers)}
              />
            </React.Fragment>
          )}
        </Formik>
      </div>
    )
  }

  if (!formShow) {
    return (
      <Button
        className="primary button-request"
        onClick={() => setFormShow(true)}
      >
        {btnMainText}
      </Button>
    )
  }

  return renderForm()
}

ButtonBatchConsult.defaultProps = {
  onConfirm: null,
  onCancel: null,
}

ButtonBatchConsult.propTypes = {
  btnMainText: PropTypes.string.isRequired,
  btnConfirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default ButtonBatchConsult
