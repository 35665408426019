import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'

export default function ({ location }) {
  const title = 'Inexistentes'
  const subtitle = 'Consultas com número de CPF que não existem'
  const analysisStatus = null
  const color = '#E91010'

  const inexistent = true

  const arrayAnalysisStatus = []

  return (
    <BaseConsultsList
      location={location}
      title={title}
      subtitle={subtitle}
      color={color}
      analysisStatus={analysisStatus}
      actionsAnalysisStatus={arrayAnalysisStatus}
      inexistent={inexistent}
    />
  )
}
