import { makeStyles } from '@material-ui/styles'
import styled from 'styled-components'

const styles = makeStyles(_theme => ({
  readOnlyMsgContainer: {
    marginBottom: 10,
  },
  checkboxShowServiceIdLabelContainer: {
    textAlign: 'right',
    marginBottom: 5,
  },
  labelCheckboxes: {
    marginBottom: 15,
    fontSize: 15,
  },
  buttonSave: {
    width: 300,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  planIdContainer: {
    padding: '1rem',
    backgroundColor: '#fbffbd',
    borderRadius: 5,
    position: 'absolute',
  },
  buttonContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    '& span': {
      marginRight: 5,
    },
  },
  tableContainer: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 40,

    '& thead': {
      '& tr': {
        '& th': {
          borderTop: 'none',
          padding: '10px 35px',
        },
        '&:first-child': {
          borderLeft: 'none',
        },
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    '& tbody': {
      borderLeft: 'none',
      borderRight: 'none',

      '& tr': {
        borderBottom: 'none',

        '&:last-child': {
          borderBottom: 'none',
        },
        '& td': {
          padding: '1.5rem',
          fontSize: '1.3rem',
        },
      },
    },
  },
}))

export default styles

// TODO: remover no futuro
export const Container = styled.div`
  .service-id-label-container {
    font-size: 10px;
    color: #aaa;
    margin-top: 5px;
  }

  .no-center {
    text-align: left;
  }

  .input-select-process-pole {
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .head-with-icon {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }

  .container-chip-input {
    width: 350px;
    margin-left: 2rem;
  }
`
