import { objToQueryString } from 'utils/functions'
import axios from './axiosCustom'
import { HOST, headers } from './apiMotorBgc'

export async function putManualAnalysisSetUser(apiKey, userId, analysisId) {
  const data = {
    user_id: userId,
    analysis_id: analysisId,
  }

  const res = await axios({
    method: 'PUT',
    url: `${HOST}/cpf/manual_derivation/analysis/user`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function deleteManualAnalysisRemoveUser(apiKey, userId, analysisId) {
  const data = {
    user_id: userId,
    analysis_id: analysisId,
  }

  const res = await axios({
    method: 'DELETE',
    url: `${HOST}/cpf/manual_derivation/analysis/user`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function getManualAnalysis(apiKey, userId, analysisId) {
  const queryString = objToQueryString({
    user_id: userId,
    analysis_id: analysisId,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/cpf/manual_derivation/analysis?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function postManualAnalysisCreateAnnotation(apiKey, userId, analysisId, analysisStatus, description) {
  const data = {
    user_id: userId,
    analysis_id: analysisId,
    analysis_status: analysisStatus,
    description: description,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/cpf/manual_derivation/analysis/annotation`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}
