import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(_theme => ({
  textField: {
    paddingTop: 8,
    '& .MuiFormLabel-root': {
      fontSize: '1.55rem',
    },
  },
}))

export default styles
