import React, { useState } from 'react'
import styled from 'styled-components'
import { Tabs, Tab } from '@material-ui/core'

const CustomTabs = styled(Tabs)`
    .MuiTab-wrapper {
        font-size: 1.4rem;
    }

    .Mui-selected {
        font-weight: 600;
    }

    .indicator {
        background-color: ${(props) => props.theme.palette.primary};
    }
`

export default function({ tabs, onChange }) {
    const [activeTab, setActiveTab] = useState(0)

    const handleTabChange = (_, index) => {
        setActiveTab(index)
        onChange(index)
    }

    return (
        <CustomTabs
            value={activeTab}
            onChange={handleTabChange}
            classes={{ indicator: 'indicator' }}
        >
            {tabs &&
                tabs.length > 0 &&
                tabs.map((tab, index) => <Tab key={index} label={tab.label} />)}
        </CustomTabs>
    )
}
