import styled from 'styled-components'

export const PopoverContainer = styled.div`
    .table {
        width: 350px;

        .icon-container {
            display: flex;
            justify-content: center;
        }

        .background-icon {
            svg {
                margin-right: 0px;
            }

            height: 40px;
            width: 40px;
            background-color: #eeeeee;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }

        tr {
            cursor: pointer;
        }

        .disabled {
            pointer-events: none;
        }

    }
`

export default styled.div`
`