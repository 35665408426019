import styled from 'styled-components'

export default styled.table`
  border-collapse: collapse;
  background-color: white;

  thead {
    tr {
      th {
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0.4rem;
        border-top: 1px solid ${(props) => props.theme.palette.gray[300]};
        border-bottom: 2px solid ${(props) => props.theme.palette.gray[300]};
      }

      &:first-child {
        border-left: 2px solid ${(props) => props.theme.palette.gray[300]};
      }

      &:last-child {
        border-right: 2px solid ${(props) => props.theme.palette.gray[300]};
      }
    }
  }

  tbody {
    border-left: 2px solid ${(props) => props.theme.palette.gray[200]};
    border-right: 2px solid ${(props) => props.theme.palette.gray[200]};

    tr {
      border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};
      background-color: white;

      &:last-child {
        border-bottom: 2px solid ${(props) => props.theme.palette.gray[300]};
      }

      &:hover {
        background-color: #F5F5F5;
      }

      td {
        padding: 0.4rem 0.1rem;
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }

  .primary {
    color: ${(props) => props.theme.palette.primary};
    display: inline-block;
    cursor: pointer;
  }
`
