import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .button-request {
    width: 250px;
    margin-top: 30px;
  }
`
