import React from 'react'
import _ from 'lodash'
import { formatToBRL } from 'brazilian-values'
import { Divider } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { objToString } from 'utils/functions'

import ProcessAccordion from '../ProcessAccordion'
import { renderCheckService } from '../utilsFunctions'

const CreditRestrictions = ({ service }) => {
  const theme = useTheme()

  /** Lista de possíveis campos a serem exibidos nas restrições, tanto para CPF quanto para CNPJ. */
  const creditRestrictionFields = {
    'last_restriction': 'Última Restrição',
    'value': 'Valor',
    'company': 'Empresa',
    'cnpj': 'CNPJ',
    'contract_number': 'Número do Contrato',
    'date_occured': 'Data do Ocorrido',
    'date_included': 'Data da Inclusão',
    'process_author': 'Autor do Processo',
    'amount': 'Quantia',
    'city': 'Cidade',
    'state': 'UF',
    'process_number': 'Numero do Processo',
    'district': 'Distrito',
    'forum': 'Fórum',
    'law_court': 'Corte',
    'name': 'Nome',
    'date': 'Data',
    'process_type': 'Tipo do Processo',
    'justice_type': 'Tipo de Justiça',
    'entity_type': 'Entidade',
    'company_cnpj': 'CNPJ',
    'company_name': 'Nome',
    'nature': 'Tipo da natureza',
    'date_occurred': 'Data da Ocorrência',
    'participant_type': 'Tipo de participação',
    'note_status': 'Status',
    'address': 'Endereço',
  }

  /** Lista de possíveis campos a serem exibidos nos protestos, tanto para CPF quanto para CNPJ. */
  const creditProtestFields = {
    'city': 'Cidade',
    'registry_name': 'Nome do Registro',
    'address': 'Endereço',
    'phone': 'Telefone',
    'protests_found': 'Número de Protestos',
    'protested_value': 'Valor Protestado',
  }

  const elemCheck = renderCheckService(service, theme)
  if (elemCheck !== null) return elemCheck

  if (!_.get(service, 'data.consists')) {
    return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
  }

  const restrictions = service.data.restrictions
  const protests = service.data.protests

  const renderListItem = (reactKey, title, value) => (
    <li key={reactKey}>
      <span style={{ fontWeight: 'bold' }}>{`${title}: `}</span>
      <span>{value}</span>
    </li>
  )

  const renderRestrictionsAccordion = () => {
    const renderRestrictionsDataBackwardCompatible = () => {
      if (_.isEmpty(restrictions)) return null

      return (
        <ProcessAccordion title={`Ocorrências (${restrictions.length})`}>
          <div style={{ fontSize: '1.3rem' }}>
            {_.map(restrictions, (occurrence, index) => (
              <React.Fragment key={index + occurrence}>
                <ul style={{ listStyle: 'none' }}>
                  {
                    Object.entries(occurrence).map(([k, v], i) => {
                      let fieldValue = v
                      if (k === 'amount')
                        fieldValue = formatToBRL(v)

                      return renderListItem(`li${i}${k}`, creditRestrictionFields[k], fieldValue)
                    })
                  }
                </ul>

                <Divider style={{ marginTop: 5 }} />
              </React.Fragment>
            ))}
          </div>
        </ProcessAccordion>
      )
    }

    const renderRestrictionsData = () => {
      if (_.isEmpty(restrictions)) return null

      return (
        <ProcessAccordion title="Restrições">
          <div style={{ marginTop: 10, fontSize: '1.3rem' }}>
            <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }} key={restrictions}>
              {restrictions.value &&
                renderListItem(restrictions.value, 'Valor Total', formatToBRL(restrictions.value))}
              {restrictions.last_restriction &&
                renderListItem(restrictions.last_restriction, 'Data da Última Restrição', restrictions.last_restriction)}
            </ul>

            {restrictions?.details && (
              <ProcessAccordion title={`Ocorrências (${restrictions.details.length})`}>
                <div style={{ fontSize: '1.3rem' }}>
                  {_.map(restrictions.details, (occurrence, index) => (
                    <React.Fragment key={index.toString()}>
                      <ul style={{ listStyle: 'none' }}>
                        {
                          Object.entries(occurrence).map(([k, v], i) => {
                            let fieldValue = v
                            if (k === 'amount')
                              fieldValue = formatToBRL(v)
                            if (k === 'address')
                              if (_.isPlainObject(v)) fieldValue = objToString(v)

                            return renderListItem(`li${i}${k}`, creditRestrictionFields[k], fieldValue)
                          })
                        }
                      </ul>

                      <Divider style={{ marginTop: 5 }} />
                    </React.Fragment>
                  ))}
                </div>
              </ProcessAccordion>
            )}
          </div>
        </ProcessAccordion>
      )
    }

    if (_.isEmpty(restrictions)) return null

    return (
      <React.Fragment>
        {/* Retrocompatibilidade com a API credit_restrictions_cnpj antiga: */}
        {Array.isArray(restrictions) && renderRestrictionsDataBackwardCompatible()}

        {/* Novo modo de mostrar as restrições, tanto para CPF como para CNPJ */}
        {!Array.isArray(restrictions) && renderRestrictionsData()}
      </React.Fragment>
    )
  }

  const renderProtestsAccordion = () => {
    if (_.isEmpty(protests)) return null

    const total_value = protests.protests_total_value
    const details = protests.protests_details

    return (
      <ProcessAccordion title="Protestos">
        <div style={{ marginTop: 10, fontSize: '1.3rem' }}>
          <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }} key={protests}>
            {!_.isEmpty(total_value) && renderListItem(total_value, 'Valor Total', formatToBRL(total_value))}
          </ul>

          {!_.isEmpty(details) && (
            <ProcessAccordion title={`Ocorrências (${details.length})`}>
              <div style={{ fontSize: '1.3rem' }}>
                {_.map(details, (occurrence, index) => (
                  <React.Fragment key={index.toString()}>
                    <ul style={{ listStyle: 'none' }}>
                      {
                        Object.entries(occurrence).map(([k, v], i) => {
                          let fieldValue = v
                          if (k === 'protested_value')
                            fieldValue = formatToBRL(v)

                          return renderListItem(`li${i}${k}`, creditProtestFields[k], fieldValue)
                        })
                      }
                    </ul>

                    <Divider style={{ marginTop: 5 }} />
                  </React.Fragment>
                ))}
              </div>
            </ProcessAccordion>
          )}
        </div>

      </ProcessAccordion>
    )
  }

  return (
    <React.Fragment>
      <strong style={{ color: theme.palette.primary.red }}>Sim</strong>
      <div style={{ marginTop: 30, marginLeft: 30 }}>
        {renderRestrictionsAccordion()}
        {renderProtestsAccordion()}
      </div>
    </React.Fragment>
  )
}

export default CreditRestrictions
