import React from 'react'
import classNames from 'classnames'
import styles from './styles'

const TimeCounter = ({ responseTime, small = false, column = false, showText = true }) => {
  const classes = styles()

  const renderTime = () => {
    const minutos = responseTime / 60
    const horas = minutos / 60
    const dias = horas / 24

    if (minutos >= 1 && minutos <= 59) {
      const inc = Math.floor(minutos) === minutos ? 0 : 1
      const text = minutos <= 1 ? 'minuto' : 'minutos'
      return `${Math.floor(minutos) + inc} ${text}`
    }
    if (minutos > 59 && horas <= 23) {
      const inc = Math.floor(horas) === horas ? 0 : 1
      const text = horas <= 1 ? 'hora' : 'horas'
      return `${Math.floor(horas) + inc} ${text}`
    }
    if (horas > 23) {
      const inc = Math.floor(dias) === dias ? 0 : 1
      const text = dias <= 1 ? 'dia' : 'dias'
      return `${Math.floor(dias) + inc} ${text}`
    }

    return `${responseTime} ${small === true ? 'segs' : 'segundos'}`
  }

  return (
    <span className={classNames(classes.container, column ? classes.containerColumn : '')}>
      { showText && 'Processando...' }
      <span className={classNames(classes.time, column ? classes.timeColumn : '')}>
        <i className={classNames(classes.iconTime, 'fas', 'fa-clock')} />
        <span className={classes.timeText}>{renderTime()}</span>
      </span>
    </span>
  )
}

export default TimeCounter
