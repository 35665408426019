import { makeStyles } from '@material-ui/styles'

export default makeStyles(_ => ({
  containerExportCsv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    marginTop: 10,
  },
  containerFilters: {
    display: 'flex',
    'justifyContent': 'space-between',
    marginTop: 10,
  },
  buttonFilterName: {
    marginTop: 5,
    marginBottom: 5,
  },
}))
