export const FETCH_PLAN_CPF_LAST = 'FETCH_PLAN_CPF_LAST'
export const FETCH_PLAN_CPF_LAST_FAILED = 'FETCH_PLAN_CPF_LAST_FAILED'
export const FETCH_PLAN_CPF_LAST_SUCCEEDED = 'FETCH_PLAN_CPF_LAST_SUCCEEDED'

export const CREATE_PLAN_CPF = 'CREATE_PLAN_CPF'
export const CREATE_PLAN_CPF_FAILED = 'CREATE_PLAN_CPF_FAILED'
export const CREATE_PLAN_CPF_SUCCEEDED = 'CREATE_PLAN_CPF_SUCCEEDED'

export const fetchPlanCpfLast = () => ({
  type: FETCH_PLAN_CPF_LAST,
})

export const createPlanCpf = payload => ({
  type: CREATE_PLAN_CPF,
  payload: payload,
})
