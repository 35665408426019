import * as Papa from 'papaparse'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'

import { processText } from '../processText'

export const validateFile = async (file) => {
  return new Promise((resolve, reject) => {
    if (file.type !== 'text/csv') {
      reject(new Error('O arquivo fornecido não é um CSV'))
      return
    }

    if (file.size <= 0) { // This file is invalid somehow
      reject(new Error('O arquivo fornecido está vazio'))
      return
    }

    if (file.size > 5242880) {  // maximum size exceeded
      reject(new Error('O arquivo fornecido é muito grande. Deve ser menor que 5 MB'))
      return
    }

    resolve(file)
  })
}

export const processFile = async (file, docType, _stepFunction) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      config: {
        skipEmptyLines: true,
        escapeFormulae: true,
        worker: true,
      },
      error: function error(err, file, inputElem, reason) {
        // TODO: verificar que tipo de erro pode acontecer para ajudar o usuário
        Sentry.captureException(new Error('Failed to process CSV file', { file, inputElem, reason }))
        console.error('error while processing file', file, inputElem, reason)
        reject(new Error('Ocorreu um erro ao processar o arquivo.'))
      },
      complete: function complete(results, _file) {
        const rawTextSplited = _.map(results.data, (x) => x[0])
        const docsResult = processText(rawTextSplited, docType)

        if (docsResult.totalDocsProvided === 0) {
          reject(new Error(`O arquivo não tem registros. Os ${docType.toUpperCase()}s devem estar na primeira coluna.`))
          return
        }

        resolve(docsResult)
      },
    })
  })
}
