import React from 'react'

import CommonProtestsAccounts from 'ui/components/ContainerCommonPanels/ExpansionPanelProtestsAccounts'

const infos = [
  {
    serviceId: 'ieptb_protests_registry_cpf',
    options: {
      fields: {
        registries: {
          name: 'Registros',
          max_items: 100,
          fields: {
            name: 'Nome',
            state: 'Estado',
            city: 'Cidade',
            time: 'Periodo da pesquisa',
            protests_number: 'Quantidade de protestos',
            protests: {
              name: 'Protestos',
              max_items: 20,
              fields: {
                value: 'Valor',
                protest_date: 'Data do protesto',
                due_date: 'Data do vencimento',
                presenter_name: 'Nome do apresentante',
                assignor_name: 'Nome do cedente',
                costs_value: 'Valor do custo',
                key_number: 'Número da chave',
                has_consent: 'Anuência',
              },
            },
          },
        },
      },
    },
  },
  {
    serviceId: 'irregular_electoral_accounts_cpf',
    options: {
      fields: {
        processes: {
          name: 'Processos',
          fields: {
            process_identifier: 'Número do Processo',
            process_date: 'Data do Processo',
            city: 'Cidade',
            state: 'Estado',
            Cidade: 'Disponível em',
            process_link: 'Processo',
            record_link: 'Gravação',
            judgment_link: 'Julgamento',
          },
        },
      },
    },
  },
  {
    serviceId: 'irregular_accounts_cpf',
    options: {
      fields: {
        processes: {
          name: 'Processos',
          fields: {
            process_identifier: 'Número do Processo',
            process_date: 'Data do Processo',
            city: 'Cidade',
            state: 'Estado',
            Cidade: 'Disponível em',
            process_link: 'Processo',
            record_link: 'Gravação',
            judgment_link: 'Julgamento',
          },
        },
      },
    },
  },
  {
    serviceId: 'unabled_people_cpf',
    options: {
      fields: {
        processes: {
          name: 'Processos',
          fields: {
            process_identifier: 'Número do Processo',
            deliberation: 'Deliberação',
            judgment_date: 'Data do Julgamento',
            final_date: 'Data Final',
          },
        },
      },
    },
  },
  {
    serviceId: 'irregular_bidders_cpf',
    options: {
      fields: {
        processes: {
          name: 'Processos',
          fields: {
            process_identifier: 'Número do Processo',
            state: 'Estado',
            deliberation: 'Deliberação',
            judgment_date: 'Data do Julgamento',
            final_date: 'Data Final',
          },
        },
      },
    },
  },
  {
    serviceId: 'ceis_infos_government_base_cpf',
    options: {
      fields: {
        infos: {
          name: 'Infos',
          fields: {
            cpfcnpj: 'CPF',
            code: 'Código',
            reference_date: 'Data de referência',
            start_sanction_date: 'Início da Sanção',
            end_sanction_date: 'Final da Sanção',
            sanction_publication_date: 'Data de Publicação da Sanção',
            transited_judged_date: 'Data do transitado julgado',
            information_origin_date: 'Data de origem da informação',
            publication_text: 'Texto da publicação',
            publication_link: 'Link da publicação',
            publication_detail: 'Detalhes da publicação',
            process_number: 'Número do Processo',
            related_search: 'Pesquisas relacionadas',
            additional_information: 'Informações Adicionais',
          },
        },
      },
    },
  },
  {
    serviceId: 'cnep_infos_government_base_cpf',
    options: {
      fields: {
        infos: {
          name: 'Infos',
          fields: {
            cpfcnpj: 'CPF',
            code: 'Código',
            reference_date: 'Data de referência',
            start_sanction_date: 'Início da Sanção',
            end_sanction_date: 'Final da Sanção',
            sanction_publication_date: 'Data de Publicação da Sanção',
            transited_judged_date: 'Data do transitado julgado',
            information_origin_date: 'Data de origem da informação',
            sanction_type: 'Tipo de Sanção',
            source_sanction: 'Fonte da Sanção',
            legislation: 'Legislação',
            sanctioning_organ: 'Órgão da Sanção',
            sanctioned: 'Sancionado',
            publication_text: 'Texto da publicação',
            publication_link: 'Link da publicação',
            publication_detail: 'Detalhes da publicação',
            process_number: 'Número do Processo',
            related_search: 'Pesquisas relacionadas',
            additional_information: 'Informações Adicionais',
          },
        },
      },
    },
  },
]

const ExpansionPanelProtestsAccounts = ({ serviceInfos }) => {
  return (
    <CommonProtestsAccounts
      serviceInfos={serviceInfos}
      serviceOptions={infos}
    />
  )
}

export default ExpansionPanelProtestsAccounts
