import { all } from 'redux-saga/effects'

import planCnpjSagas from './planCnpj/sagas'
import planCnpjServicesSagas from './planCnpjServices/sagas'
import planCpfSagas from './planCpf/sagas'
import planCpfServicesSagas from './planCpfServices/sagas'
import userSagas from './user/sagas'

export default function* rootSaga(_getState) {
  yield all([
    planCnpjSagas(),
    planCnpjServicesSagas(),
    planCpfSagas(),
    planCpfServicesSagas(),
    userSagas(),
  ])
}
