import React from 'react'
import { withStyles } from '@material-ui/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'

import styles from './styles'

const Accordion = withStyles({
  root: {
    borderTop: '1px solid #cecece',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    borderRadius: 15,
  },
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    display: 'flex',
  },
})(MuiAccordionSummary)


export default function ProcessAccordion({ title, subtitle, icon = false, children }) {
  const classes = styles()

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {icon && (<i className={classNames(classes.icon, 'far', 'fa-file-pdf')} />)}
            <div>
              <Typography className={classes.heading}>{title}</Typography>
              <Typography className={classes.secondaryHeading}>{subtitle}</Typography>
            </div>
          </div>
        </AccordionSummary>
        <div style={{ marginLeft: 40 }}>
          {children}
        </div>
      </Accordion>
    </div>
  )
}
