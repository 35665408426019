import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(() => ({
  panelLine: {
    marginRight: 10,
    whiteSpace: 'nowrap',
    width: 150,
  },
}))

export default styles
