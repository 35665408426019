import React from 'react'
import { useTheme } from '@material-ui/styles'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorIcon from '@material-ui/icons/Error'

import serviceStatusConstants from 'utils/constants/serviceStatus'
import analysisStatusConstants from 'utils/constants/analysisStatus'

import styles from './styles'

const PanelSubtitle = ({ status, analysisStatus }) => {
  const classes = styles()
  const theme = useTheme()

  const renderElem = () => {
    if (status === serviceStatusConstants.SERVICE_STATUS.processing) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Processando</span>
    }

    switch (analysisStatus) {
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved:
        return <CancelIcon style={{ color: theme.palette.primary.red }} />
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention:
        return <ErrorIcon style={{ color: theme.palette.primary.inAttention }} />
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved:
        return <CheckCircleIcon style={{ color: theme.palette.primary.green }} />
      default:
        return null
    }
  }

  return <div className={classes.subtitle}>{renderElem()}</div>
}

export default PanelSubtitle
