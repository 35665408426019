import React from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { changePanelEnv, logout } from 'redux/auth/actions'
import { isUserLogged } from 'utils/functions'
import { isPermissionAdminOrGreater, isPermissionMaster } from 'utils/validators'

import useRouter from 'utils/useRouter'

export default function ({ type, parent: Parent, child, matchProps, ...rest }) {
  const dispatch = useDispatch()
  const router = useRouter()

  const accessToken = useSelector(state => state.auth.access_token)
  const panelEnv = useSelector(state => state.auth.panelEnv)
  const userPermission = useSelector(state => state.user.permission)

  function returnToLogin() {
    router.history.push({
      pathname: '/',
      state: { from: router.location.pathname },
    })
  }

  if (rest.path.includes('/admin/') && !isPermissionAdminOrGreater(userPermission)) {
    router.history.push(`/${panelEnv}/processing`)
    return null
  }

  if (rest.path.includes('/master/') && !isPermissionMaster(userPermission)) {
    router.history.push(`/${panelEnv}/processing`)
    return null
  }

  if (isUserLogged(accessToken)) {
    if (type !== undefined && panelEnv !== type) {
      dispatch(changePanelEnv({ panelEnv: type }))
    }
    return <Parent component={child} {...rest} />
  }

  if (accessToken) {
    toast.warn('Seu acesso expirou, faça login novamente.')
  }

  dispatch(logout())
  returnToLogin()
  return null
}
