import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { CircularProgress } from '@material-ui/core'

import {
  ButtonBatchConsult,
  ButtonConsult,
  DocListBasic,
  Pagination,
  Table,
  TitleView,
} from 'ui/components'
import { ModalContainerPanels } from 'ui/components/ContainerCommonPanels/utilsFunctions'

import { getList, postCreateDoc, postCreateBatchDoc } from 'services/apiMotorBgc'
import serviceStatusConstants from 'utils/constants/serviceStatus'
import { isDocNumberValid } from 'utils/validators'
import { extractOnlyNumbers } from 'utils/functions'
import  reportError from 'utils/errorReporter'

import Container from './styles'

export default function () {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const savedCallbackListDocs = useRef()

  const [loading, setLoading] = useState(false)
  const [consultsList, setConsultsList] = useState([])
  const [totalRequests, setTotalRequests] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [itemSelected, setItemSelected] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  const listDocs = async (options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getList(
        apiKey,
        userId,
        panelEnv,
        {
          status: serviceStatusConstants.SERVICE_STATUS.processing,
          withServices: true,
          isTakingTimeToProcess: false,
          page: _.has(options, 'page') ? options.page : currentPage,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao listar consultas')
      setLoading(false)
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalRequests(xTotal)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setConsultsList(res.data[`${panelEnv}_datas`])
    setLoading(false)
  }

  const callbackListDocs = () => listDocs()

  useEffect(() => {
    savedCallbackListDocs.current = callbackListDocs
  })

  useEffect(() => {
    listDocs()

    const callListDocs = () => savedCallbackListDocs.current()
    const intervalId = setInterval(callListDocs, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = async (event, page) => {
    const options = {
      page: page,
    }

    listDocs(options)
  }

  const handleClickDoc = (docData) => {
    setItemSelected(docData)
    setModalOpen(!modalOpen)
  }

  const handleAddConfirm = async (values) => {
    const { docNumber } = values
    const docNumberDigits = extractOnlyNumbers(docNumber)

    if (!isDocNumberValid(docNumberDigits)) {
      toast.error(`${panelEnv} inválido!`)
      return
    }

    try {
      await postCreateDoc(apiKey, userId, panelEnv, docNumberDigits)
      listDocs()
    } catch (err) {
      reportError(err, 'Erro ao criar solicitação')
      return
    }

    toast.success('Solicitação criada com sucesso!')
  }

  const handleBatchConsultConfirm = async (values) => {
    try {
      await postCreateBatchDoc(
        apiKey,
        userId,
        panelEnv,
        values.description,
        values.docNumbers,
      )
      listDocs()
    } catch (err) {
      reportError(err, 'Erro ao criar solicitação')
      return
    }

    toast.success('Solicitação criada com sucesso!')
  }

  return (
    <Container>
      <TitleView
        type="Consultas"
        title="Processando"
        subtitle="Consultas processando"
      />

      <div className="main">
        <div className="button-add-container">
          <ButtonConsult
            btnConfirmText="Consultar"
            btnMainText="Criar Nova Consulta"
            onConfirm={handleAddConfirm}
          />
        </div>

        <div className="button-add-container">
          <ButtonBatchConsult
            btnConfirmText="Consultar em Lote"
            btnMainText="Criar Nova Consulta em Lote"
            onConfirm={handleBatchConsultConfirm}
          />
        </div>

        <div className="requests-container">
          <div>
            <div className="title-container">
              <div className="title">
                CONSULTAS PROCESSANDO
              </div>

              <div>
                <div className="total-requests">{`Quantidade: ${totalRequests}`}</div>
              </div>
            </div>
            <Table className="table">
              <thead>
                <tr>
                  <th>Documento</th>
                  <th>Data</th>
                  <th>Progresso</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? <tr><td colSpan={3}><CircularProgress /></td></tr>
                  : (
                    <DocListBasic
                      docList={consultsList}
                      onClickItem={handleClickDoc}
                    />
                  )}
              </tbody>
            </Table>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePage}
            />
          </div>

        </div>
      </div>

      <ModalContainerPanels
        docData={itemSelected}
        docType={panelEnv}
        onClose={handleClickDoc}
        open={modalOpen}
      />
    </Container>
  )
}
