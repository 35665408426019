import axios from 'axios'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'
import { isNetworkError } from 'utils/functions'

axios.interceptors.response.use((response) => {
  return response
}, (err) => {
  Sentry.captureException(err)

  const status = _.get(err, 'response.status') || err.status
  const statusMsg = status ? `${status}: ` : ''

  if (isNetworkError(err))
    toast.error(`${statusMsg}Problema na solicitação: ${err.message}`)

  return Promise.reject(err)
})

export { axios as default }
