import _ from 'lodash'
import { isCPF, isCNPJ } from 'brazilian-values'
import { PANEL_ENVS } from 'utils/constants/environments'
import { USER_PERMISSION } from 'utils/constants/user'

export const isPasswordValid = (value) => {
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  return regex.test(value)
}

export const cpfValid = (string) => {
  return _.isString(string) && isCPF(string)
}

export const cnpjValid = (string) => {
  return _.isString(string) && isCNPJ(string)
}

export const isDocNumberValid = (docNumber, type) => {
  switch (type) {
    case PANEL_ENVS.CPF: return cpfValid(docNumber)
    case PANEL_ENVS.CNPJ: return cnpjValid(docNumber)
    default: return cpfValid(docNumber) || cnpjValid(docNumber)
  }
}

export const isPermissionAdmin = (userPermission) => {
  return USER_PERMISSION.ADMIN === userPermission
}

export const isPermissionMaster = (userPermission) => {
  return USER_PERMISSION.MASTER === userPermission
}

export const isPermissionAdminOrGreater = (userPermission) => {
  return [
    USER_PERMISSION.ADMIN,
    USER_PERMISSION.MASTER,
  ].includes(userPermission)
}
