import styled from 'styled-components'

export default styled.div`
    border: 1px solid #CCCCCC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 350px;

    input {
        background-color: white;
        padding-left: 10px;
        width: 200px;
        height: 40px;
        box-sizing: border-box;
        font-size: 12px;
        color: #9E9E9E;
        border: none;
    }

    input:focus {
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
    }
`
