import _ from 'lodash'

import { getAnalysisStatusMoveToName, getAnalysisStatusNameToKey } from 'utils/constants/analysisStatus'
import { arrayToCsv } from 'utils/functions'

const termsExampleValue = '(Insira termos separados por vírgula)'

const spreadSheetData = {
  blacklist: {
    moveTo: { title: 'Filtro para reprovação/atenção', value: '(Insira REPROVADO, EM ATENÇÃO ou BLOQUEADO)' },
    journal_blacklist_contains: { title: 'Reprovação/atenção: Diários a serem filtrados', value: termsExampleValue },
    process_type_blacklist_contains: { title: 'Reprovação/atenção: Matéria dos processos', value: termsExampleValue },
  },
  whitelist: {
    moveTo: { title: 'Filtro para aprovação', value: 'APROVADO' },
    journal_whitelist_contains: { title: 'Aprovação: Diários a serem filtrados', value: termsExampleValue },
    process_type_whitelist_contains: { title: 'Aprovação: Matéria dos processos', value: termsExampleValue },
  },
  unclassified: {
    moveTo: { title: 'Processos não filtrados', value: '(Insira APROVADO, REPROVADO, EM ATENÇÃO ou BLOQUEADO)' },
  },
}

const typeResultsStructure = {
  type_result_id: '',
  move_to: '',
  data: [],
}

const buildFile = (data) => {
  const rows = []
  _.each(data, (value) => {
    _.each(value, (item) => {
      rows.push([item.title, item.value])
    })
    rows.push([])
  })

  return arrayToCsv(rows)
}

export const buildExampleFile = () => {
  return buildFile(spreadSheetData)
}

export const buildFileFromTypeResults = (typeResults) => {
  const typeResultsObject = { ...spreadSheetData }

  _.each(typeResults, (tr) => {
    const data = typeResultsObject[tr.type_result_id.toLowerCase()]

    // Mapping values
    _.each(tr.data, (item, key) => {
      if (data[key]) {
        data[key].value = item.values.join(',')
      }
    })

    // Mapping move_to
    data.moveTo.value = getAnalysisStatusMoveToName(tr.move_to).toUpperCase()

    typeResultsObject[tr.type_result_id.toLowerCase()] = data
  })

  return buildFile(typeResultsObject)
}

export const buildTypeResultsFromFileText = (content) => {
  const formatTypeResultsData = (rows, item) => {
    const data  = {}

    _.each(item, (itemValue, itemKey) => {
      if (itemKey === 'moveTo') return

      const row = _.find(rows, (r) => r[0] === itemValue.title)
      if (_.isEmpty(row)) return

      const valueRow = row[1].trim()
      const values = _.isEmpty(valueRow) ? [] : valueRow.split(',')
      data[itemKey] = { values: values }
    })

    return data
  }

  const formatTypeResultsMoveTo = (rows, item) => {
    const moveToRow = _.find(rows, (r) => r[0]?.trim() === item.moveTo.title)
    if (_.isEmpty(moveToRow)) return null

    return getAnalysisStatusNameToKey(moveToRow[1].trim())
  }

  const parseTableRows = (textContent) => {
    const rows  = textContent.replaceAll('"', '').split("\n")
    return _.map(rows, r => r.split(';'))
  }

  const rows = parseTableRows(content)
  const typeResults = []

  _.each(spreadSheetData, (trItem, trKey) => {
    const typeResult = { ...typeResultsStructure }

    typeResult.type_result_id = trKey.toUpperCase()

    typeResult.move_to = formatTypeResultsMoveTo(rows, trItem)

    typeResult.data = formatTypeResultsData(rows, trItem)

    typeResults.push(typeResult)
  })

  return typeResults
}
