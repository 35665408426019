import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { withStyles } from '@material-ui/styles'
import { TextField, Typography } from '@material-ui/core'

import { ButtonRecaptcha } from 'ui/components'

import useHideZendeskWidget from 'hooks/useHideZendesk'
import { recoverPassword } from 'redux/auth/actions'
import { recoverPasswd } from 'services/apiAuth'
import { isNetworkError } from 'utils/functions'

import styles from './styles'

const PasswordRecover = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [usernameEmail, setUsernameEmail] = useState('')
  const [error, setError] = useState(false)

  useHideZendeskWidget()

  const handleSubmit = async (argUsernameEmail, argRecaptchaToken) => {
    setError(false)

    try {
      const formData = new FormData()
      formData.append('username_email', argUsernameEmail)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)
      formData.append('recaptcha_token', argRecaptchaToken)

      const res = await recoverPasswd(formData)
      if (res.status === 200) {
        dispatch(recoverPassword(res.data.destination))

        return history.push('/password-confirm-recover')
      }
    } catch (err) {
      if (!isNetworkError(err)) {
        const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao recuperar senha'
        toast.error(messageError)
      }

      return null
    }

    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <img src="/assets/images/logos/logo.svg" alt="Logo" className={classes.logo} />
        <div className={classes.titleSidebarContainer}>
          <h1 className={classes.titleSidebar}>Recuperar Senha</h1>
          <p className={classes.subtitleSidebar}>Recupere sua senha perdida ou esquecida!</p>
        </div>
      </div>
      <div className={classes.main}>
        <div>
          <h1 className={classes.title}>
            Insira seu email ou nome de usuário:
          </h1>
          <p>Enviaremos para o email cadastrado um código que deverá ser utilizado para recuperar a senha.</p>
        </div>

        <TextField
          id="email"
          label="E-mail"
          className={classes.textField}
          value={usernameEmail}
          onChange={(e) => setUsernameEmail(e.target.value)}
          type="email"
          required={true}
          autoCapitalize="off"
          autoCorrect="off"
        />
        {error ? (
          <p>
            <strong className={classes.error}>Usuário não encontrado nesse aplicativo.</strong>
          </p>
        ) : null}

        <ButtonRecaptcha
          text="Recuperar"
          onClick={(token) => handleSubmit(usernameEmail, token)}
        />

        <Typography>
          Lembrei minha senha.
          {' '}
          <Link to="/" className={classes.link}>
            Entrar
          </Link>
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(PasswordRecover)
