import styled from 'styled-components'

export default styled.div`
  .title-container {
    margin: 10px 0;
  }

  .table {
    width: 100%;

    tr {
      margin: 5px 0;
    }
  }

  .percentage {
    color: #00f;
    font-size: 12px;
    position: absolute;
    margin-left: 5px;
  }
`
