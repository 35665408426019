import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(_theme => ({
  container: {},
  dialogContainer: {
    width: '40vw',
  },
  radioContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  textareaContainer: {},
  annotationsContainer: {
    paddingBottom: 10,
  },
  annotationContainer: {
    paddingBottom: 10,
    paddingTop: 10,
  },
}))

export default styles
