import { useEffect } from 'react'

/** Use this hook on pages you would want to not show the Zendesk widget. */
const useHideZendeskWidget = () => {
  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'hide')
      return () => window.zE('webWidget', 'show')
    }

    return undefined
  }, [])
}

export default useHideZendeskWidget
