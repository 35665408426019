import _ from "lodash"

const planServiceHelp = {
  processes_classifier: {
    journal_blacklist_contains: 'Em quais diários deseja buscar os processos? Digite termos como: TJ, STJ, STF, TRF, TRABALHO.',
    journal_blacklist_exact: 'Em quais diários deseja buscar os processos? Digite nomes exatos.',

    process_type_blacklist_contains: 'Digite a matéria dos processos a serem buscados, por exemplo: criminal, trabalhista, fraude, execução de título, estelionato, etc.',
    process_type_blacklist_exact: 'Digite a matéria dos processos a serem buscados. Digite matérias exatas.',

    journal_whitelist_contains: 'Em quais diários deseja buscar os processos? Processos encontrados com esse filtro serão marcados como APROVADOs, ou seja, não serão considerados como pendência. Digite termos como: TJ, STJ, STF, TRF, TRABALHO.',
    journal_whitelist_exact: 'Em quais diários deseja buscar os processos? Processos encontrados com esse filtro serão marcados como APROVADOs, ou seja, não serão considerados como pendência. Digite nomes exatos.',

    process_type_whitelist_contains: 'Digite a matéria dos processos a serem buscados. Processos encontrados com esse filtro serão marcados como APROVADOs, ou seja, não serão considerados como pendência. Exemplos: criminal, trabalhista, fraude, execução de título, estelionato, etc.',
    process_type_whitelist_exact: 'Digite a matéria dos processos a serem buscados. Digite matérias exatas. Processos encontrados com esse filtro serão marcados como APROVADOs, ou seja, não serão considerados como pendência.',
  },
}

const getPlanServiceHelp = (serviceId, typeResultId) => {
  return _.get(planServiceHelp, [serviceId, typeResultId])
}

export default getPlanServiceHelp
