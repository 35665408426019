import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'ui/components'

const Container = styled.div`
`

export default function ({ history }) {
  const [loading, setLoading] = useState(false)

  const handleReturn = function () {
    history.goBack()
  }

  const handleButtonClick = function (type) {
    alert(`Clicou no botão ${type}`)
  }

  const handleToggleLoading = function () {
    setLoading(!loading)
  }

  return (
    <Container>
      <nav>
        <button type="button" onClick={handleReturn}>
          Voltar
        </button>
      </nav>

      <Button onClick={() => {}}>Desabilitado sem onClick</Button>
      <Button onClick={() => handleButtonClick('Padrão')}>Cor Padrão</Button>
      <Button className="primary" onClick={() => handleButtonClick('Primário')}>
        Cor Primária do Tema
      </Button>
      <Button variant="black" onClick={() => handleButtonClick('Preto')}>
        Cor Preta
      </Button>
      <Button variant="primary" onClick={handleToggleLoading} loading={loading}>
        Ação de Loading
      </Button>
    </Container>
  )
}
