import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: 20,
  },
  progress: {
    height: '25px !important',
    margin: theme.spacing(1),
    width: '25px !important',
  },
}))
