import { makeStyles } from '@material-ui/styles'

export default makeStyles(_ => ({
  buttonsContainer: {
    display: 'flex',
  },
  buttonFilterName: {
    alignSelf: 'start',
    flexGrow: 1,
  },
  buttonOrganizationCreate: {
    alignSelf: 'end',
  },
}))
