import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'
import { MANUAL_DERIVATION_STATUS } from 'utils/constants/manualDerivationStatus'

export default function ({ location }) {
  const title = 'Em Analise'
  const subtitle = `Consultas sendo analisadas por um usuário`
  const color = '#ddd'
  const manualDerivationStatus = MANUAL_DERIVATION_STATUS.IN_ANALYSIS
  const withManualAnalysis = true
  const itemWithManualAnalysis = true

  return (
    <BaseConsultsList
      location={location}
      title={title}
      subtitle={subtitle}
      color={color}
      manualDerivationStatus={manualDerivationStatus}
      withManualAnalysis={withManualAnalysis}
      itemWithManualAnalysis={itemWithManualAnalysis}
    />
  )
}
