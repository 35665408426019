const POLE_TYPE = {
  PASSIVE: 'POLE_PASSIVE',
  ACTIVE: 'POLE_ACTIVE',
  OTHER: 'POLE_OTHER',
  UNDEFINED: 'POLE_UNDEFINED',
}

const POLE_TYPE_TO_CONSULT = {
  PASSIVE: POLE_TYPE.PASSIVE,
  ACTIVE: POLE_TYPE.ACTIVE,
  ALL: 'ALL',
}

const POLE_TYPE_TRANSLATED = {
  PASSIVE: 'PASSIVO',
  ACTIVE: 'ATIVO',
  OTHER: 'OUTRO',
  UNDEFINED: 'INDEFINIDO',
}

export {
  POLE_TYPE,
  POLE_TYPE_TRANSLATED,
  POLE_TYPE_TO_CONSULT,
}
