import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { GROUP_ID, nameGroupId } from 'utils/constants/groupService'
import serviceStatusConstants from 'utils/constants/serviceStatus'
import analysisStatusConstants from 'utils/constants/analysisStatus'

import ExpansionPanel from 'ui/components/ExpansionPanel'
import ExpansionPanelCertificates from 'ui/components/ContainerCommonPanels/ExpansionPanelCertificates'
import ExpansionPanelTRFProcesses from 'ui/components/ContainerCommonPanels/ExpansionPanelTRFProcesses'
// import ExpansionPanelNegativeMedia from 'ui/components/ContainerCommonPanels/ExpansionPanelNegativeMedia'
// import ExpansionPanelCvm from 'ui/components/ContainerCommonPanels/ExpansionPanelCvm'
import ExpansionPanelProcessesPJE from 'ui/components/ContainerCommonPanels/ExpansionPanelProcessesPJE'
import ExpansionPanelJudicialCriminalProcedures from 'ui/components/ContainerCommonPanels/ExpansionPanelJudicialCriminalProcedures'
import ExpansionPanelProcessesTJ from 'ui/components/ContainerCommonPanels/ExpansionPanelProcessesTJ'

import PanelSubtitle from 'ui/components/ContainerCommonPanels/Panels/PanelSubtitle'
import PanelTitle from 'ui/components/ContainerCommonPanels/Panels/PanelTitle'

import ExpansionPanelCadastralData from '../ExpansionPanelCadastralData'
import ExpansionPanelCadastralExtension from '../ExpansionPanelCadastralExtension'
// import ExpansionPanelCreditHistory from '../ExpansionPanelCreditHistory'
import ExpansionPanelProtestsAccounts from '../ExpansionPanelProtestsAccounts'
// import ExpansionPanelIBAMA from '../ExpansionPanelIBAMA'

const Panels = ({ docData }) => {
  const servicesList = useSelector((state) => state.planCpfServices.services)

  const findServiceById = (services, serviceId) => (
    _.find(services, { service_id: serviceId })
  )

  const hasRestrictionsInCreditHistory = (person) => {
    const hasBankingRestrictions = _.size(person.banking_restrictions) > 0
    const hasPostDatedChecks = _.size(person.pre_dated_checks) > 0
    const hasPurchaseRestrictions = _.size(person.shopkeepers_restrictions) > 0
    const hasAlerts = _.size(person.alerts) > 0

    return (hasBankingRestrictions || hasPostDatedChecks || hasPurchaseRestrictions || hasAlerts)
  }

  const hasDeath = (person) => {
    return !_.isEmpty(person.death)
  }

  const filterPersonServicesByGroupId = (servicesFiltered, groupId, personService) => {
    const clonedPersonService = _.cloneDeep(personService)
    const person = _.get(clonedPersonService, 'data.person')
    if (_.isEmpty(person)) return servicesFiltered

    switch (groupId) {
      case GROUP_ID.CREDIT_HISTORY: {
        if (hasRestrictionsInCreditHistory(person)) {
          return [...servicesFiltered, clonedPersonService]
        }

        break
      }
      case GROUP_ID.CADASTRAL_DATA: {
        if (hasDeath(person)) {
          return servicesFiltered
        }

        break
      }
      default: {
        return servicesFiltered
      }
    }

    const servicesRejectPerson = _.reject(servicesFiltered, { service_id: 'search_infos_person_complete_v2' })
    clonedPersonService.analysis_status = analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved

    return [...servicesRejectPerson, clonedPersonService]
  }

  const filterServicesByGroupId = (services, groupId) => {
    const servicesIdsPermitted = _
      .chain(servicesList)
      .filter({ group_id: groupId })
      .map((serv) => serv.service_id)
      .value()

    return _.filter(services, (service) => servicesIdsPermitted.includes(service.service_id))
  }

  /** Sobrescreve a análise de status para groupIds especificados */
  const defineAnalysisStatus = (servicesFiltered, groupId, personService) => {
    const newServicesFiltered = filterPersonServicesByGroupId(servicesFiltered, groupId, personService)

    if (_.some(newServicesFiltered, { analysis_status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved })) {
      return analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved
    }
    if (_.every(newServicesFiltered, { analysis_status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved })) {
      return analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved
    }

    return null
  }

  const renderPanel = (groupId) => {
    const serviceInfos = _.flatMap(servicesList, (serviceDesc) => {
      const serviceInfosFiltered = _.filter(docData.service_infos, { service_id: serviceDesc.service_id })

      if (serviceInfosFiltered.length > 1) {
        return serviceInfosFiltered.map((serv) => (
          {
            ...serv,
            service_id: serviceDesc.service_id,
            name: serviceDesc.name,
            name_short: serviceDesc.name_short,
            response_time: serviceDesc.response_time,
          }
        ))
      }

      return {
        ...serviceInfosFiltered[0],
        service_id: serviceDesc.service_id,
        name: serviceDesc.name,
        name_short: serviceDesc.name_short,
        response_time: serviceDesc.response_time,
      }
    })

    const personService = findServiceById(serviceInfos, 'search_infos_person_complete_v2')
    let elemPanel = null
    let servicesFiltered = []
    switch (groupId) {
      case GROUP_ID.CADASTRAL_DATA: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CADASTRAL_DATA)
        elemPanel = <ExpansionPanelCadastralData serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.CADASTRAL_EXTENSION: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CADASTRAL_EXTENSION)
        elemPanel = <ExpansionPanelCadastralExtension serviceInfos={servicesFiltered} personService={personService} />
        break
      }
      case GROUP_ID.PROTESTS_ACCOUNTS: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.PROTESTS_ACCOUNTS)
        elemPanel = <ExpansionPanelProtestsAccounts serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.CERTIFICATES: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.CERTIFICATES)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.TRF_PROCESSES: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.TRF_PROCESSES)
        elemPanel = <ExpansionPanelTRFProcesses serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.COURT_LAWSUITS: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.COURT_LAWSUITS)
        elemPanel = <ExpansionPanelJudicialCriminalProcedures serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.LABOR_COURTS: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.LABOR_COURTS)
        elemPanel = <ExpansionPanelCertificates serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.PJE_FIRST_INSTANCE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.PJE_FIRST_INSTANCE)
        elemPanel = <ExpansionPanelProcessesPJE serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.PJE_SECOND_INSTANCE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.PJE_SECOND_INSTANCE)
        elemPanel = <ExpansionPanelProcessesPJE serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.TJ_FIRST_INSTANCE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.TJ_FIRST_INSTANCE)
        elemPanel = <ExpansionPanelProcessesTJ serviceInfos={servicesFiltered} />
        break
      }
      case GROUP_ID.TJ_SECOND_INSTANCE: {
        servicesFiltered = filterServicesByGroupId(serviceInfos, GROUP_ID.TJ_SECOND_INSTANCE)
        elemPanel = <ExpansionPanelProcessesTJ serviceInfos={servicesFiltered} />
        break
      }
      default:
        return null
    }

    if (_.isEmpty(servicesFiltered)) return null

    let status = null
    if (_.some(servicesFiltered, { status: serviceStatusConstants.SERVICE_STATUS.processing })) {
      status = serviceStatusConstants.SERVICE_STATUS.processing
    } else if (_.every(servicesFiltered, { status: serviceStatusConstants.SERVICE_STATUS.processed })) {
      status = serviceStatusConstants.SERVICE_STATUS.processed
    }

    const analysisStatus = defineAnalysisStatus(servicesFiltered, groupId, personService)

    return (
      <ExpansionPanel
        key={groupId}
        title={<PanelTitle text={nameGroupId(groupId)} analysisStatus={analysisStatus} />}
        subtitle={<PanelSubtitle status={status} analysisStatus={analysisStatus} />}
      >
        {elemPanel}
      </ExpansionPanel>
    )
  }

  return (
    <React.Fragment>
      {
        _.map(GROUP_ID, (groupId) => renderPanel(groupId))
      }
    </React.Fragment>
  )
}

export default Panels
