import styled from 'styled-components'

// TODO: Get rid from styled components from this component while maintaining the behavior.
export default styled.div`
  .main {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  input {
    background-color: white;
    padding-left: 15px;
    width: 300px;
    height: 40px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    font-size: 12px;
    color: #9E9E9E;
  }

  form {
    width: 470px;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  td {
    padding: 10px 40px !important;
  }

  .button-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  .requests-container {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .MuiCircularProgress-root {
      width: initial !important;
      height: initial !important;

      svg {
        margin-right: 0px;
        width: 14px;
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    color: ${(props) => props.theme.palette.gray[600]};
  }

  .total-requests {
    font-weight: bold;
    font-size: 14px;
  }

  .table {
    margin-top: 20px;
    width: 500px;
  }

  .pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .second-container {
    margin-left: 30px;
  }

  .column-icon {
    display: flex;
    justify-content: center;
  }

  .background-icon {
    svg {
      margin-right: 0px;
    }

    height: 40px;
    width: 40px;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .disabled {
    pointer-events: none;
  }

  .handler {
    cursor: pointer;
  }

  .button-add-container {
    align-self: flex-start;
  }
`
