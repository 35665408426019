import React from 'react'
import styled from 'styled-components'

import { Icons, IconButton } from 'ui/components'

const Container = styled.div`
  h3 {
    margin: 1rem 0;
  }

  .icon_line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem;

      svg {
        margin: 1rem;
      }
    }
  }

  .primary {
    color: ${(props) => props.theme.palette.primary};
    display: inline-block;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.palette.gray[100]};
    }
  }
`

export default function ({ history }) {
  const handleClick = function () {
    alert('Click funciona envolvendo o ícone com uma div!')
  }

  const handleIconButtonClick = function () {
    alert('Use este componente em tabelas')
  }

  return (
    <Container>
      <h3>Icons</h3>
      <div className="icon_line">
        <div className="primary" onClick={handleClick}>
          <Icons icon="user" />
          <span>user</span>
        </div>
        <div>
          <Icons icon="userCheck" color="teal" />
          <span>userCheck</span>
        </div>
        <div>
          <Icons icon="UserPlus" />
          <span>UserPlus</span>
        </div>
        <div>
          <Icons icon="users" />
          <span>users</span>
        </div>
        <div>
          <Icons icon="search" />
          <span>search</span>
        </div>
        <div>
          <Icons icon="trash" />
          <span>trash</span>
        </div>
        <div>
          <Icons icon="calendar" />
          <span>calendar</span>
        </div>
        <div>
          <Icons icon="checkCircle" />
          <span>checkCircle</span>
        </div>
        <div>
          <Icons icon="xCircle" />
          <span>xCircle</span>
        </div>
        <div>
          <Icons icon="plusCircle" />
          <span>plusCircle</span>
        </div>
        <div>
          <Icons icon="alertCircle" />
          <span>alertCircle</span>
        </div>
        <div>
          <Icons icon="more" />
          <span>more</span>
        </div>
        <div>
          <Icons icon="history" />
          <span>history</span>
        </div>
        <div>
          <Icons icon="chevronLeft" />
          <span>chevronLeft</span>
        </div>
        <div>
          <Icons icon="chevronRight" />
          <span>chevronRight</span>
        </div>
        <div>
          <Icons icon="chevronDown" />
          <span>chevronDown</span>
        </div>
        <div>
          <Icons icon="chevronUp" />
          <span>chevronUp</span>
        </div>
        <div>
          <Icons icon="fileText" />
          <span>fileText</span>
        </div>
        <div>
          <Icons icon="settings" />
          <span>settings</span>
        </div>
        <div>
          <Icons icon="edit" />
          <span>edit</span>
        </div>
        <div>
          <Icons icon="close" />
          <span>close</span>
        </div>
        <div>
          <Icons icon="checkFilled" color="teal" />
          <span>checkFilled</span>
        </div>
        <div>
          <Icons icon="errorFilled" color="tomato" />
          <span>errorFilled</span>
        </div>
        <div>
          <Icons icon="alertFilled" color="gold" />
          <span>alertFilled</span>
        </div>
        <div>
          <Icons icon="square" />
          <span>square</span>
        </div>
        <div>
          <Icons icon="squareCheck" color="teal" />
          <span>squareCheck</span>
        </div>
        <div>
          <Icons icon="helpFilled" />
          <span>helpFilled</span>
        </div>
        <div>
          <Icons icon="infoCircle" />
          <span>infoCircle</span>
        </div>
        <div>
          <Icons icon="block" />
          <span>block</span>
        </div>
        <div>
          <Icons icon="radio" />
          <span>radio</span>
        </div>
        <div>
          <Icons icon="radioFilled" color="teal" />
          <span>radioFilled</span>
        </div>
        <div>
          <Icons icon="refresh" />
          <span>refresh</span>
        </div>
      </div>

      <div>
        <h3>Icon Button</h3>
        <IconButton
          icon="user"
          label="Usuário"
          onClick={handleIconButtonClick}
        />
      </div>
    </Container>
  )
}
