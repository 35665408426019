import React from 'react'
import _ from 'lodash'
import { Paper, TableContainer } from '@material-ui/core'
import { TitleBold } from 'ui/components/ContainerCommonPanels/styledComponents'

import { ConsultStatusIcon } from 'utils/icons'

import MemberTable from './MemberTable'

const renderNoMembers = () => {
  return (
    <div style={{ margin: 15 }}>
      Não possui membros no quadro societário.
    </div>
  )
}

export const renderTable = (memberData, docData) => {
  const members = memberData?.membership_board || []
  if (_.isEmpty(members)) return renderNoMembers()

  const membersWithDoc = _.filter(members, (m) => m.cpf || m.cnpj)
  const membersWithoutDoc = _.filter(members, (m) => _.isNil(m.cpf) && _.isNil(m.cnpj))

  return (
    <TableContainer component={Paper}>
      <MemberTable
        members={membersWithDoc}
        membersWithoutDocNumber={membersWithoutDoc}
        docData={docData}
      />
    </TableContainer>
  )
}

const RecursiveMembershipboard = ({ membershipBoardRecursiveService, docData }) => {
  if (_.isEmpty(membershipBoardRecursiveService)) return null

  const serviceData = membershipBoardRecursiveService?.data

  const renderStatus = () => {
    return (
      <div style={{ display: 'flex', fontSize: '1.5rem', marginTop: 10 }}>
        Status:
        {' '}
        <ConsultStatusIcon consult={membershipBoardRecursiveService} showText={true} />
      </div>
    )
  }

  const renderTotalCpfs = () => {
    const totalCpfs = serviceData?.cpfs_found?.length || 0

    return (
      <div>
        Total de CPFs:
        {' '}
        {totalCpfs}
      </div>
    )
  }

  const renderTotalCnpjs = () => {
    const totalCnpjs = _.without(serviceData?.cnpjs_searched, serviceData.cnpj).length || 0

    return (
      <div>
        Total de Cnpjs:
        {' '}
        {totalCnpjs}
      </div>
    )
  }

  const renderTotals = () => {
    return (
      <div style={{ display: 'flex', gap: 10, fontSize: '1.5rem' }}>
        {renderTotalCpfs()}
        {'|'}
        {renderTotalCnpjs()}
      </div>
    )
  }

  const renderDetails = () => {
    if (_.isEmpty(serviceData)) return null

    return (
      <React.Fragment>
        {renderTotals()}
        {renderTable(serviceData?.data, docData)}
      </React.Fragment>
    )
  }

  return (
    <div style={{ marginTop: '30px' }}>
      <TitleBold>Quadro societário detalhado</TitleBold>

      {renderStatus()}
      {renderDetails()}
    </div>
  )
}

export default RecursiveMembershipboard
