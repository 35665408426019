import React from 'react'
import { withStyles } from '@material-ui/styles'
import MUIDataTableDefault from 'mui-datatables'

import defaultOptions from './defaultOptions'

const MUIDataTable = withStyles({
  liveAnnounce: {
    display: 'none',
  },
})(MUIDataTableDefault)

const DataTable = ({ columns, data, options }) => {
  const allOptions = { ...defaultOptions, ...options }

  return (
    <MUIDataTable
      columns={columns}
      data={data}
      options={allOptions}
    />
  )
}

export default DataTable
