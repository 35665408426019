import _ from 'lodash'

const typeResultsForcedData = {
  processes_classifier: {
    'BLACKLIST': {
      disabledOptions: ['APPROVED'],
    },
    'WHITELIST': {
      forceDisabled: true,
      forcedValue: 'APPROVED',
    },
  },
}

export const typeResultMoveToForceDisabled = (serviceId, typeResultId) => (
  _.get(typeResultsForcedData, [serviceId, typeResultId, 'forceDisabled'])
)

export const typeResultMoveToForcedValue = (serviceId, typeResultId, defaultValue = null) => (
  _.get(typeResultsForcedData, [serviceId, typeResultId, 'forcedValue'], defaultValue)
)

export const typeResultMoveToDisabledOptions = (serviceId, typeResultId) => (
  _.get(typeResultsForcedData, [serviceId, typeResultId, 'disabledOptions'])
)
