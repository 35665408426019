import React from 'react'
import { useTheme } from '@material-ui/styles'

import analysisStatusConstants from 'utils/constants/analysisStatus'

const PanelTitle = ({ text, analysisStatus }) => {
  const theme = useTheme()

  const colorSelect = () => {
    // TODO: adicionar outros status
    switch (analysisStatus) {
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved:
        return theme.palette.primary.red
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention:
        return theme.palette.primary.inAttention
      case analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved:
        return theme.palette.primary.green
      default:
        return theme.palette.primary.grayDark
    }
  }

  return <div style={{ color: colorSelect() }}>{text}</div>
}

export default PanelTitle
