import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  container:{
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  containerColumn: {
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  time: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    borderRadius: 8,
    padding: '1px 7px',
    color: theme.palette.primary.lighter,
    fontWeight: 'bold',
    backgroundColor: '#F69C61',
    marginLeft: 15,
  },
  timeColumn: {
    marginLeft: 0,
  },
  iconTime: {
    fontSize: 18,
    marginRight: 10,
  },
  timeText: {
    whiteSpace: 'nowrap',
  },
}))

export default styles
