import _ from 'lodash'

import {
  FETCH_USER,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCEEDED,
} from './actions'

const initialState = {
  fail: false,
  load: false,
  email: null,
  permission: null,
  org_id: null,

  role: null,
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER: {
      return {
        ...state,
        fail: false,
        load: true,
      }
    }
    case FETCH_USER_FAILED: {
      return {
        ..._.cloneDeep(initialState),
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case FETCH_USER_SUCCEEDED: {
      return {
        fail: false,
        load: false,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export default userReducer
