import axios from './axiosCustom'

const HOST = process.env.REACT_APP_AUTHENTICATOR_API
const AUTH = process.env.REACT_APP_AUTHENTICATOR_AUTH
const ROUTE = '/v2'

export async function login(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROUTE}/users/login`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
    validateStatus: (status) => status >= 200 && status <= 302,
  })

  return res
}

export async function recoverPasswd(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROUTE}/passwords/forgot`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })

  return res
}

export async function confirmRecoverPassword(formData) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROUTE}/passwords/forgot/confirm`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })

  return res
}

export async function changePassword(data) {
  const res = await axios({
    method: 'POST',
    url: `${HOST}${ROUTE}/passwords/change`,
    headers: {
      'x-api-key': AUTH,
      'Content-Type': 'application/json',
    },
    data: data,
  })

  return res
}
