import styled from 'styled-components'

export default styled.div`
    width: 100%;
    margin-bottom: 1rem;

    .textinput {
        width: 100%;

        &__label {
            display: block;
            font-weight: 600;
            font-size: 1.2rem;
            margin-bottom: 0.3rem;

            &--error {
                color: ${(props) => props.theme.palette.error};
            }
        }

        &__input,
        &__area {
            width: 100%;
            border: 1px solid ${(props) => props.theme.palette.gray[400]};
            outline: none;
            padding: 1rem;
            font-size: 1.2rem;

            resize: none;

            &--error {
                border-color: ${(props) => props.theme.palette.error};
            }
        }

        &__input {
            height: 3.5rem;
            padding: 0 1rem;
        }

        &__error {
            text-align: right;
            font-weight: 600;
            font-size: 1rem;
            margin-top: 0.3rem;
            color: ${(props) => props.theme.palette.error};
        }
    }
`
