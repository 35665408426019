import styled from 'styled-components'

export default styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  position: relative;
  cursor: pointer;
  width: 3.5rem;
  height: 3.5rem;

  .background {
    background-color: rgba(0, 0, 0, 0.05);
    position: absolute;
    width: 140%;
    height: 140%;
    border-radius: 50%;
    transform: translate(-13%, -80%) scale(0);
    transition: all 0.2s ease-in-out;
  }

  .label {
    position: absolute;
    white-space: nowrap;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 150%;
    font-size: 1.1rem;
    background-color: ${(props) => props.theme.palette.gray[700]};
    color: white;
    padding: 0.2rem 0.4rem;
    border-radius: 0.2rem;
    transition: visibility 0.2s, opacity 0.2s;
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    .background {
      transform: translate(-13%, -80%) scale(1);
    }

    .label {
      visibility: visible;
      opacity: 1;
    }
  }
`
