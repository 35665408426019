import { Tooltip as MaterialUITooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(MaterialUITooltip)

export default Tooltip
