import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from '@material-ui/core'

export default ({ value, min, max, disabled, onChange }) => {
  const [counter, setCounter] = useState(value)

  useEffect(() => {
    setCounter(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    onChange(counter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])

  const handleDecrement = () => {
    if (counter === min) return

    setCounter(counter - 1)
  }

  const handleIncrement = () => {
    if (counter === max) return

    setCounter(counter + 1)
  }

  return (
    <ButtonGroup size="small">
      <Button disabled={disabled || counter === min} onClick={handleDecrement}>-</Button>
      <Button style={{ color: disabled ? null : 'black' }} disabled>{counter}</Button>
      <Button disabled={disabled || counter === max} onClick={handleIncrement}>+</Button>
    </ButtonGroup>
  )
}
