import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { CircularProgress, TextField } from '@material-ui/core'

import { Button } from 'ui/components'
import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import { columnsOrganization } from 'ui/components/ModalSelectOrganization/configDataTable'
import ButtonOrganizationCreate from 'ui/components/ButtonOrganizationCreate'

import { getOrganizations } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'

import styles from './styles'

export default function () {
  const classes = styles()

  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)

  const [loading, setLoading] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [organizations, setOrganizations] = useState([])
  const [pageData, setPageData] = useState({ current: 1, totalItems: 0, totalPages: 0, perPage: 0 })

  const fetchOrganizations = async (options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getOrganizations(
        apiKey,
        userId,
        {
          name: options.name,
          page: _.has(options, 'page') ? options.page : pageData.current,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao obter organizações')
      setLoading(false)
      return
    }

    const current = parseInt(res.headers['x-page'], 10)
    const totalItems = parseInt(res.headers['x-total'], 10)
    const perPage = parseInt(res.headers['x-per-page'], 10)
    const totalPages =  Math.ceil(totalItems / perPage)
    setPageData({ current: current, totalPages: totalPages, totalItems: totalItems, perPage: perPage })
    setOrganizations(res.data.organizations)
    setLoading(false)
  }

  useEffect(() => {
    fetchOrganizations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearchName = () => {
    fetchOrganizations({ name: searchName, page: 1 })
  }

  const handleChangePage = (number) => {
    fetchOrganizations({ name: searchName, page: number + 1 })
  }

  const handleOrgCreate = () => {
    setSearchName('')
    fetchOrganizations({ page: 1 })
  }

  const renderButtonOrganizationCreate = () => {
    return (
      <div className={classes.buttonOrganizationCreate}>
        <ButtonOrganizationCreate onCreate={handleOrgCreate} />
      </div>
    )
  }

  const renderFilterName = () => {
    return (
      <div className={classes.buttonFilterName}>
        <TextField label="Nome" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
        <Button variant="text" onClick={handleSearchName}>Filtrar</Button>
      </div>
    )
  }

  const renderOrganizations = () => {
    if (loading) return <CircularProgress />

    return (
      <DataTable
        columns={columnsOrganization}
        data={organizations}
        options={{
          pagination: true,
          serverSide: true,
          rowsPerPage: pageData.perPage,
          count: pageData.totalItems,
          page: pageData.current - 1,
          sort: false,
          onChangePage: handleChangePage,
        }}
      />
    )
  }

  return (
    <div>
      <div className={classes.buttonsContainer}>
        {renderFilterName()}
        {renderButtonOrganizationCreate()}
      </div>
      {renderOrganizations()}
    </div>
  )
}
