import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { formatToCNPJ, formatToCPF, formatToPhone } from 'brazilian-values'

import {
  Grid,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { PANEL_ENVS } from 'utils/constants/environments'
import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import { TitleBold, Line, LineTitle, LineContent } from 'ui/components/ContainerCommonPanels/styledComponents'

import {
  columnsAddress,
  columnsCnaes,
  columnsLegalNature,
  columnsMembershipBoard,
} from './configDataTable'

import styles from './styles'

const CadastralData = ({ membershipBoardService, isMembershipBoardRecursivePresent }) => {
  const classes = styles()
  const theme = useTheme()

  const userId = useSelector(state => state.auth.sub)
  const apiKey = useSelector(state => state.auth.api_key)
  const cpfPlanId = useSelector((state) => state.planCpf.plan_id)
  const cnpjPlanId = useSelector((state) => state.planCnpj.plan_id)


  const renderCadastralSituation = () => {
    let situation = membershipBoardService.cadastral_situation
    let situationDescription = null

    // If situation has a composed name, like 'ATIVA - EMPRESA DOMICILIADA NO EXTERIOR'
    if (situation?.includes(' - ')) {
      [situation, situationDescription] = situation.split(' - ')
    }

    const reason = membershipBoardService.cadastral_situation_reason
    const color = situation?.includes('ATIVA') ? theme.palette.primary.green : theme.palette.primary.red

    return membershipBoardService.cnpj && (
      <React.Fragment>
        <Line>
          <LineTitle>CNPJ</LineTitle>
          <LineContent style={{ width: 'initial', display: 'flex' }}>
            {formatToCNPJ(membershipBoardService.cnpj)}
            {
              situation && (
                <span style={{ marginLeft: 10, color: color }}>
                  {situation}
                </span>
              )
            }
          </LineContent>
        </Line>

        { situationDescription && (
          <Line>
            <LineTitle />
            <LineContent style={{ width: 'initial', display: 'flex' }}>
              <span style={{ color: color }}>
                {situationDescription}
              </span>
            </LineContent>
          </Line>
        )}

        { reason && (
          <Line>
            <LineTitle>Motivo da Situação Cadastral</LineTitle>
            <LineContent style={{ width: 'initial', display: 'flex' }}>
              <span>
                {reason}
              </span>
            </LineContent>
          </Line>
        )}
      </React.Fragment>
    )
  }

  const renderEmployeesNumber = () => {
    const employees = membershipBoardService.employees_number
    return employees && (
      <Line>
        <LineTitle>Número de Colaboradores</LineTitle>
        <LineContent style={{ width: 'initial' }}>{employees}</LineContent>
      </Line>
    )
  }

  const renderOpeningDate = () => {
    const openingDate = membershipBoardService.opening_date
    return openingDate && (
      <Line>
        <LineTitle>Data de Abertura</LineTitle>
        <LineContent style={{ width: 'initial' }}>{openingDate}</LineContent>
      </Line>
    )
  }

  const renderCadastralSituationDate = () => {
    const date = membershipBoardService.cadastral_situation_date
    return date && (
      <Line>
        <LineTitle>Data da Situação Cadastral</LineTitle>
        <LineContent style={{ width: 'initial', display: 'flex' }}>
          <span>{date}</span>
        </LineContent>
      </Line>
    )
  }

  const renderFantasyNames = () => {
    return !_.isEmpty(membershipBoardService.fantasy_names) && (
      <Line>
        <LineTitle>Nomes Fantasia</LineTitle>
        <div>
          {
            _.map(membershipBoardService.fantasy_names, name => {
              return (
                <LineContent key={name} style={{ width: 'initial', display: 'flex' }}>
                  <div className={classes.panelLine}>
                    {name}
                  </div>
                </LineContent>
              )
            })
          }
        </div>
      </Line>
    )
  }

  const renderPhones = () => {
    const phones = _.get(membershipBoardService, 'phones')
    if (_.isEmpty(phones)) return null

    return (
      <Line>
        <LineTitle>Telefones Associados</LineTitle>
        <div>
          {phones.map(phone => {
            return (
              <LineContent key={phone} style={{ width: 'initial', display: 'flex' }}>
                <span className={classes.panelLine}>
                  {formatToPhone(phone)}
                </span>
              </LineContent>
            )
          })}
        </div>
      </Line>
    )
  }

  const renderLegalNature = () => {
    let legalNature = _.get(membershipBoardService, 'legal_nature')
    if (_.isEmpty(legalNature)) return null

    legalNature = [{
      number: legalNature.number || '',
      description: legalNature.description || '',
    }]

    if (_.isEmpty(legalNature)) return null
    return (
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Natureza Jurídica</TitleBold>
        <DataTable
          data={legalNature}
          columns={columnsLegalNature}
        />
      </div>
    )
  }

  /** Checks if the analysis button column must be rendered. It will not be rendered if:
 * - both planCpf and planCnpj are null
 * - user does not have an plan for a docType and there's only of this type to show.
 *    E.g. user doesn't have acess to a cpf plan but thre's only cpfs in the membershipboard.
 *    So the column will not be shown.
 * @param docTypes array containing each docType of the membershipboard e.g. ['cpf', 'cpf', 'cnpj']
 */
  const hasAnlaysisButtonsToDisplay = (docTypes, panelCpfId, panelCnpjId) => {
    // Spares the whole processment if both are null!
    if (_.isEmpty(panelCnpjId) && (_.isEmpty(panelCpfId))) return false

    if (_.isEmpty(panelCnpjId))
      _.remove(docTypes, (dt) => dt === PANEL_ENVS.CNPJ)
    if (_.isEmpty(panelCpfId))
      _.remove(docTypes, (dt) => dt === PANEL_ENVS.CPF)

    return !(_.isEmpty(docTypes))
  }

  const renderMembershipBoard = () => {
    let membershipBoard = _.get(membershipBoardService, 'membership_board')
    if (_.isEmpty(membershipBoard)) return null

    const membershipDocTypes = _.map(membershipBoard, m =>
      _.isEmpty(m.cpf) ? PANEL_ENVS.CNPJ : PANEL_ENVS.CPF,
    )

    const showAnalysisButton = hasAnlaysisButtonsToDisplay(membershipDocTypes, cpfPlanId, cnpjPlanId)

    membershipBoard = _.map(membershipBoard, m => {
      let docNumber = m.cpf
      let docType = PANEL_ENVS.CPF

      if (!_.isEmpty(docNumber)) {
        docNumber = formatToCPF(docNumber)
      } else if (!_.isEmpty(m.cnpj)) {
        docNumber = formatToCNPJ(m.cnpj)
        docType = PANEL_ENVS.CNPJ
      } else {
        docNumber = ''
        docType = ''
      }

      return {
        name: m.name || '',
        qualification: m.qualification || '',
        doc_number: docNumber,
        start_date: m.start_date || '',
        doc_type: docType,
      }
    })

    return (
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Quadro Societário</TitleBold>
        <DataTable
          data={membershipBoard}
          columns={columnsMembershipBoard(
            {
              apiKey: apiKey,
              userId: userId,
              cpfPlanId: cpfPlanId,
              cnpjPlanId: cnpjPlanId,
              displayAnalysisButton: showAnalysisButton,
            })}
        />
      </div>
    )
  }

  const renderCapitalStock = () => {
    const capital = _.get(membershipBoardService, 'capital_stock')
    if (_.isEmpty(capital)) return null

    return (
      <Line>
        <LineTitle>Capital Social</LineTitle>
        <div>
          <LineContent key={capital} style={{ width: 'initial', display: 'flex' }}>
            <span className={classes.panelLine}>
              {`${capital.currency} ${capital.value}`}
            </span>
          </LineContent>
        </div>
      </Line>
    )
  }

  const renderCNAES = () => {
    const cnaes = _.get(membershipBoardService, 'cnaes')
    if (_.isEmpty(cnaes)) return null

    _.map(cnaes, c => {
      return {
        number: c.number || '',
        description: c.description || '',
      }
    })

    return (
      <div>
        <TitleBold style={{ marginTop: '30px' }}>CNAEs</TitleBold>
        <DataTable
          data={cnaes}
          columns={columnsCnaes}
        />
      </div>
    )
  }

  const renderAddress = () => {
    const address = _.get(membershipBoardService, 'address')
    if (_.isEmpty(address)) return null

    const addresses = [
      {
        address: `${address.public_place || ''}, ${address.number || ''}, ${address.complement || ''}`,
        neighborhood: address.neighborhood || '',
        city_state: `${address.city || ''}, ${address.state || ''}`,
        zipcode: address.zipcode || '',
      },
    ]

    return (
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Endereço</TitleBold>
        <DataTable
          data={addresses}
          columns={columnsAddress}
        />
      </div>
    )
  }

  const renderEnityFederative = () => {
    return membershipBoardService.entity_federative && (
      <Line>
        <LineTitle>Ente Federativo Responsável</LineTitle>
        <LineContent>{membershipBoardService.entity_federative}</LineContent>
      </Line>
    )
  }

  const renderCompanySize = () => {
    return membershipBoardService.company_size && (
      <Line>
        <LineTitle>Porte</LineTitle>
        <LineContent>{membershipBoardService.company_size}</LineContent>
      </Line>
    )
  }

  const renderEmail = () => {
    return membershipBoardService.email && (
      <Line>
        <LineTitle>Email</LineTitle>
        <div>
          <LineContent key={membershipBoardService.email}>{membershipBoardService.email}</LineContent>
        </div>
      </Line>
    )
  }

  const renderSpecialSituation = () => {
    return membershipBoardService.special_situation && (
      <Line>
        <LineTitle>Situação Especial</LineTitle>
        <div>
          <LineContent key={membershipBoardService.special_situation}>{membershipBoardService.special_situation}</LineContent>
        </div>
      </Line>
    )
  }


  const renderSpecialSituationDate = () => {
    return membershipBoardService.special_situation_date && (
      <Line>
        <LineTitle>Data da Situação Especial</LineTitle>
        <div>
          <LineContent key={membershipBoardService.special_situation_date}>{membershipBoardService.special_situation_date}</LineContent>
        </div>
      </Line>
    )
  }


  const renderUnitType = () => {
    return membershipBoardService.unit_type && (
      <Line>
        <LineTitle>Tipo</LineTitle>
        <div>
          <LineContent key={membershipBoardService.unit_type}>{membershipBoardService.unit_type}</LineContent>
        </div>
      </Line>
    )
  }

  return membershipBoardService && (
    <div>
      <TitleBold>{membershipBoardService.name}</TitleBold>
      <div style={{ marginTop: 30 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {renderFantasyNames()}

            {renderCompanySize()}

            {renderUnitType()}

            {renderCadastralSituation()}

            {renderOpeningDate()}

            {renderCadastralSituationDate()}

            {renderSpecialSituation()}

            {renderSpecialSituationDate()}

            {renderEmployeesNumber()}
          </Grid>

          <Grid item xs={12} sm={6}>
            {renderEnityFederative()}

            {renderPhones()}
            {renderEmail()}
            {renderCapitalStock()}
          </Grid>
        </Grid>
      </div>

      {renderAddress()}
      {renderLegalNature()}
      {renderCNAES()}
      { !isMembershipBoardRecursivePresent && renderMembershipBoard()}
    </div>
  )
}

export default CadastralData
