import React from 'react'
import _ from 'lodash'

export const renderItemValue = (value, total) => {
  if (!_.isNumber(value)) return '-'

  const percentage = total !== 0 ? value * 100 / total : 0
  const percentageText = `(${percentage.toFixed(1)}%)`

  return (
    <React.Fragment>
      <span>{value}</span>
      {' '}
      <span className="percentage">{percentageText}</span>
    </React.Fragment>
  )
}
