import React from 'react'
import _ from 'lodash'
import { formatToBRL } from 'brazilian-values'
import { useTheme } from '@material-ui/styles'

import ProcessAccordion from '../ProcessAccordion'
import { renderCheckService } from '../utilsFunctions'

const CreditPersonRestrictions = ({ service }) => {
  const theme = useTheme()

  const elemCheck = renderCheckService(service, theme)
  if (elemCheck !== null) return elemCheck

  if (!_.get(service, 'data.consists')) {
    return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
  }

  const restrictionsData = _.get(service, 'data.credit_restrictions_data')

  const formatBRL = (val) => {
    const newValue = _.isString(val) ? val.replace(',', '.') : val
    return formatToBRL(newValue)
  }

  const renderListItem = (title, value) => (
    <li>
      <span style={{ fontWeight: 'bold' }}>{`${title}: `}</span>
      <span>{value}</span>
    </li>
  )

  const renderProtestsOccurrencesAccordion = (occurrences) => {
    if (_.isEmpty(occurrences)) return null

     const renderOccurrences = () => {
       return _.map(occurrences, (occurrence, index) => {
          return (
            <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }} key={index.toString()}>
              {renderListItem('Valor', formatBRL(occurrence.value))}
              {renderListItem('Endereço', occurrence.address)}
              {renderListItem('UF', occurrence.uf)}
              {renderListItem('Cidade', occurrence.district)}
              {renderListItem('Data', occurrence.date_of_occurrence)}
              {renderListItem('Registro', occurrence.registry)}
              {renderListItem('Credor', occurrence.creditor)}
              {renderListItem('Cedente', occurrence.assignor)}
            </ul>
          )
        })
     }

    return (
      <ProcessAccordion title={`Ocorrências (${occurrences.length}) `}>
        {renderOccurrences()}
      </ProcessAccordion>
    )
  }

  const renderProtestsAccordion = (protests) => {
    if (_.isEmpty(protests)) return null

    const quantityOccurrences = protests.quantity_occurrences
    const totalValue = protests.total_value

    if (_.parseInt(quantityOccurrences) === 0) return null

    return (
      <ProcessAccordion title="Protestos">
        <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }}>
          {renderListItem('Valor Total', formatBRL(totalValue))}
          {renderListItem('Total de Ocorrências', quantityOccurrences)}
          {renderProtestsOccurrencesAccordion(protests.occurrences)}
        </ul>
      </ProcessAccordion>
    )
  }

  const renderFinancialPendingOccurrencesAccordion = (occurrences) => {
    if (_.isEmpty(occurrences)) return null

     const renderOccurrences = () => {
       return _.map(occurrences, (occurrence, index) => {
          return (
            <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }} key={index.toString()}>
              {renderListItem('Valor', formatBRL(occurrence.value))}
              {renderListItem('Moeda', occurrence.currency)}
              {renderListItem('Data de vencimento', occurrence.expiration_date)}
              {renderListItem('Informante', occurrence.informant)}
              {renderListItem('Tipo de devedor', occurrence.debtor_type)}
              {renderListItem('Credor', occurrence.creditor)}
              {renderListItem('Contrato', occurrence.contract)}
              {renderListItem('Origem', occurrence.origin)}
              {renderListItem('Subjudice', occurrence.subjudice)}
              {renderListItem('Modalidade', occurrence.modality)}
              {renderListItem('Entidade', occurrence.entity)}
            </ul>
          )
        })
     }

    return (
      <ProcessAccordion title={`Ocorrências (${occurrences.length}) `}>
        {renderOccurrences()}
      </ProcessAccordion>
    )
  }

  const renderFinancialPendingAccordion = (financialPending) => {
    if (_.isEmpty(financialPending)) return null

    const quantityOccurrences = financialPending.quantity_occurrences
    const totalValue = financialPending.total_value

    if (_.parseInt(quantityOccurrences) === 0) return null

    return (
      <ProcessAccordion title="Pendências Financeiras">
        <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }}>
          {renderListItem('Valor Total', formatBRL(totalValue))}
          {renderListItem('Total de Ocorrências', quantityOccurrences)}
          {renderFinancialPendingOccurrencesAccordion(financialPending.occurrences)}
        </ul>
      </ProcessAccordion>
    )
  }

  const renderBacenBadCheckOccurrencesAccordion = (occurrences) => {
    if (_.isEmpty(occurrences)) return null

     const renderOccurrences = () => {
       return _.map(occurrences, (occurrence, index) => {
          return (
            <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }} key={index.toString()}>
              {renderListItem('DCR banco', occurrence.dcr_bank)}
              {renderListItem('Número do banco', occurrence.bank_number)}
              {renderListItem('Número da agência', occurrence.agency_number)}
              {renderListItem('Motivo da devolução', occurrence.return_reason)}
              {renderListItem('Quantidade de cheques', occurrence.quantity_check)}
              {renderListItem('Data da última ocorrência', occurrence.last_occurrence_date)}
              {renderListItem('Dados da agência', occurrence.agency_data)}
              {renderListItem('Documento relacionado', occurrence.related_document)}
            </ul>
          )
        })
     }

    return (
      <ProcessAccordion title={`Ocorrências (${occurrences.length}) `}>
        {renderOccurrences()}
      </ProcessAccordion>
    )
  }

  const renderBacenBadCheckAccordion = (bacenBadCheck) => {
    if (_.isEmpty(bacenBadCheck)) return null

    const quantityOccurrences = bacenBadCheck.quantity_occurrences

    if (_.parseInt(quantityOccurrences) === 0) return null

    return (
      <ProcessAccordion title="Bacen Cheque Sem Fundo">
        <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }}>
          {renderListItem('Correntista', bacenBadCheck.account_holder)}
          {renderListItem('Total de Ocorrências', quantityOccurrences)}
          {renderBacenBadCheckOccurrencesAccordion(bacenBadCheck.occurrences)}
        </ul>
      </ProcessAccordion>
    )
  }

  const renderCadinOccurrencesAccordion = (occurrences) => {
    if (_.isEmpty(occurrences)) return null

     const renderOccurrences = () => {
       return _.map(occurrences, (occurrence, index) => {
          return (
            <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }} key={index.toString()}>
              {renderListItem('Entidade', occurrence.entity)}
              {renderListItem('Sigla', occurrence.initials)}
              {renderListItem('Quantidade', occurrence.quantity)}
            </ul>
          )
        })
     }

    return (
      <ProcessAccordion title={`Ocorrências (${occurrences.length}) `}>
        {renderOccurrences()}
      </ProcessAccordion>
    )
  }

  const renderCadinAccordion = (cadin) => {
    if (_.isEmpty(cadin)) return null

    const quantityOccurrences = cadin.quantity_occurrences

    if (_.parseInt(quantityOccurrences) === 0) return null

    return (
      <ProcessAccordion title="Cadin">
        <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.4rem' }}>
          {renderListItem('Total de Ocorrências', quantityOccurrences)}
          {renderCadinOccurrencesAccordion(cadin.occurrences)}
        </ul>
      </ProcessAccordion>
    )
  }

  return (
    <React.Fragment>
      <strong style={{ color: theme.palette.primary.red }}>Sim</strong>
      <div style={{ marginTop: 30, marginLeft: 30 }}>
        {renderProtestsAccordion(restrictionsData.synthetic_protest)}
        {renderFinancialPendingAccordion(restrictionsData.financial_pending)}
        {renderBacenBadCheckAccordion(restrictionsData.bacen_bad_check)}
        {renderCadinAccordion(restrictionsData.cadin)}
      </div>
    </React.Fragment>
  )
}

export default CreditPersonRestrictions
