import React from 'react'
import _ from 'lodash'

import CertificateRow from 'ui/components/ContainerCommonPanels/CertificateRow'

const infos = [
  {
    serviceId: 'cvm_administrative_process',
    options: {
      fields: {
        child_physical_process: {
          name: 'Processos Físicos',
          fields: {
            subject: 'Assunto',
            number: 'Número',
            applicant: 'Requerente',
            interested: 'Interessado',
          },
        },
        child_eletronic_process: {
          name: 'Processos Eletrônicos',
          fields: {
            subject: 'Assunto',
            number: 'Número',
            interested: {
              name: 'Interessados',
            },
          },
        },
      },
    },
  },
  {
    serviceId: 'cvm_sanctioning_administrative_process',
    options: {
      fields: {
        child_process: {
          name: 'Processos',
          fields: {
            subject: 'Assunto',
            number: 'Número',
            accused: 'Acusado',
            menu: 'Ementa',
          },
        },
      },
    },
  },
]

const ExpansionPanelCvm = ({ serviceInfos }) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
      {
        _.chain(serviceInfos)
        .sortBy(['name'])
        .map((service) => {
          const info = _.find(infos, { serviceId: service.service_id })

          return (
            <CertificateRow
              key={service.service_id}
              title={service.name}
              service={service}
              otherInfos={info && _.get(service, 'data')}
              options={info && info.options}
            />
          )
        }).value()
      }
    </ul>
  )
}

export default ExpansionPanelCvm
