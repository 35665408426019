import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Pagination, Table } from 'ui/components'
import {
  getExportReportItem,
  getExportReportList,
} from 'services/apiMotorBgc'
import { downloadFromUri } from 'utils/functions'
import { formatDate } from 'utils/masks'
import { REPORT_OF_TYPE } from 'utils/constants/exportReport'

import Container from '../styles'

export default ({ forceUpdate }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector(state => state.auth.panelEnv)

  const [reportList, setReportList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const getListReportExport = async (options = {}) => {
    let res = null
    try {
      res = await getExportReportList(
        apiKey,
        userId,
        panelEnv,
        REPORT_OF_TYPE.DATE,
        {
          exportType: 'CSV',
          page: _.has(options, 'page') ? options.page : currentPage,
        },
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao listar relatorios'
      toast.error(messageError)
      return
    }

    setReportList(res.data.reports)

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
  }

  useEffect(() => {
    getListReportExport()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate])

  const handleChangePage = async (event, page) => {
    const options = {
      page: page,
    }

    getListReportExport(options)
  }

  const handleGetReport = async (reportId, status, fileName) => {
    if (status !== 'PROCESSED') return

    let res = null
    try {
      res = await getExportReportItem(
        apiKey,
        userId,
        panelEnv,
        {
          reportId: reportId,
          exportType: 'CSV',
        },
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao baixar relatorio'
      toast.error(messageError)
      return
    }

    toast.success('Download do relatório iniciado')

    const docUrl = res.data.document_url
    downloadFromUri(docUrl, fileName)
  }

  const renderItemColumns = (item) => {
    return (
      <React.Fragment>
        <td>{item.status}</td>
        <td>{item.initial_date}</td>
        <td>{item.final_date}</td>
        <td>{formatDate(item.created_at)}</td>
      </React.Fragment>
    )
  }

  const renderListItems = () => {
    if (_.isEmpty(reportList)) {
      return <tr><td colSpan={4}>Nenhum relatório encontrado.</td></tr>
    }

    return _.map(reportList, (item) => (
      <tr
        key={item.report_id}
        onClick={() => handleGetReport(item.report_id, item.status, item.file_name)}
      >
        {renderItemColumns(item)}
      </tr>
    ))
  }

  const renderTable = () => (
    <Table className="table-container">
      <thead>
        <tr>
          <th>Status</th>
          <th>De</th>
          <th>Ate</th>
          <th>Data do Pedido</th>
        </tr>
      </thead>
      <tbody>
        {renderListItems()}
      </tbody>
    </Table>
  )

  const renderPagination = () => {
    return (
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChangePage}
      />
    )
  }

  return (
    <Container>
      {renderTable()}
      {renderPagination()}
    </Container>
  )
}
