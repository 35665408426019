import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import { Checkbox, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import {
  additionalCostGroupText,
  govBrRequiredServicesText,
  manualInputGroupText,
} from 'utils/constants/planSpecialServices'
import LightTooltip from '../LightTooltip'

import styles from './styles'

const Accordion = withStyles({
  root: {
    borderTop: '1px solid #cecece',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    borderRadius: 15,
  },
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    display: 'flex',
  },
})(MuiAccordionSummary)

const handleClickCheckbox = (e, onClickCheckbox) => {
  if (e.stopPropagation) e.stopPropagation()
  if (onClickCheckbox) onClickCheckbox(e)
}

export default function PlanAccordion({
  title,
  servicesSelected,
  hasAdditionalCostService,
  hasManualInputService,
  hasGovBrRequiredService,
  servicesLength,
  onClickCheckbox,
  children,
  checkboxDisabled,
}) {
  const classes = styles()

  const setCheckBoxTooltipTitle = () => {
    if (hasAdditionalCostService) return additionalCostGroupText
    if (hasManualInputService) return manualInputGroupText
    if (hasGovBrRequiredService) return govBrRequiredServicesText

    return '' // Tooltip não será mostrado
  }

  const checkBoxIndeterminate = servicesSelected > 0 && servicesSelected < servicesLength

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={1}>
            <LightTooltip
              title={setCheckBoxTooltipTitle()}
              placement='bottom-start'
            >
              <Checkbox
                value="checkedB"
                color={checkBoxIndeterminate ? 'default' : 'secondary'}
                indeterminate={checkBoxIndeterminate}
                checked={servicesSelected > 0}
                disabled={checkboxDisabled}
                style={{ verticalAlign: 'middle', alignItems: 'center', fontSize: '1.2rem' }}
                onChange={e => handleClickCheckbox(e, onClickCheckbox)}
                onClick={e => e.stopPropagation()}
              />
            </LightTooltip>
          </Grid>

          <Grid
            style={{ display: "flex", flexDirection: 'column' }}
            item
            xs={5}
            container
          >
            <Grid item style={{ backgroundColor: 'white' }}>
              <Typography className={classes.heading}>
                {title}
              </Typography>
            </Grid>
            <Grid item style={{ backgroundColor: 'white' }}>
              <Typography className={classes.secondaryHeading}>{`${servicesSelected} de ${servicesLength} serviços selecionados`}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>

        {children}
      </Accordion>
    </div>
  )
}
