import React from 'react'
import _ from 'lodash'

import { useTheme } from '@material-ui/styles'
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'

import serviceStatusConstants from 'utils/constants/serviceStatus'
import { POLE_TYPE, POLE_TYPE_TRANSLATED } from 'utils/constants/poleTypes'
import analysisStatusConstants from 'utils/constants/analysisStatus'
import { s3PresignerUrlOpen } from 'utils/functions'

import { renderCheckService } from '../utilsFunctions'
import ProcessAccordion from '../ProcessAccordion'

import styles from './styles'

const CertificateRow = ({
  title,
  service,
  icon = true,
  otherInfos,
  options,
  customCertificateRenderer,
}) => {
  const classes = styles()
  const theme = useTheme()

  const docUrl = _.get(service, 'data.document_url')
  const msgErrors = _.get(service, 'data.msg_errors')
  const msgInfos = _.get(service, 'data.msg_infos')
  const slowProcessing = _.get(service, 'slow_processing')
  const status = _.get(service, 'status')
  const analysisStatus = _.get(service, 'analysis_status')

  const getColor = () => {
    switch (status) {
      case serviceStatusConstants.SERVICE_STATUS.processing:
        return slowProcessing === true ? theme.palette.primary.orange : theme.palette.primary.grayDark
      // case serviceStatusConstants.SERVICE_STATUS.error:
      //   return theme.palette.primary.red
      case serviceStatusConstants.SERVICE_STATUS.processed: {
        if (analysisStatus === analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved)
          return theme.palette.primary.red
        // if (analysisStatus === analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention)
        //   return theme.palette.primary.inAttention
        if (!_.isEmpty(msgErrors)) return theme.palette.primary.grayLight2
        if (!_.isEmpty(msgInfos)) return theme.palette.primary.grayLight2

        return theme.palette.primary.green
      }
      default:
        return theme.palette.primary.grayLight
    }
  }

  const renderMaxItemsText = (maxItems, arrLength) => {
    if (!_.isInteger(maxItems)) return null
    if (maxItems >= arrLength) return null

    return (
      <p>{`Exibindo os ${maxItems} primeiros items`}</p>
    )
  }

  const renderTitle = () => {
    return (
      <span className={classes.title} style={{ color: getColor() }}>
        {title}
      </span>
    )
  }

  const renderDescription = () => {
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    if (_.isEmpty(analysisStatus)) return null

    let text = 'Existe(m) ocorrência(s)'
    if (analysisStatus === analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved)
      text = 'Nenhuma ocorrência'

    return <span className={classes.description} style={{ color: getColor() }}>{text}</span>
  }

  const renderOtherInfos = () => {
    // If a custom renderer function is specified calls it here after accordion being rendered
    if (customCertificateRenderer) {
      return customCertificateRenderer(service, otherInfos, options)
    }

    if (
      _
        .chain(otherInfos)
        .pick(_.keys(_.get(options, 'fields')))
        .every(_.isEmpty)
        .value()
    ) return null

    const renderFields = (item, optFields) => {
      return _.map(optFields, (val, key) => (
        <div key={key}>
          {renderField(item, val, key)}
        </div>
      ))
    }

    const translatePoleType = (kind) => {
      switch (kind) {
        case POLE_TYPE.ACTIVE:
          return POLE_TYPE_TRANSLATED.ACTIVE
        case POLE_TYPE.PASSIVE:
          return POLE_TYPE_TRANSLATED.PASSIVE
        case POLE_TYPE.OTHER:
          return POLE_TYPE_TRANSLATED.OTHER
        case POLE_TYPE.UNDEFINED:
          return POLE_TYPE_TRANSLATED.UNDEFINED
        default:
          return kind
      }
    }

    const renderField = (item, fieldVal, fieldKey) => {
      let content = _.get(item, fieldKey)

      if (fieldKey === 'kind' && fieldVal === 'Polo') {
        content = translatePoleType(content)
      }

      if (_.isString(content) || _.isBoolean(content)) {
        return (
          <React.Fragment>
            <strong>{fieldVal}</strong>
            {': '}
            {_.isString(content) ? content : content === true ? 'Sim' : 'Não'}
          </React.Fragment>
        )
      }

      if (_.isArray(content)) {
        const contentLength = content.length
        const newContent = fieldVal.max_items ? _.take(content, fieldVal.max_items) : content

        return (
          <React.Fragment>
            <strong>{`${fieldVal.name} (${contentLength})`}</strong>
            {renderMaxItemsText(fieldVal.max_items, contentLength)}

            {_.map(newContent, (itemChild, i) => (
              <ul key={i.toString()}>
                <li>
                  {
                    _.isString(itemChild)
                      ? itemChild
                      : renderFields(itemChild, fieldVal.fields)
                  }
                </li>
              </ul>
            ))}
          </React.Fragment>
        )
      }

      return null
    }

    const renderAccordionContent = (info, fieldVal) => {
      const newInfo = fieldVal.max_items ? _.take(info, fieldVal.max_items) : info

      return (
        <div>
          {renderMaxItemsText(fieldVal.max_items, info.length)}

          {_.map(newInfo, (item, i) => (
            <div
              key={i.toString()}
              style={{ paddingBottom: 10, paddingTop: 10, borderBottom: '1px solid #ddd' }}
            >
              {renderFields(item, fieldVal.fields)}
            </div>
          ))}
        </div>
      )
    }

    return (
      <React.Fragment>
        {_.map(options.fields, (fieldVal, fieldKey) => {
          const info = _.get(otherInfos, fieldKey)

          return (
            <ProcessAccordion
              key={fieldKey}
              title={`${fieldVal.name} ${_.isArray(info) ? `(${info.length})` : ''}`}
            >
              {renderAccordionContent(info, fieldVal)}
            </ProcessAccordion>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ListItem className={classes.certiLine} style={{ color: getColor() }}>
        {icon && (
          <ListItemAvatar>
            <InsertDriveFileOutlinedIcon style={{ color: getColor() }} fontSize="large" />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={renderTitle()}
          secondary={renderDescription()}
        />
        <ListItemSecondaryAction>
          {!_.isEmpty(docUrl) && (
            <IconButton edge="end" onClick={() => s3PresignerUrlOpen(docUrl)}>
              <GetAppOutlinedIcon style={{ color: getColor() }} fontSize="large" />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {renderOtherInfos()}
    </React.Fragment>
  )
}

export default CertificateRow
