import React from 'react'
import _ from 'lodash'
import { useTheme } from '@material-ui/styles'

import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import { renderCheckService } from 'ui/components/ContainerCommonPanels/utilsFunctions'

import { columns } from './configDataTable'

const LegalPersonAssumedBilling = ({ service }) => {
  const theme = useTheme()

  const elemCheck = renderCheckService(service, theme)
  if (elemCheck !== null) return elemCheck

  const data = _.get(service, 'data.results')

  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
      />
    </div>
  )
}

export default LegalPersonAssumedBilling
