import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { InputLabel, MenuItem, Select, Typography } from '@material-ui/core'

import {
  Button,
  Modal,
  TextInput,
} from 'ui/components'

import {
  postCreateUser,
} from 'services/apiMotorBgcAdmin'

import messages from 'utils/messages'
import { USER_PERMISSION, translatePermission } from 'utils/constants/user'
import { ORGANIZATION_MASTER } from 'utils/constants/organizationMaster'
import { isPermissionMaster } from 'utils/validators'

import styles from './styles'

const validationSchema = Yup.object({
  email: Yup.string().required(messages.required),
  permission: Yup.string().required(messages.required),
})

const initialValues = {
  email: '',
  permission: USER_PERMISSION.COMMON,
}

export default ({ orgSelected, onCreate = null }) => {
  const classes = styles()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const accessToken = useSelector(state => state.auth.access_token)
  const userPermission = useSelector((state) => state.user.permission)

  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleToggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  const handleUserCreate = async (values) => {
    setLoading(true)

    try {
      await postCreateUser(
        apiKey,
        userId,
        values.email,
        values.permission,
        orgSelected.org_id,
        accessToken,
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao criar usuário'
      toast.error(messageError)
      setLoading(false)
      return
    }

    setLoading(false)
    setModalOpen(false)
    toast.success('Usuário criado com sucesso! Um email foi enviado para ele')

    if (_.isFunction(onCreate)) onCreate()
  }

  const renderMenuItems = () => {
    const common = USER_PERMISSION.COMMON
    const admin = USER_PERMISSION.ADMIN
    const master = USER_PERMISSION.MASTER

    const items = [
      <MenuItem key={common} value={common}>{translatePermission(common)}</MenuItem>,
      <MenuItem key={admin} value={admin}>{translatePermission(admin)}</MenuItem>,
    ]

    if (isPermissionMaster(userPermission) && _.values(ORGANIZATION_MASTER).includes(orgSelected.name)) {
      items.push(<MenuItem key={master} value={master}>{translatePermission(master)}</MenuItem>)
    }

    return items
  }

  const renderPermissionSelect = (formik) => {
    return (
      <React.Fragment>
        <InputLabel>Permissão</InputLabel>
        <Select
          name="permission"
          value={formik.values.permission}
          onChange={formik.handleChange}
          error={formik.touched.permission && Boolean(formik.errors.permission)}
        >
          {renderMenuItems()}
        </Select>
      </React.Fragment>
    )
  }

  const renderEmailInput = () => {
    return (
      <React.Fragment>
        <InputLabel>Email</InputLabel>
        <Field
          name="email"
          type="email"
          component={TextInput}
        />
      </React.Fragment>
    )
  }

  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUserCreate}
      >
        {(form) => {
          return (
            <form>
              {renderEmailInput()}
              <br />
              {renderPermissionSelect(form)}

              <div className={classes.buttonsContainer}>
                <Button onClick={handleCancel} variant="text">
                  Cancelar
                </Button>
                <Button onClick={form.handleSubmit} disabled={loading}>
                  Criar Usuário
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    )
  }

  const renderOrgName = () => {
    const orgName = orgSelected.name
    if (_.isEmpty(orgName)) return null

    return (
      <div className={classes.orgNameContainer}>
        <Typography>
          Organização:
          {' '}
          <strong>{orgName}</strong>
        </Typography>
      </div>
    )
  }

  return (
    <div>
      <Button onClick={handleToggleModal}>
        Criar Usuário
      </Button>

      <Modal
        open={modalOpen}
        onClose={handleToggleModal}
        title="Criar Usuário"
      >
        {renderOrgName()}
        {renderForm()}
      </Modal>
    </div>
  )
}
