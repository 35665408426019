import React from 'react'
import _ from 'lodash'

import HelpOutline from '@material-ui/icons/HelpOutline'
import { Divider, Slider } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import ProcessAccordion from 'ui/components/ContainerCommonPanels/ProcessAccordion'
import LightTooltip from 'ui/components/ContainerCommonPanels/LightTooltip'

import serviceStatusConstants from 'utils/constants/serviceStatus'
import analysisStatusConstants, { getAnalysisStatusMoveToName } from 'utils/constants/analysisStatus'
import { renderCheckService } from 'ui/components/ContainerCommonPanels/utilsFunctions'

import scoreIndicatorsData from './scoreIndicatorsData'

export const renderCreditScore = (service, theme) => {
  const ProgressSlider = withStyles({
    root: {
      color: '#6f8eff',
      height: 8,
    },
    track: {
      color: '#bc2950',
      height: 8,
      borderRadius: 2,
      webKitMask: 'linear-gradient(#fff 0 0)',

    },
    thumb: {
      display: 'none',
    },
  })(Slider)

  const renderInfo = (msgInfos) => {
    return (<span style={{ color: theme.palette.primary.grayLight2 }}>{msgInfos?.at(0)}</span>)
  }

  const getColor = (service) => {
    switch (service.status) {
      case serviceStatusConstants.SERVICE_STATUS.processing:
        return service.slowProcessing === true ? theme.palette.primary.orange : theme.palette.primary.grayDark
      case serviceStatusConstants.SERVICE_STATUS.error:
        return theme.palette.primary.red
      case serviceStatusConstants.SERVICE_STATUS.processed: {
        if (service.analysis_status === analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved)
          return theme.palette.primary.red
        if (service.analysis_status === analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention)
          return theme.palette.primary.inAttention
        if (!_.isEmpty(service.msgErrors)) return theme.palette.primary.grayLight2
        if (!_.isEmpty(service.msgInfos)) return theme.palette.primary.grayLight2

        return theme.palette.primary.green
      }
      default:
        return theme.palette.primary.grayLight
    }
  }

  const riskValues = {
    veryHigh: 300,
    high: 553,
    medium: 725,
    low: 874,
    veryLow: 937,
  }

  const getCreditRisk = (score) => {
    if (score < 553) return { name: 'Muito alto', floor: 300, next: 553, sliderMark: 0 }
    if (score < 725) return { name: 'Alto', floor: 553, next: 725, sliderMark: 200 }
    if (score < 874) return { name: 'Médio', floor: 725, next: 874, sliderMark: 400 }
    if (score < 937) return { name: 'Baixo', floor: 874, next: 937, sliderMark: 600 }

    return { name: 'Muito baixo', floor: 937, next: 1000, sliderMark: 800 }
  }

  const parseScore = (score) => (Number.isNaN(score) ? 0 : parseInt(score, 10))

  const elemCheck = renderCheckService(service, theme)
  if (elemCheck !== null) return elemCheck

  const data = _.get(service, 'data')

  let score = _.get(data, 'score')

  if (_.isNil(score))
    renderInfo(data.msgInfos)

  score = parseScore(score)

  const serviceColor = getColor(service)
  const analysisStatus = getAnalysisStatusMoveToName(service.analysis_status)
  const creditRisk = getCreditRisk(score)

  const renderCreditRiskStatus = () => (
    <div>
      <span style={{ fontWeight: 'bold', color: serviceColor }}>
        {analysisStatus}
      </span>
      <LightTooltip
        title={(
          <span>
            Baseado no nível de Risco de Crédito.
            {!Number.isNaN(score) && <p>Recebeu um score de <strong>{score} pontos</strong></p>}
            Pontuação entre 300 a 1000.
            <br />
            <strong style={{ color: serviceColor }}> {analysisStatus} segundo os parâmetros de score definidos no plano.</strong>
          </span>
        )}
      >
        <HelpOutline style={{ marginLeft: 8, verticalAlign: 'middle', fontSize: '1.9rem' }} />
      </LightTooltip>
    </div>
  )

  const renderCreditRiskSummary = (score, creditRisk) => (
    <div style={{ marginTop: 15 }}>
      <Divider />
      <br />
      <p><strong>Score: </strong>{score} pontos</p>
      <p><strong>Risco de Crédito: </strong> {creditRisk.name}</p>
      <br />
    </div>
  )

  const renderCreditRiskScoreSlider = (score, creditRisk) => {
    const subtitleStyle = (score, riskValue) => (
      {
        flexGrow: 0,
        textAlign: 'left',
        color: score > riskValue ? theme.palette.primary.black : theme.palette.primary.grayLight2
      }
    )

    const findSliderValue = (creditRisk, score) => {
      const relativeUnits = creditRisk.next - creditRisk.floor
      const relativeValue = score - creditRisk.floor
      const percentage = relativeValue / relativeUnits * 100
      return creditRisk.sliderMark + percentage * 200 / 100
    }

    const renderSubtitles = () => (
      <div style={{ justifyContent: 'space-around', flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', fontSize: '1.2rem' }}>
        <div style={subtitleStyle(score, riskValues.veryHigh)}>
          Muito alto
        </div>
        <div style={subtitleStyle(score, riskValues.high)}>
          Alto
        </div>
        <div style={subtitleStyle(score, riskValues.medium)}>
          Médio
        </div>
        <div style={subtitleStyle(score, riskValues.low)}>
          Baixo
        </div>
        <div style={subtitleStyle(score, riskValues.veryLow)}>
          Muito baixo
        </div>
      </div>

    )

    return (
      <div style={{ paddingRight: 40, paddingLeft: 20, marginTop: 10 }}>
        {renderSubtitles()}

        <ProgressSlider
          style={{ cursor: 'default', fontSize: '1rem' }}
          value={findSliderValue(creditRisk, score)}
          min={0}
          max={1000}
          color="secondary"
          marks={
            [
              { label: riskValues.veryHigh, value: 0 },
              { label: riskValues.high, value: 200 },
              { label: riskValues.medium, value: 400 },
              { label: riskValues.low, value: 600 },
              { label: riskValues.veryLow, value: 800 },
              { label: 1000, value: 1000 },
            ]
          }
        >
        </ProgressSlider>
      </div>
    )
  }

  const renderPointsOfInterest = (data) => (
    <div>
      <ProcessAccordion title='Pontos de Atenção' style={{ marginLeft: 0, marginTop: 20, fontSize: 12 }} >
        {_.map(data.score_reasons, (detail) => (
          (
            <div key={`div${detail}`} style={{ fontSize: '1.6rem' }}>
              <ul>
                <li key={`ul${detail}`}><span>{detail}</span></li>
              </ul>
            </div>
          )
        ))}
      </ProcessAccordion>
    </div>
  )

  const renderScoreIndicators = (data) => {
    const scoreIndicatorColor = (indicator) => {
      if (Number.isNaN(indicator.value)) return theme.palette.primary.grayLight2

      if (!indicator.description || !scoreIndicatorsData[indicator.description])
        return theme.palette.primary.grayLight2

      if (scoreIndicatorsData[indicator.description].reverse) {
        return indicator.value > 50 ? theme.palette.primary.red : theme.palette.primary.green
      }

      return indicator.value > 50 ? theme.palette.primary.green : theme.palette.primary.red
    }

    return (
      <div style={{ marginTop: 20 }}>
        <ProcessAccordion title='Indicadores de Negócio'>
          {_.map(data.score_indicators, (indicator) => {
            // Evita que o código quebre, caso alguma chave não seja encontrada no retorno do serviço
            if (!_.has(scoreIndicatorsData, indicator.description)) return null

            return (
              <div key={`div${indicator.description}`} style={{ fontSize: '1.5rem' }}>
                <ul>
                  <li key={`ul${indicator.description}`}>
                    <div>
                      <span>{`${scoreIndicatorsData[indicator.description].title}: `}</span>
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: scoreIndicatorColor(indicator),
                        }}
                      >
                        {`${indicator.value}${!Number.isNaN(indicator.value) && '%'}`}
                      </span>

                      <LightTooltip title={scoreIndicatorsData[indicator.description].description}>
                        <HelpOutline style={{ marginLeft: 8, verticalAlign: 'middle', fontSize: '1.8rem' }} />
                      </LightTooltip>
                    </div>
                  </li>
                </ul>
              </div>
            )
          })}
        </ProcessAccordion>
      </div>
    )
  }

  const renderPaymentPunctuality = (score) => {
    let color
    const palette = theme.palette.primary

    if (Number.isNaN(score))
      color = palette.grayLight2
    else
      color = score >= 50 ? palette.green : palette.red

    return (
      <div style={{ marginTop: 20 }}>
        <span>Pontualidade de Pagamento:</span>
        <strong style={{ color: color }}>{!Number.isNaN(score) ? ` ${score}%` : score}</strong>
        <LightTooltip title='Uma porcentagem maior representa menos atrasos de pagamentos ou pendências financeiras na média dos últimos 12 meses.'>
          <HelpOutline style={{ marginLeft: 8, verticalAlign: 'top', fontSize: '1.9rem' }} />
        </LightTooltip>
      </div>
    )
  }

  const hasMoreInfo = !_.isEmpty(data.score_reasons) || !_.isEmpty(data.score_indicators)

  return (
    <React.Fragment>
      <div>
        {renderCreditRiskStatus(analysisStatus, score)}

        {renderCreditRiskSummary(score, creditRisk)}

        {renderCreditRiskScoreSlider(score, creditRisk)}

        {data.payment_punctuality && renderPaymentPunctuality(data.payment_punctuality)}

        {hasMoreInfo && (
          <ProcessAccordion title="Mais Informações">
            <div>
              {!_.isEmpty(data.score_reasons) && renderPointsOfInterest(data)}

              {!_.isEmpty(data.score_indicators) && renderScoreIndicators(data)}
            </div>
          </ProcessAccordion>
        )}
      </div>
    </React.Fragment>
  )
}
