import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  dialogTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    margin: 0,
    padding: 0,
    minHeight: 65,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  containerTitle: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    fontSize: '1.5rem',
    textTransform: 'uppercase',
  },
  dialogContentContainer: {
    padding: theme.spacing(2),
    margin: 0,
  },
  closeButton: {
    color: theme.palette.grey[500],
    marginLeft: 20,
  },
}))
