import React from 'react'
import { useSelector } from 'react-redux'

import Restrictions from 'ui/components/plan/Restrictions'

import { PANEL_ENVS } from 'utils/constants/environments'

export default function () {
  const userOrgId = useSelector((state) => state.user.org_id)

  const renderRestrictions = () => {
    return <Restrictions docType={PANEL_ENVS.CNPJ} orgId={userOrgId} />
  }

  return (
    renderRestrictions()
  )
}
