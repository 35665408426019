export default {
  textLabels: {
    body: {
      noMatch: 'Sem dados disponíveis.',
      toolTip: 'Ordenar',
      columnHeaderTooltip: column => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: 'Próxima página',
      previous: 'Página anterior',
      rowsPerPage: 'Linhas por página:',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Busca',
      downloadCsv: 'Download CSV',
      print: 'Print',
      viewColumns: 'Exibir colunas',
      filterTable: 'Filtrar tabela',
    },
    filter: {
      all: 'TUDO',
      title: 'FILTROS',
      reset: 'RESETAR',
    },
    viewColumns: {
      title: 'Exibir Colunas',
      titleAria: 'Exibir/Esconder colunas da table',
    },
    selectedRows: {
      text: 'linha(s) Selecionadas',
      delete: 'Deletar',
      deleteAria: 'Deletar Linhas Selecionadas',
    },
  },
  pagination: false,
  filterType: 'checkbox',
  selectableRows: 'none',
  elevation: 0,
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  rowsPerPageOptions: [],
}
