import { combineReducers } from 'redux'

import { authReducer } from './auth/reducer'
import { planCnpjReducer } from './planCnpj/reducer'
import { planCnpjServicesReducer } from './planCnpjServices/reducer'
import { planCpfReducer } from './planCpf/reducer'
import { planCpfServicesReducer } from './planCpfServices/reducer'
import { userReducer } from './user/reducer'

const appReducer = combineReducers({
  auth: authReducer,
  planCnpj: planCnpjReducer,
  planCnpjServices: planCnpjServicesReducer,
  planCpf: planCpfReducer,
  planCpfServices: planCpfServicesReducer,
  user: userReducer,
})

export const Reducers = (state, action) => {
  // reset store in logout
  if (action.type === 'LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}
