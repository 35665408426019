import { toast } from 'react-toastify'
import _ from 'lodash'

import {
  deleteManualAnalysisRemoveUser,
  getManualAnalysis,
  postManualAnalysisCreateAnnotation,
  putManualAnalysisSetUser,
} from 'services/apiMotorBv'

const putSetUser = async (apiKey, userId, analysisId) => {
  try {
    await putManualAnalysisSetUser(
      apiKey,
      userId,
      analysisId,
    )
  } catch (err) {
    const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao iniciar analise'
    toast.error(messageError)
    return false
  }

  return true
}

const removeUser = async (apiKey, userId, analysisId) => {
  try {
    await deleteManualAnalysisRemoveUser(
      apiKey,
      userId,
      analysisId,
    )
  } catch (err) {
    const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao cancelar analise'
    toast.error(messageError)
    return false
  }

  return true
}

 const postCreateAnnotation = async (apiKey, userId, analysisId, analysisStatus, description) => {
   try {
     await postManualAnalysisCreateAnnotation(
       apiKey,
       userId,
       analysisId,
       analysisStatus,
       description,
     )
   } catch (err) {
     const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao enviar analise'
     toast.error(messageError)
     return false
   }

   return true
 }

const getAnalysis = async (apiKey, userId, analysisId) => {
  let res = null
  try {
    res = await getManualAnalysis(
      apiKey,
      userId,
      analysisId,
    )
  } catch (err) {
    const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter analise'
    toast.error(messageError)
    return null
  }

  return res.data
}

export {
  getAnalysis,
  postCreateAnnotation,
  putSetUser,
  removeUser,
}
