import React from 'react'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import Container from './styles'

export default ({ value, ...rest }) => {
  const newValue = parseInt(value, 10)

  return (
    <Container>
      <CircularProgressbar
        // className={(value <= 25) ? 'red' : (value <= 50) ? 'yellow' : 'red'}
        value={newValue}
        maxValue={100}
        text={`${newValue}%`}
        styles={buildStyles({ pathTransitionDuration: 0.5 })}
      />
    </Container>
  )
}
