import React from 'react'
import { toast } from 'react-toastify'

import { postCreateDoc } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'
import { extractOnlyNumbers } from 'utils/functions'
import { isDocNumberValid } from 'utils/validators'

const PanelLink = (docType) => (
  <div>
    <a href={`/${docType}/processing`} target="_blank" rel="noopener noreferrer">
      {`Abrir Painel de ${docType.toUpperCase()}s em processamento`}
    </a>
  </div>
)

export default async function createAnalysis({ apiKey, userId, _planId, docType, docNumber }) {
  const docNumberDigits = extractOnlyNumbers(docNumber)

  if (!isDocNumberValid(docNumberDigits)) {
    toast.error(`${docType} inválido!`)
    return
  }

  try {
    await postCreateDoc(apiKey, userId, docType, docNumberDigits)
  } catch (err) {
    reportError(err, 'Erro ao criar solicitação')
    return
  }

  toast.success(`Solicitação criada com sucesso! ${docType.toUpperCase()} adicionado à fila de processamento.`)
  toast.info(() => PanelLink(docType))
}
