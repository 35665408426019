import { makeStyles } from '@material-ui/styles'

export default makeStyles(_ => ({
  buttonsContainer: {
    marginTop: 30,
  },
  orgNameContainer: {
    marginBottom: 30,
  },
}))
