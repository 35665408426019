import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { CircularProgress, TextField } from '@material-ui/core'

import { Button, DialogConfirm } from 'ui/components'
import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import ButtonUserCreate from 'ui/components/ButtonUserCreate'
import ModalSelectOrganization from 'ui/components/ModalSelectOrganization'

import { getUsers, putAdminUser } from 'services/apiMotorBgcAdmin'
import reportError from 'utils/errorReporter'

import { columnsUsers } from './configDataTable'

import styles from './styles'

export default function () {
  const classes = styles()

  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)
  const accessToken = useSelector(state => state.auth.access_token)
  const userOrgId = useSelector((state) => state.user.org_id)
  const myUserEmail = useSelector((state) => state.user.email)

  const orgDefault = { org_id: userOrgId }

  const [loading, setLoading] = useState(false)
  const [searchEmail, setSearchEmail] = useState('')
  const [users, setUsers] = useState([])
  const [pageData, setPageData] = useState({ current: 1, totalItems: 0, totalPages: 0, perPage: 0 })
  const [orgSelected, setOrgSelected] = useState(orgDefault)

  const [userToEdit, setUserToEdit] = useState(null)
  const [dialogChangeStatusUserOpen, setDialogChangeStatusUserOpen] = useState(false)
  const [changeStatusUserLoading, setChangeStatusUserLoading] = useState(false)

  const putUserActived = async (options = {}) => {
    setChangeStatusUserLoading(true)

    try {
      await putAdminUser(
        apiKey,
        userId,
        accessToken,
        {
          userEmailToUpdate: options.userEmailToUpdate,
          userData: options.data,
        },
      )

      toast.success('O usuário foi alterado')
    } catch (err) {
      reportError(err, 'Ocorreu um erro ao alterar o usuário')
    }

    setChangeStatusUserLoading(false)
  }

  const fetchUsers = async (orgId, options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getUsers(
        apiKey,
        userId,
        orgId,
        {
          email: options.email,
          page: _.has(options, 'page') ? options.page : pageData.current,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao obter usuários')
      setLoading(false)
      return
    }

    const current = parseInt(res.headers['x-page'], 10)
    const totalItems = parseInt(res.headers['x-total'], 10)
    const perPage = parseInt(res.headers['x-per-page'], 10)
    const totalPages =  Math.ceil(totalItems / perPage)
    setPageData({ current: current, totalPages: totalPages, totalItems: totalItems, perPage: perPage })
    setUsers(res.data.users)
    setLoading(false)
  }

  useEffect(() => {
    fetchUsers(orgSelected.org_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSelected])

  const handleSelectOrganization = (org) => {
    if (_.isEmpty(org)) {
      setOrgSelected(orgDefault)
      return
    }
    setOrgSelected(org)
  }

  const handleUserCreate = () => {
    setSearchEmail('')
    fetchUsers(orgSelected.org_id)
  }

  const handleSearchEmail = () => {
    fetchUsers(orgSelected.org_id, { email: searchEmail, page: 1 })
  }

  const handleChangePage = (number) => {
    fetchUsers(orgSelected.org_id, { email: searchEmail, page: number + 1 })
  }

  const handleOpenDialogChangeStatusUser = (event, user) => {
    event.stopPropagation()

    setUserToEdit(user)
    setDialogChangeStatusUserOpen(true)
  }

  const handleChangeStatusUserCancel  = async (event) => {
    event.stopPropagation()
    setDialogChangeStatusUserOpen(false)
    setUserToEdit(null)
  }

  const handleChangeStatusUserConfirm  = async (event, user) => {
    event.stopPropagation()

    await putUserActived({
      userEmailToUpdate: user.email,
      data: {
        actived: !user.actived,
      },
    })
    fetchUsers(orgSelected.org_id)

    setDialogChangeStatusUserOpen(false)
    setUserToEdit(null)
  }

  const textUserStatus = (user) => (
    user.actived ? 'Desativar' : 'Ativar'
  )

  const renderButtonChangeStatusUser = (user) => {
    if (myUserEmail === user.email) return null

    return (
      <Button
        variant="outlined"
        size="small"
        onClick={(e) => handleOpenDialogChangeStatusUser(e, user)}
      >
        {textUserStatus(user)}
      </Button>
    )
  }

  const usersWithAction = () => {
    return _.map(users, (user) => {
      const newUser = _.cloneDeep(user)
      newUser.action = (
        <div>
          {renderButtonChangeStatusUser(user)}
        </div>
      )

      return newUser
    })
  }

  const renderUsers = () => {
    if (loading) return <CircularProgress />

    return (
      <DataTable
        columns={columnsUsers}
        data={usersWithAction()}
        options={{
          pagination: true,
          serverSide: true,
          rowsPerPage: pageData.perPage,
          count: pageData.totalItems,
          page: pageData.current - 1,
          sort: false,
          onChangePage: handleChangePage,
        }}
      />
    )
  }

  const renderDialogChangeStatusUser = () => {
    if (_.isEmpty(userToEdit)) return null

    return (
      <DialogConfirm
        open={dialogChangeStatusUserOpen}
        onConfirm={(e) => handleChangeStatusUserConfirm(e, userToEdit)}
        onCancel={(e) => handleChangeStatusUserCancel(e)}
        title={textUserStatus(userToEdit)}
        content={`Tem certeza que deseja ${textUserStatus(userToEdit)}: "${userToEdit.email}"?`}
        btnConfirmText={textUserStatus(userToEdit)}
        btnConfirmDisabled={changeStatusUserLoading}
      />
    )
  }

  const renderModalSelectOrganization = () => {
    return (
      <div className={classes.buttonsSelectOrg}>
        <ModalSelectOrganization onSelect={handleSelectOrganization} />
      </div>
    )
  }

  const renderButtonUserCreate = () => {
    return (
      <div className={classes.buttonsUserCreate}>
        <ButtonUserCreate orgSelected={orgSelected} onCreate={handleUserCreate} />
      </div>
    )
  }

  const renderFilterEmail = () => {
    return (
      <div className={classes.buttonFilterEmail}>
        <TextField label="Email" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
        <Button variant="text" onClick={handleSearchEmail}>Filtrar</Button>
      </div>
    )
  }

  return (
    <div>
      <div className={classes.buttonsContainer}>
        {renderModalSelectOrganization()}
        {renderButtonUserCreate()}
      </div>
      {renderFilterEmail()}
      {renderUsers()}

      {renderDialogChangeStatusUser()}
    </div>
  )
}
