import React from 'react'
import { useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'

import { TextInput, Button } from 'ui/components'

import messages from 'utils/messages'

import Container from './styles'

const validationSchema = Yup.object({
  docNumber: Yup.string().required(messages.required),
})

const initialValues = {
  docNumber: '',
}

export default ({ btnMainText, btnConfirmText, onConfirm, onCancel }) => {
  const panelEnv = useSelector(state => state.auth.panelEnv)

  const [formShow, setFormShow] = React.useState(false)

  const handleCancel = () => {
    setFormShow(false)
    if (onCancel) onCancel()
  }

  const handleSubmit = (values) => {
    setFormShow(false)
    if (onConfirm) onConfirm(values)
  }

  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(form) => {
          return (
            <form onSubmit={form.handleSubmit}>
              <Field
                name="docNumber"
                label={`${panelEnv.toUpperCase()} *`}
                mask={panelEnv}
                component={TextInput}
              />
              <div className="buttons-container">
                <Button
                  onClick={handleCancel}
                  variant="text"
                >
                  Cancelar
                </Button>
                <Button type="submit">
                  {btnConfirmText}
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    )
  }

  return (
    <Container>
      {!formShow ? (
        <Button
          className="primary button-request"
          onClick={() => setFormShow(true)}
        >
          {btnMainText}
        </Button>
      ) : (
        renderForm()
      )}
    </Container>
  )
}
