import { formatToCNPJ, formatToCPF, isCNPJ, isCPF } from 'brazilian-values'

const customBodyRender = (value) => {
  if (isCPF(value)) return formatToCPF(value)
  if (isCNPJ(value)) return formatToCNPJ(value)

  return value
}

export const columnsCpfs = [
  {
    name: 'cpf',
    label: 'CPF',
    options: {
      customBodyRender: customBodyRender,
    },
  },
]

export const columnsCnpjs = [
  {
    name: 'cnpj',
    label: 'CNPJ',
    options: {
      customBodyRender: customBodyRender,
    },
  },
]
