import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'
import { MANUAL_DERIVATION_STATUS } from 'utils/constants/manualDerivationStatus'
import { translateRole, USER_ROLE } from 'utils/constants/user'

export default function ({ location }) {
  const userRole = USER_ROLE.ANALYST
  const title = `Na fila para ${translateRole(userRole)} / Apenas PEP`
  const subtitle = `Consultas em analise manual com apenas PEP`
  const color = '#ddd'
  const manualDerivationStatus = MANUAL_DERIVATION_STATUS.IN_QUEUE
  const withManualAnalysis = true
  const itemWithManualAnalysis = true
  const hasPep = true
  const hasProcesses = false

  return (
    <BaseConsultsList
      location={location}
      title={title}
      subtitle={subtitle}
      color={color}
      manualDerivationStatus={manualDerivationStatus}
      manualDerivationToRole={userRole}
      withManualAnalysis={withManualAnalysis}
      itemWithManualAnalysis={itemWithManualAnalysis}
      hasPep={hasPep}
      hasProcesses={hasProcesses}
    />
  )
}
