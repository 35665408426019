import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(() => ({
  noticeTypeContainer: {
    marginTop: 10,
  },
  noticeTypeTitle: {
    fontSize: 18,
  },
  noticeContainer: {
    marginTop: 5,
    marginLeft: 15,
  },
  noticeHrSeparator: {
    opacity: 0.3,
  },
  totalNoticesContainer: {
    marginTop: 5,
  },
}))

export default styles
