import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'

import DateRangeIcon from '@material-ui/icons/DateRange'
import { Button, Modal } from 'ui/components'
import { toast } from 'react-toastify'

import Container, { ContainerModal } from './styles'

export default ({ onHandleFilter, initialDateDefault, finalDateDefault }) => {
  const [modalOpen, setModalOpen ] = useState(false)
  const [initialDate, setInitialDate] = useState(initialDateDefault)
  const [finalDate, setFinalDate] = useState(finalDateDefault)

  const getDateOnPTBRFormat = (date) => {
    if (_.isEmpty(date)) return '__/__/____'

    return date.toDate().toLocaleDateString("pt-BR")
  }

  const verifyInterval = () => {
    if (initialDate > finalDate) {
      toast.error('Erro! A data inicial deve ser menor ou igual a final.')
      return false
    }
    if (finalDate > Date.now()) {
      toast.error('Erro! O intervalo não pode terminar depois do dia atual.')
      return false
    }
    return true
  }

  const handleToggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleFilter = () => {
    if (!verifyInterval()) return

    let newInitialDate = initialDate
    let newFinalDate = finalDate
    if (_.isEmpty(newInitialDate)) {
      newInitialDate = moment()
      setInitialDate(newInitialDate)
    }

    if (_.isEmpty(newFinalDate)) {
      newFinalDate = moment()
      setFinalDate(newFinalDate)
    }

    onHandleFilter(newInitialDate, newFinalDate)
    setModalOpen(false)
  }

  const handleFilterClear = () => {
    setInitialDate(null)
    setFinalDate(null)
    onHandleFilter(null, null)
    setModalOpen(false)
  }

  return (
    <Container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="filter-container">
          <div className="text-container" onClick={() => handleToggleModal()}>
            <DateRangeIcon />
            <div>{getDateOnPTBRFormat(initialDate)}</div>
            <div>-</div>
            <div>{getDateOnPTBRFormat(finalDate)}</div>
          </div>
        </div>

        <Modal
          open={modalOpen}
          onClose={handleToggleModal}
          title="Data inicial / Data final"
        >
          <ContainerModal>
            <div className="date-container">
              <DatePicker
                autoOk
                orientation="landscape"
                variant="static"
                openTo="date"
                value={initialDate}
                onChange={(date) => setInitialDate(moment(date))}
                minDate={new Date(2015, 1, 1)}
                maxDate={finalDate}
              />

              <DatePicker
                autoOk
                orientation="landscape"
                variant="static"
                openTo="date"
                value={finalDate}
                onChange={(date) => setFinalDate(moment(date))}
                minDate={initialDate}
                maxDate={new Date()}
              />
            </div>

            <div className="date-container-btn">
              <Button onClick={handleFilter} variant="text" className="btn-date-filter">
                Filtrar
              </Button>

              <Button onClick={handleFilterClear} variant="text">
                Limpar
              </Button>
            </div>
          </ContainerModal>
        </Modal>
      </MuiPickersUtilsProvider>
    </Container>
  )
}
