import _ from 'lodash'
import { toast } from 'react-toastify'

/** Shows to the user a toast with error. Logs the stacktrace on console if running on dev environment.
 * If a custom message is omitted, shows the exception message on the toast.
 * @err the exception object
 * @userMessage  alternate message if the exception doesn't have one
 * @exceptionMessagePath the path where the message is located in the exception object
 */
const reportError = (err, userMessage, exceptionMessagePath = 'response.data.msg_errors.0.msg') => {
  const messageError = _.get(err, exceptionMessagePath) || userMessage
  if (process.env.NODE_ENV === 'development') {
    console.error(messageError)
    console.error(err)
  }
  toast.error(messageError)
}

export default reportError
