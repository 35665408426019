import { formatDate } from 'utils/masks'
import { translatePermission } from 'utils/constants/user'
import { translateBoolean } from 'utils/functions'

const customPermissionRender = (value) => {
  return translatePermission(value)
}

const customCreatedAtRender = (value) => {
  return formatDate(value, { hideTime: true })
}

const customActivedRender = (value) => {
  return translateBoolean(
    value,
    {
      textTrue: 'Ativado',
      textFalse: 'Desativado',
    },
  )
}

export const columnsUsers = [
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'permission',
    label: 'Permissão',
    options: {
      customBodyRender: customPermissionRender,
    },
  },
  {
    name: 'created_at',
    label: 'Criado em',
    options: {
      customBodyRender: customCreatedAtRender,
    },
  },
  {
    name: 'actived',
    label: 'Ativado?',
    options: {
      customBodyRender: customActivedRender,
    },
  },
  {
    name: 'action',
    label: 'Ação',
  },
]
