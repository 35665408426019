import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { getLastGeneratedPlan, postGeneratePlan } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'
import { PANEL_ENVS } from 'utils/constants/environments'

import { selectApiKey, selectUserId } from 'redux/auth/saga.selectors'

import * as actions from './actions'

function* fetchPlanCnpjLast() {
  yield takeEvery(actions.FETCH_PLAN_CNPJ_LAST, function* sg(_action) {
    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    let resLastPlan = null
    try {
      resLastPlan = yield call(getLastGeneratedPlan, apiKey, userId, PANEL_ENVS.CNPJ)
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter o plano'
      yield put({ type: actions.FETCH_PLAN_CNPJ_LAST_FAILED, payload: messageError })

      reportError(err, messageError)
      return
    }

    const { plan_id, services, process_pole } = resLastPlan.data

    yield put({
      type: actions.FETCH_PLAN_CNPJ_LAST_SUCCEEDED,
      payload: {
        plan_id: plan_id,
        services: services,
        process_pole: process_pole,
      },
    })
  })
}

function* createPlanCnpj() {
  yield takeEvery(actions.CREATE_PLAN_CNPJ, function* sg(action) {
    const { processPole, servicesChecked } = action.payload

    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    let res = null
    try {
      res = yield call(postGeneratePlan, apiKey, userId, PANEL_ENVS.CNPJ, servicesChecked, processPole)
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao criar o plano'
      yield put({ type: actions.CREATE_PLAN_CNPJ_FAILED, payload: messageError })

      reportError(err, messageError)
      return
    }

    const { plan_id, services, process_pole } = res.data

    yield put({
      type: actions.CREATE_PLAN_CNPJ_SUCCEEDED,
      payload: {
        plan_id: plan_id,
        services: services,
        process_pole: process_pole,
      },
    })

    yield put(actions.fetchPlanCnpjLast())

    toast.success('Plano atualizado com sucesso.')
  })
}

export default function* rootSaga() {
  yield all([
    fork(createPlanCnpj),
    fork(fetchPlanCnpjLast),
  ])
}
