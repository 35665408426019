import { makeStyles } from '@material-ui/styles'

export default makeStyles(_ => ({
  buttonsContainer: {
    display: 'flex',
  },
  buttonsSelectOrg: {
    alignSelf: 'start',
    flexGrow: 1,
  },
  buttonsUserCreate: {
    alignSelf: 'end',
  },
  buttonFilterEmail: {
    marginTop: 5,
    marginBottom: 5,
  },
}))
